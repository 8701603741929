export const client = {
  page_title: "Clients",
  first_name: "Prénom",
  last_name: "Nom",
  email: "Email",
  phone: "Numéro de téléphone",
  delivery_address: "Addresse de livraison",
  status: "Status de paiement",
  photo: "Photo de profile",
  payment_expiration: "Date d'expiration du paiement",
  successfully_deleted: "Client est supprimé avec succès",
  successfully_adding: "Client est ajouté avec succès",
  successfully_editing: "Client est edité avec succès",
  delete: "Supprimer le Client",
  add: "Ajouter Client",
  edit: "Modifier Client",
  updateStatus: "Status de Client est modifié",
  disable: "Désactiver Client",
  enable: "Activer Client",
  show:"Afficher Client",
  country:"Pays",
  city:"Ville"
};
