
import React from 'react';
import { CssBaseline, Container, Typography } from '@mui/material';
import { RouteIdEnum } from "config/enums";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";

function Chat() {

    return (
        <DashboardLayout
          title={"Horaires d'ouverture"}
          selectedPath={RouteIdEnum.Opening_Hours}
        >  <Container style={{backgroundColor: "white",
        boxShadow: "0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.1",
        padding: 18,
       width: '100vw', height: '100vh',
        borderRadius: 8,}}>
     <iframe
        src="https://chatterpal.me/live_chat/pub/32LpfQBd88ZM/chat"
        title="chatterpal"
        width="100%"
        height="100%"
        style={{ border: 'none' }}
      ></iframe>

       
      </Container>
    
       
             </DashboardLayout>
  );
}
export default Chat;