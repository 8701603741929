import { DrawerPropsInterface } from "./Drawer.type";
import { DrawerStyle } from "./Drawer.style";

function Drawer({
  children, // component
  drawer, // drawer is open
  handleDrawerClose, // handle click to close drawer
}: DrawerPropsInterface) {
  return (
    <DrawerStyle
      variant={window.innerWidth < 600 ? "temporary" : "permanent"}
      anchor="left"
      open={drawer}
      onClose={handleDrawerClose}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      isdraweropened={drawer}
    >
      {children}
    </DrawerStyle>
  );
}
export default Drawer;
