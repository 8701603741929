import {
  AddActionTitleStyle,
  AddButtonStyle,
  AddIconStyle,
} from "./DeleteButton.style";
import { AddButtonPropsInterface } from "./DeleteButton.type";
import { useTranslation } from "react-i18next";

function EditButton({ onAddClick, title, className }: AddButtonPropsInterface) {
  // translation hooks
  const { t } = useTranslation();
  return (
    <AddButtonStyle onClick={onAddClick} className={className}>
      <AddIconStyle />
      <AddActionTitleStyle>{t(title)}</AddActionTitleStyle>
    </AddButtonStyle>
  );
}
export default EditButton;
