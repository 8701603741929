import {
  SnackbarCloseReason,
  Snackbar as CustomSnackBar,
  Alert,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useSnackBar } from "hooks/useSnackBar";
import { RootState } from "redux/store";

function SnackBarNotification() {
  const notification = useSelector((state: RootState) => state.snackBarReducer);
  const { clearSnackBar } = useSnackBar();
  const handleClose = (_: unknown, reason?: SnackbarCloseReason) =>
    reason !== "clickaway" && clearSnackBar();
  return (
    <CustomSnackBar
      open={notification.open}
      autoHideDuration={notification.timeout}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <Alert
        variant="filled"
        onClose={handleClose}
        severity={notification.type}
      >
        {notification.message}
      </Alert>
    </CustomSnackBar>
  );
}

export default SnackBarNotification;
