import { useTranslation } from "react-i18next";
import { ChangeEvent, useCallback, useState, useEffect } from "react";
import Dialog from "components/common/Dialog/Dialog";
import Button from "components/common/Button/Button";
import {
  ContainerButtonStyle,
  DeleteButtonsStyle,
  InputStyle,
} from "./EditTax.style";
import { useSnackBar } from "hooks/useSnackBar";
import TextInput from "components/common/TextInput/TextInput";
import {
  ITaxEditingData,
  ITaxEditingDataError,
  IEditingDialog,
} from "./EditTax.types";
import { useUpdateTaxMutation } from "redux/api/tax/taxes";

function EditTax({
  iniData,
  openEditingDialog,
  setOpenEditingDialog,
}: IEditingDialog) {
  const { t } = useTranslation();

  const { displaySuccessSnackBar, displayErrorApiSnackBar } = useSnackBar();

  const [editingTax, { data, error, isLoading, reset }] =
    useUpdateTaxMutation();

  if (error) {
    displayErrorApiSnackBar(error);
    reset();
  }
  if (data) {
    displaySuccessSnackBar(t("tax.successfully_editing"));
    reset();
  }
  const closeEditingDialog = useCallback(() => {
    setOpenEditingDialog(false);
    setEditingData({ name: "", percentage: "",country:"" });
    setEditingError({ name: "", percentage: "",country:"" });

  }, [setOpenEditingDialog]);

  const onSuccessEditingDialog = async(event: React.FormEvent) => {
    event.preventDefault();
     if(areAllValuesEmpty(editingError)){
    editingTax({
      id: editingData.id,
      name:editingData.name,
      percentage:editingData.percentage,
      country: editingData.country
    });
    closeEditingDialog()
  }
  }
  function areAllValuesEmpty(obj:Object) {
    return Object.values(obj).every(value => {
      if (typeof value === 'object' && !Array.isArray(value)) {
        return Object.values(value).every(innerValue => {
          return innerValue === null || innerValue === '';
        });
      } else {
        return value === null || value === '';
      }
    });
  }

  const [editingData, setEditingData] = useState<ITaxEditingData>({
    id: iniData.id,
    name: iniData.name,
    percentage: iniData.percentage,
    country: iniData.country,
  });
  const [editingError, setEditingError] = useState<ITaxEditingDataError>({
    name: "",
    percentage:"",
    country:"",
  });
 
  const onChange = useCallback(
    (field: string) =>
      (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {

        setEditingData(prevData => {
          const newData = { ...prevData, [field]: e.target.value };
          if(newData.name==""){
            setEditingError(prevError => ({ ...prevError, name: "Nom obligatoire" }));
          }
          else {
            setEditingError(prevError => ({ ...prevError, name: "" }));
          }
          if(newData.country=="") {
            setEditingError(prevError => ({ ...prevError, country: "Pays obligatoire" }));
          }
          else {
            setEditingError(prevError => ({ ...prevError, country: "" }));
          
         }
         if(newData.percentage=="") {
          setEditingError(prevError => ({ ...prevError, percentage: "Pourcentage obligatoire" }));
        }
     
      else if(parseFloat(newData.percentage)<0.0||parseFloat(newData.percentage)>100.0) {
        setEditingError(prevError => ({ ...prevError, percentage: "Pourcentage entre 0 et 100" }));
      }
       else {
        setEditingError(prevError => ({ ...prevError, percentage: "" }));
      
       }
      
        
       
         return newData;

      })
        
      },
    [editingData,editingError]

  );


useEffect(() => {
    if (openEditingDialog)
      setEditingData({
        id: iniData.id,
        name: iniData.name,
        percentage: iniData.percentage,
        country:iniData.country
      });
  }, [openEditingDialog, iniData.id, iniData.name, iniData.percentage,iniData.country]);
  return (
    <Dialog
      open={openEditingDialog}
      handleClose={closeEditingDialog}
      title={t("tax.edit")}
    >
      <form onSubmit={onSuccessEditingDialog}>
        <InputStyle>
          <TextInput
            value={editingData.name}
            label={t("tax.name")}
            onChange={onChange("name")}
            error={t(editingError.name)}
          />
        </InputStyle>
        <InputStyle>
          <TextInput
            value={parseFloat(editingData.percentage).toString()}
            label={t("tax.percentage")}
            type="number"

            onChange={onChange("percentage")}
            error={t(editingError.percentage)}
          />
        </InputStyle>
        <InputStyle>
          <TextInput
            value={editingData.country}
            label={t("tax.country")}
            onChange={onChange("country")}
            error={t(editingError.country)}
          />
        </InputStyle>



        <DeleteButtonsStyle>
          <ContainerButtonStyle>
            <Button
              disabled={isLoading}
              title={t("common.cancel_button")}
              onClick={closeEditingDialog}
            />
          </ContainerButtonStyle>
          <ContainerButtonStyle>
            <Button
              disabled={isLoading}
              type="submit"
              loader={isLoading}
              title={t("common.confirm_button")}
            />
          </ContainerButtonStyle>
        </DeleteButtonsStyle>
      </form>
    </Dialog>
  );
}
export default EditTax;
