import { createApi } from "@reduxjs/toolkit/query/react";
import { ENDPOINTS } from "config/constant/endpoints.config";
import { Paginator } from "types/interfaces";
import { injectParamsPaginator } from "utils/helpers/api-url";
import { baseQueryWithReauth } from "../../rtkBaseQuery";

export const expeditionCategsApi = createApi({
  reducerPath: "expeditionCategsApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["ExpeditionCateg"],
  endpoints: (builder) => ({
    getAllExpeditionCategs: builder.query({
      query: (params: { paginator?: Paginator,all?:boolean }) => {
        return injectParamsPaginator(ENDPOINTS.EXPEDITION_CATEG, params.paginator,params.all);
      },
      providesTags: ["ExpeditionCateg"],
    }),
    getExpeditionCateg: builder.query({
      query: (id: number) => ({
        url: `${ENDPOINTS.EXPEDITION_CATEG}/${id}`,
        method: "GET",
      }),
    }),
    deleteExpeditionCateg: builder.mutation({
      query: (id: number) => ({
        url: `${ENDPOINTS.EXPEDITION_CATEG}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ExpeditionCateg"],
    }),
    deleteMultipleExpeditonCategs: builder.mutation({
        query: (params: { categ_ids: number[], select_all: boolean ,search:string}) => ({
          url: `${ENDPOINTS.EXPEDITION_CATEG}/delete-multiple`,
          method: "POST",
          body: {
            categ_ids: params.categ_ids,
            select_all: params.select_all,
            search:params.search
          },
        }),
        invalidatesTags: ["ExpeditionCateg"],

      }),
    addExpeditionCateg: builder.mutation({
      query({name}) {
        return {
          url: `${ENDPOINTS.EXPEDITION_CATEG}`,
          method: "POST",
          credentials: "include",
          body: {name},
        };
      },
      invalidatesTags: ["ExpeditionCateg"],
    }),
    updateExpeditionCateg: builder.mutation({
      query({ id, name }) {
        return {
          url: `${ENDPOINTS.EXPEDITION_CATEG}/${id}`,
          method: "POST",
          credentials: "include",
          body:  {name},
        };
      },
      invalidatesTags: ["ExpeditionCateg"],
    }),
  }),
});

export const {
  useGetAllExpeditionCategsQuery,
  useGetExpeditionCategQuery,
  useAddExpeditionCategMutation,
  useUpdateExpeditionCategMutation,
  useDeleteExpeditionCategMutation,
  useDeleteMultipleExpeditonCategsMutation,
} = expeditionCategsApi;
