import React, { useEffect, useRef, useState } from 'react';
import { BrowserBarcodeReader, VideoInputDevice } from '@zxing/library';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import './BarcodeScanner.css'; 
interface BarcodeScannerProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (result: string | null) => void;
}

const BarcodeScanner: React.FC<BarcodeScannerProps> = ({
  isOpen,
  onClose,
  onConfirm,
}) => {
    const [result, setResult] = useState<string | null>(null);
    const [scanning, setScanning] = useState(false);
    const [isVideoModalOpen, setVideoModalOpen] = useState(false);
    const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
    const [isCameraOpen, setCameraOpen] = useState(true);
    const codeReader = useRef<BrowserBarcodeReader | null>(null);
  
    useEffect(() => {
      const initializeScanner = async () => {
        try {
          codeReader.current = new BrowserBarcodeReader();
          const videoInputDevices: MediaDeviceInfo[] = await codeReader.current.listVideoInputDevices();
  
          if (isCameraOpen && videoInputDevices.length > 0) {
             codeReader.current.decodeFromInputVideoDevice(videoInputDevices[0].deviceId, 'video').then((result: any) => {
                setResult(result.getText());
                handleBarcodeDetection(result.getText());
              })
              .catch((err: any) => {
                console.error(err);
              });
          }
        } catch (error) {
          // Handle initialization error, e.g., display an error message to the user
          console.error(error);
        }
      };
  
      initializeScanner();
  
      return () => {
        if (codeReader.current) {
          codeReader.current.reset();
        }
      };
    }, [isCameraOpen]);
 

  const startScanning = () => {
    setResult(null);
    setScanning(true);
    setVideoModalOpen(true);

   
  };

  const handleBarcodeDetection = (barcode: string | null) => {
    if (barcode) {
      setVideoModalOpen(false);
      setConfirmModalOpen(true);
    }
  };

  const onCloseVideoModal = () => {
    setVideoModalOpen(false);
  };

  const handleConfirm = () => {
    // Handle the confirmed action
    setCameraOpen(false);
    setConfirmModalOpen(false);
    onConfirm(result);
  };

  const handleRescan = () => {
    // Close the camera before rescanning
    setCameraOpen(false);

    // Handle the rescan action
    setConfirmModalOpen(false);
    startScanning();

    // Reopen the camera after a delay (adjust the delay as needed)
    setTimeout(() => {
      setCameraOpen(true);
    }, 1000);
  };

  return (
    <>
      <Dialog open={isOpen && !result} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>Scanning Video</DialogTitle>
        <DialogContent>
          <div className="video-container">
            {isOpen && <div className="animated-rectangle" />}
            {isOpen && <div className="scanning-label">Scanning...</div>}
            {isOpen && <video id="video" width="100%" height="auto" />}
          </div>
        </DialogContent>
      </Dialog>

      <Dialog open={!!result} onClose={onClose}>
        <DialogTitle>Barcode Detected</DialogTitle>
        <DialogContent>
          <p>{`Detected barcode: ${result}`}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirm} variant="contained" color="primary">
            Confirm
          </Button>
          <Button onClick={handleRescan} variant="contained" color="secondary">
            Rescan
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BarcodeScanner;
