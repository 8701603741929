import { IconButton, styled } from "@mui/material";
import { Cancel, Search,LocalActivity, HourglassEmpty } from "@mui/icons-material";

export const ContainerStyle = styled("div")(({ theme }) => ({
  width: "100%",
  position: "relative",
}));

export const InputContainerStyle = styled("div")(({ theme }) => ({
  width: "100%",
}));

export const InputSearchStyle = styled("input")(({ theme }) => ({
  color: "black",
  fontSize: 14,
  outline: "none",
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: theme.palette.primary.main,
  borderRadius: 30,
  paddingTop: 10,
  paddingBottom: 10,
  paddingLeft: 17,
  paddingRight: 40,
  transition: "0.1s ease-out",
  width: "100%",
  "&:disabled": {
    backgroundColor: "white",
  },
  "&:not(:placeholder-shown)": {
    borderColor: theme.palette.primary.main,
    color: "black",
    fontSize: 14,

    "&+label": {
      top: 0,
      transform: "translateY(-70%) scale(1)",
      color: theme.palette.primary.main,
    },
  },
  "&:-webkit-autofill + label": {
    top: 0,
    transform: "translateY(-70%) scale(1)",
    color: theme.palette.primary.main,
  },
  "&:focus": {
    boxShadow: "0px 0px 6px 3px rgba(18, 63, 140, 0.3) !important",
    "&+label": {
      color: theme.palette.primary.main,
      top: 0,
      transform: "translateY(-70%) scale(1)",
    },
  },
}));

export const SearchIconButtonStyle = styled(IconButton)(({ theme }) => ({
  height: 18,
  width: 18,
  position: "absolute",
  top: 8,
  right: 10,
  "&:hover": {
    backgroundColor: "rgba(18, 62, 140, 0.1)",
  },
}));

export const CancelIconStyle = styled(Cancel)(({ theme }) => ({
  height: 16,
  width: 16,
}));

export const SearchIconStyle = styled(Search)(({ theme }) => ({
  height: 16,
  width: 16,
}));

export const LoadingIconStyle = styled(HourglassEmpty)(({ theme }) => ({
  height: 16,
  width: 16,
}));