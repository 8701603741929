import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import theme from "./theme/shared-theme";
import App from "./App";
import i18n from "./i18n";
import { store } from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import "./index.css"
import 'react-toastify/dist/ReactToastify.css';
import SnackBarNotification from "components/common/SnackBar/SnackBarNotification";
import { ToastContainer } from "react-toastify";
/*
 * Get the root element from the DOM and check if it already exists
 */
const mainApp = document.getElementById("root");
if (!mainApp) {
  throw new Error("The element #root wasn't found");
} else {
  const root = createRoot(mainApp);
  root.render(
    <I18nextProvider i18n={i18n}>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Provider store={store}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <App />
          </ThemeProvider>
        </BrowserRouter>
        <SnackBarNotification />
      </Provider>
    </I18nextProvider>
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
