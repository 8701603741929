import { useTranslation } from "react-i18next";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import Dialog from "components/common/Dialog/Dialog";
import { Stack } from "@mui/material";
import {
  ISuppliersDialog,
  ISuppliersShowData,
} from "./SupplierShow.types";
function ShowSupplier({
  id,
  codeInfos,
  openShowDialog,
  setOpenShowDialog,
}: ISuppliersDialog) {
  const { t } = useTranslation();


  

  const [supplierData, setSupplierData] = useState<ISuppliersShowData>({
    company_name: codeInfos.company_name,
    address: codeInfos.address,
    postal_code:codeInfos.postal_code,
    city:codeInfos.city,
    region:codeInfos.region,
    department:codeInfos.department,
    country:codeInfos.country,
    email:codeInfos.email,
    office_phone:codeInfos.office_phone,
    mobile_phone:codeInfos.mobile_phone,
    website:codeInfos.website,
    contact_last_name:codeInfos.contact_last_name,
    contact_first_name:codeInfos.contact_first_name,
    contact_position:codeInfos.contact_position,
    contact_mobile:codeInfos.contact_mobile,
    contact_email:codeInfos.contact_email,
    contact_office_phone:codeInfos.contact_office_phone
 
  });
 





  useEffect(() => {
    if (openShowDialog)
      setSupplierData({
        company_name: codeInfos.company_name,
        address: codeInfos.address,
        postal_code:codeInfos.postal_code,
        city:codeInfos.city,
        region:codeInfos.region,
        department:codeInfos.department,
        country:codeInfos.country,
        email:codeInfos.email,
        office_phone:codeInfos.office_phone,
        mobile_phone:codeInfos.mobile_phone,
        website:codeInfos.website,
        contact_last_name:codeInfos.contact_last_name,
        contact_first_name:codeInfos.contact_first_name,
        contact_position:codeInfos.contact_position,
        contact_mobile:codeInfos.contact_mobile,
        contact_email:codeInfos.contact_email,
        contact_office_phone:codeInfos.contact_office_phone
      });
  }, [
    openShowDialog,
    codeInfos.company_name,
    codeInfos.address,
   codeInfos.postal_code,
    codeInfos.city,
    codeInfos.region,
    codeInfos.department,
   codeInfos.country,
    codeInfos.email,
    codeInfos.website,
    codeInfos.contact_last_name,
    codeInfos.contact_first_name,
    codeInfos.contact_position,
    codeInfos.contact_mobile,
    codeInfos.contact_email,
    codeInfos.contact_office_phone,
    id,
  ]);
  const closeEditingDialog = useCallback(() => {
    setOpenShowDialog(false);
    setSupplierData({ 
      company_name:"",
      address: "",
      postal_code: "",
      city: "",
      region: "",
      department:"",
      country:"",
      email:"" ,
      office_phone:"",
      mobile_phone:"",
      website:"",
      contact_last_name:"",
      contact_first_name:"",
      contact_position:"",
      contact_mobile:"",
      contact_email:"",
      contact_office_phone:""
    });
  }, [setOpenShowDialog]);
  return (
    <Dialog
      open={openShowDialog}
      handleClose={closeEditingDialog}
      title={t("supplier.show")}
    >
    <Stack spacing={24} direction="row">
    <Stack spacing={2} direction="column">


      <h5 style={{color:"#DA70D6	"}}>
        Company Name:
      </h5>
        <p>
          {supplierData.company_name}
        </p>

        <h5 style={{color:"#DA70D6	"}}>
       Address:
      </h5>
        <p>
          {supplierData.address}
        </p>
        <h5 style={{color:"#DA70D6	"}}>
       Postal Code:
      </h5>
        <p>
          {supplierData.postal_code}
        </p>

        <h5 style={{color:"#DA70D6	"}}>
       City:
      </h5>
        <p>
          {supplierData.city}
        </p>
      

        <h5 style={{color:"#DA70D6	"}}>
       Region:
      </h5>
        <p>
          {supplierData.region}
        </p>

        <h5 style={{color:"#DA70D6	"}}>
       Departmenet:
      </h5>
        <p>
          {supplierData.department}
        </p>
        </Stack>
        <Stack spacing={2} direction="column">
        <h5 style={{color:"#DA70D6	"}}>
       Country:
      </h5>
        <p>
          {supplierData.country}
        </p>

        <h5 style={{color:"#DA70D6	"}}>
       Email:
      </h5>
        <p>
          {supplierData.email}
        </p>

        <h5 style={{color:"#DA70D6	"}}>
       Office Phone:
      </h5>
        <p>
          {supplierData.office_phone}
        </p>

        <h5 style={{color:"#DA70D6	"}}>
       Mobile Phone:
      </h5>
        <p>
          {supplierData.mobile_phone}
        </p>

        <h5 style={{color:"#DA70D6	"}}>
       Website:
      </h5>
        <p>
          {supplierData.website}
        </p>
     </Stack>
     </Stack>
     <h3 style={{color:"#FFDE66"}}>Contact</h3>
     <Stack spacing={48} direction="row">
     <Stack spacing={2} direction="column">


     <h5 style={{color:"#DA70D6	"}}>
       Last Name:
      </h5>
        <p>
          {supplierData.contact_last_name}
        </p>

        <h5 style={{color:"#DA70D6	"}}>
       First Name:
      </h5>
        <p>
          {supplierData.contact_first_name}
        </p>

        <h5 style={{color:"#DA70D6	"}}>
       Position:
      </h5>
        <p>
          {supplierData.contact_position}
        </p>

        <h5 style={{color:"#DA70D6	"}}>
       Mobile:
      </h5>
        <p>
          {supplierData.contact_mobile}
        </p>

        <h5 style={{color:"#DA70D6	"}}>
       Email:
      </h5>
        <p>
          {supplierData.contact_email}
        </p>
        <h5 style={{color:"#DA70D6	"}}>
       Office Phone:
      </h5>
        <p>
          {supplierData.contact_office_phone}
        </p>
        </Stack>
        </Stack>

    </Dialog>
  );
}
export default ShowSupplier;
