import { useTranslation } from "react-i18next";
import { ChangeEvent, useCallback, useState,useEffect } from "react";
import Dialog from "components/common/Dialog/Dialog";
import Button from "components/common/Button/Button";
import {
  ContainerButtonStyle,
  DeleteButtonsStyle,
  InputStyle,
} from "./AddTax.style";
import {
  ITaxAddDialog,
  ITaxAddingData,
  ITaxesAddingDataError,
} from "./AddTax.types";
import { useSnackBar } from "hooks/useSnackBar";
import TextInput from "components/common/TextInput/TextInput";
import FileInput from "components/common/FileInput/FileInput";
import { useAddTaxMutation } from "redux/api/tax/taxes";

function AddTax({ openAddingDialog, setOpenAddingDialog }: ITaxAddDialog) {
  const { t } = useTranslation();

  const { displaySuccessSnackBar, displayErrorApiSnackBar } = useSnackBar();
  const [addingData, setAddingData] = useState<ITaxAddingData>({
    name: "",
    percentage: "",
    country:""
  });
  const [addingError, setAddingError] =
    useState<ITaxesAddingDataError>({
      name: "",
      percentage: "",
      country: "",
    });
  const [addingTax, { data, error, isLoading, reset }] =
    useAddTaxMutation();

  if (error) {
    displayErrorApiSnackBar(error);
    reset();
  }
  if (data) {
    displaySuccessSnackBar(t("tax.successfully_adding"));
    reset();
  }

  function areAllValuesEmpty(obj:Object) {
    return Object.values(obj).every(value => {
      if (typeof value === 'object' && !Array.isArray(value)) {
        return Object.values(value).every(innerValue => {
          return innerValue === null || innerValue === '';
        });
      } else {
        return value === null || value === '';
      }
    });
  }

  const closeAddingDialog = useCallback(() => {
    setOpenAddingDialog(false);
    setAddingData({ name: "", percentage: "",country:"" });
    setAddingError({ name: "", percentage: "",country:"" })
  }, [setOpenAddingDialog]);

  const onSuccessAddingDialog = (event: React.FormEvent) => {
   event.preventDefault();

    if(areAllValuesEmpty(addingError)){
       addingTax(addingData);
      closeAddingDialog()
    }
   
 }
 useEffect(()=>{
  console.log("addingData",addingData)
 },[addingData])




  const onChange = useCallback(
    (field: string) =>
      (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setAddingData(prevData => {
      
        const newData = { ...prevData, [field]: e.target.value };
          if(newData.name==""){
            setAddingError(prevError => ({ ...prevError, name: "Nom obligatoire" }));
          }
          else {
            setAddingError(prevError => ({ ...prevError, name: "" }));
          }
          if (newData.country === "") {
            setAddingError(prevError => ({ ...prevError, country: "Pays obligatoire" }));
          } 
          else if (!isNaN(Number(newData.country))) { 
            setAddingError(prevError => ({ ...prevError, country: "Le pays ne peut pas être un nombre" }));
          } 
          else {
            setAddingError(prevError => ({ ...prevError, country: "" }));
          }
          

         if(newData.percentage=="") {
          setAddingError(prevError => ({ ...prevError, percentage: "Pourcentage obligatoire" }));
        }
   
      else if(parseFloat(newData.percentage)<0.0||parseFloat(newData.percentage)>100.0) {
        setAddingError(prevError => ({ ...prevError, percentage: "Pourcentage entre 0 et 100" }));
      }
       else {
        setAddingError(prevError => ({ ...prevError, percentage: "" }));
      
       }
      
        
       
         return newData;

      })
    },
    [addingData,addingError]
  );



  return (
    <Dialog
      open={openAddingDialog}
      handleClose={closeAddingDialog}
      title={t("tax.add")}
    >
      <form onSubmit={onSuccessAddingDialog}>
        <InputStyle>
          <TextInput
            value={addingData.name}
            label={t("tax.name")}
            onChange={onChange("name")}
            error={t(addingError.name)}
          />
        </InputStyle>
        <InputStyle>
          <TextInput
            value={addingData.percentage}
            label={t("tax.percentage")}
            type="number"
            onChange={onChange("percentage")}
            error={t(addingError.percentage)}
          />
          </InputStyle>
        <InputStyle>
          <TextInput
            value={addingData.country}
            label={t("tax.country")}
            onChange={onChange("country")}
            error={t(addingError.country)}
          />
        </InputStyle>

        <DeleteButtonsStyle>
          <ContainerButtonStyle>
            <Button
              disabled={isLoading}
              title={t("common.cancel_button")}
              onClick={closeAddingDialog}
            />
          </ContainerButtonStyle>
          <ContainerButtonStyle>
            <Button
              disabled={isLoading}
              type="submit"
              loader={isLoading}
              title={t("common.confirm_button")}
            />
          </ContainerButtonStyle>
        </DeleteButtonsStyle>
      </form>
    </Dialog>
  );
}
export default AddTax;
