import {
  PublishActionTitleStyle,
  PublishButtonStyle,
  PublishIconStyle,
} from "./PublishButton.style";
import { AddButtonPropsInterface } from "./PublishButton.type";
import { useTranslation } from "react-i18next";

function EditButton({ onAddClick, title, className }: AddButtonPropsInterface) {
  // translation hooks
  const { t } = useTranslation();
  return (
    <PublishButtonStyle onClick={onAddClick} className={className}>
      <PublishIconStyle />
      <PublishActionTitleStyle>{t(title)}</PublishActionTitleStyle>
    </PublishButtonStyle>
  );
}
export default EditButton;
