import { useState } from 'react';
import { TiCreditCard } from "react-icons/ti";
import { MdLocalShipping } from "react-icons/md";
import kd from '../../assets/img/logo.jpeg'
import lolo from '../../assets/img/lolo.png'
const tabs = [
  { name: 'Kd Marché', href: '#', id: 0, icon: <MdLocalShipping size={25} />, image: kd },
  { name: 'Lolo Drive', href: '#', id: 1, icon: <TiCreditCard size={25} />, image: lolo },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function TypesTabs({ currentTab, setCurrentTab }) {

  const handleTabClick = (tabId) => {
    setCurrentTab(tabId); // Update current tab on click
  };

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-[#b269ae] focus:ring-[#b269ae]"
          value={currentTab}
          onChange={(e) => setCurrentTab(Number(e.target.value))}
        >
          {tabs.map((tab) => (
            <option key={tab.id} value={tab.id}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block mb-10">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex mx-auto justify-center" aria-label="Tabs">
            {tabs.map((tab) => (
              <a
                key={tab.id}
                href={tab.href}
                onClick={() => handleTabClick(tab.id)}
                className={classNames(
                  tab.id === currentTab
                    ? 'border-[#b269ae] text-[#b269ae]'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'w-1/4 border-b-2 py-4 px-1 text-center text-sm font-medium flex items-center gap-3 justify-center '
                )}
                aria-current={tab.id === currentTab ? 'page' : undefined}
              >
                <img src={tab.image} alt="" className='h-10 rounded-full' />
                {tab.name}
              </a>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}
