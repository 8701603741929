export const promo_flash={
    percentage:"Pourcentage",
    page_title:"Promotions Flash",
    add:"Ajouter une Promotion Flash",
    edit:"Modifier une Promotion Flash",
    delete:"Supprimer une Promotion Flash",
    successfully_deleted: "Promotion Flash  supprimée avec succès",
    successfully_adding: "Promotion Flash  ajoutée avec succès",
    successfully_editing: "Promotion Flash editée avec succès",
    product_required:" Produit  obligatoire",
    percentage_required:"Pourcentage  obligatoire",
    percentage_range:"Pourcentage est compris entre 0 et 99.99",
    date_promotion_required:"Date promotion est obligatoire",
    product:"Product",
    date_promotion:"Date promotion flash",
    euro:"Euro",
    description:"Description",
    durée:"Durée",
    seconds:"Secondes",
    minutes:"Minutes",
    hours:"Heures",
    day:"Jour"
}
