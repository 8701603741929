import { createApi } from "@reduxjs/toolkit/query/react";
import { ENDPOINTS } from "config/constant/endpoints.config";
import { Paginator } from "types/interfaces";
import { injectParamsPaginator } from "utils/helpers/api-url";
import { baseQueryWithReauth } from "../rtkBaseQuery";

export const orderApi = createApi({
  reducerPath: "OrderApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Order"],
  endpoints: (builder) => ({
    getHistoryOrder: builder.query({
      query: (params: { paginator?: Paginator }) => {
        return injectParamsPaginator(ENDPOINTS.ORDERS, params.paginator);
      },
    }),
    updateOrderStatus: builder.mutation({
      query({order_id,
      status}) {
       
        return {
          url: `${ENDPOINTS.ORDERS}/change-status`,
          method: "POST",
          credentials: "include",
          headers: {
            // If you need to set specific headers for FormData, add them here
            "enctype": "multipart/form-data"
          },
          body: {
            order_id,
            status
          },
        };
      },
      invalidatesTags: ["Order"],
    }),
  }),
  
});

export const { useGetHistoryOrderQuery,useUpdateOrderStatusMutation } = orderApi;
