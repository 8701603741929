import { useTranslation } from "react-i18next";
import { ChangeEvent, useCallback, useState } from "react";
import Dialog from "components/common/Dialog/Dialog";
import Button from "components/common/Button/Button";
import {
  ContainerButtonStyle,
  DeleteButtonsStyle,
  ImageContainerStyle,
  ImageStyle,
  InputStyle,
} from "./AddCategory.style";
import {
  IAddDialog,
  ICategoryAddingData,
  IPromotionCodesAddingDataError,
} from "./AddCategory.types";
import { useSnackBar } from "hooks/useSnackBar";
import TextInput from "components/common/TextInput/TextInput";
import { useAddCategoryMutation } from "redux/api/category/category";
import FileInput from "components/common/FileInput/FileInput";
import { GlobalVariables } from "config/constant";

function AddCategory({ openAddingDialog, setOpenAddingDialog }: IAddDialog) {
  const { t } = useTranslation();

  const { displaySuccessSnackBar, displayErrorApiSnackBar } = useSnackBar();
  const [addingError, setAddingError] =
    useState<IPromotionCodesAddingDataError>({
      title: "",
      image: "",
    });
  const [addingCategory, { data, error, isLoading, reset }] =
    useAddCategoryMutation();

  if (error) {
    displayErrorApiSnackBar(error);
    reset();
  }
  if (data) {
    displaySuccessSnackBar(t("category.successfully_adding"));
    reset();
  }
  const [addingData, setAddingData] = useState<ICategoryAddingData>({
    title: "",
    image: null,
  });
  const closeAddingDialog = useCallback(() => {
    setOpenAddingDialog(false);
    setAddingData({ title: "", image: null });
    setAddingError({ title: "", image: "" });
  }, [setOpenAddingDialog]);
  const validate=()=>{
    const addingErrorCateg:{title:string,image:string}={
      title:"",
      image:"",
    }
    if(!addingData.title){
      addingErrorCateg.title="Titre obligatoire"
    }
    else {
      addingErrorCateg.title=""

    }
    if(!addingData.image){
      addingErrorCateg.image="Image obligatoire"

    }
    else{
      addingErrorCateg.image=""

    }
    setAddingError(addingErrorCateg)
    console.log("AddingError",addingErrorCateg)

  }
  const onChangeOneImage = useCallback(
    (field: string) => (e: any) => {
      const files = (e.target as HTMLInputElement).files;

      if (files) {
        const file = files[0];

        // Check if the selected file is an image
        if (file.type.startsWith('image/')) {
          const reader = new FileReader();

          reader.onload = (event) => {
            if (event.target) {
              const image = new Image();
              image.src = event.target.result as string;

              image.onload = () => {
                // Access the image dimensions
                const imageWidth = image.width;
                const imageHeight = image.height;

                console.log('Image Width:', imageWidth);
                console.log('Image Height:', imageHeight);
                if(imageWidth==300 && imageHeight == 300){
                  setAddingError({ ...addingError, image: "" });

                  setAddingData({
                    ...addingData,
                  image : e.target.files[0]})


                }else{
                  setAddingError({ ...addingError, image: "L'image doit être de taille 300 × 300 pixels." });

                }


              };
            }
          };

          // Read the selected file as a data URL
          reader.readAsDataURL(file);
        } else {
          // Handle non-image file type
          console.error('Selected file is not an image.');
        }
      }
    },
    [addingData,addingError]
  );
  const onSuccessAddingDialog = (event: React.FormEvent) => {
    event.preventDefault();
    validate()
    const formData = new FormData();
    formData.append("title", addingData.title);
    if (addingData.image) formData.append("image", addingData.image);
    if(addingData.title&& addingData.image){
    addingCategory(formData);
    closeAddingDialog();
    }
  };



  const onChange = useCallback(
    (field: string) =>
      (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        validate()
        setAddingData({ ...addingData, [field]: e.target.value });
        if (field === "image") {
          const files = (e.target as HTMLInputElement).files;
          if (files)
            setAddingData({
              ...addingData,
              image: files[0],
            });
        }
    
      },
    [addingData]
  );

  return (
    <Dialog
      open={openAddingDialog}
      handleClose={closeAddingDialog}
      title={t("category.add")}
    >
      <form onSubmit={onSuccessAddingDialog}>
        <InputStyle>
          <TextInput
            value={addingData.title}
            label={t("category.title")}
            onChange={onChange("title")}
            error={t(addingError.title)}
          />
        </InputStyle>
        <InputStyle>
          <FileInput
            value={addingData.image}
            label={t("category.image")}
            onChange={onChangeOneImage("image")}
            error={t(addingError.image)}
            accept={GlobalVariables.FILE_FORMATS.map((e) => e.format).join(",")}
          />
        </InputStyle>
        {addingData.image && (
          <ImageContainerStyle>
            <ImageStyle
              src={URL.createObjectURL(addingData.image)}
            ></ImageStyle>
          </ImageContainerStyle>
        )}
        <DeleteButtonsStyle>
          <ContainerButtonStyle>
            <Button
              disabled={isLoading}
              title={t("common.cancel_button")}
              onClick={closeAddingDialog}
            />
          </ContainerButtonStyle>
          <ContainerButtonStyle>
            <Button
              disabled={isLoading}
              type="submit"
              loader={isLoading}
              title={t("common.confirm_button")}
            />
          </ContainerButtonStyle>
        </DeleteButtonsStyle>
      </form>
    </Dialog>
  );
}
export default AddCategory;
