import { DialogContent, DialogTitle, IconButton,Dialog, styled } from "@mui/material";

export const DialogTitleStyle = styled(DialogTitle)(({ theme }) => ({
 color:"white",
 backgroundColor:"#B269AE",
  marginInlineEnd: 20,
  fontFamily: "Lato, Bold",
}));

export const CloseIconStyle = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  display: "inline",
  right: 0,
  top: 0,
  margin: 10,
  color:"white",
}));
export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
export const SubTitleStyle = styled("div")(({ theme }) => ({
  textAlign: "center",
  marginInlineEnd: 20,
  fontFamily: "Lato, Bold",
}));

export const DialogContentStyle = styled(DialogContent)(({ theme }) => ({
 
}));
