
import React from 'react';
import { CssBaseline, Container, Typography } from '@mui/material';
import { RouteIdEnum } from "config/enums";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import Horaires from './Horaires';
import Holiday from './Holiday';
function OpeningHours() {

    return (
        <DashboardLayout
          title={"Horaires d'ouverture"}
          selectedPath={RouteIdEnum.Opening_Hours}
        >  <Container style={{backgroundColor: "white",
        boxShadow: "0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.1",
        padding: 18,
        borderRadius: 8,}}>
        <CssBaseline />
        <Typography variant="h4" gutterBottom>Horaires d'Ouverture</Typography>
        <Horaires />
       
      </Container>
      <br></br>
      <Container style={{backgroundColor: "white",
         boxShadow: "0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.1",
         padding: 18,
         borderRadius: 8,
      }}>
        <CssBaseline />
       
        <br></br>
        <Holiday />
       
      </Container>
       
             </DashboardLayout>
  );
}
export default OpeningHours;