import { useTranslation } from "react-i18next";
import { useCallback, useState } from "react";
import Dialog from "components/common/Dialog/Dialog";
import Button from "components/common/Button/Button";
import {
  ContainerButtonStyle,
  DeleteButtonsStyle,
} from "./DeleteCategoryMultiple.style";
import { ICategsDeleteMultiple } from "./DeleteCategoryMultiple.types";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { useDeletePromotionCodeMutation } from "redux/api/promotionCodes/promotionCodes";
import { useSnackBar } from "hooks/useSnackBar";

function DeleteCategoryMultiple({
  catesgs,
  openDeleteMultipleDialog,
  isLoading,
  setOpenDeleteMultipleDialog,
  onSubmit
}: ICategsDeleteMultiple) {
  console.log("codes",catesgs)
  const { t } = useTranslation();

  const { displaySuccessSnackBar, displayErrorApiSnackBar } = useSnackBar();



  const closeDeleteAlertDialog = useCallback(() => {
    setOpenDeleteMultipleDialog(false);

  }, [setOpenDeleteMultipleDialog]);

  
  return (
    <Dialog
      open={openDeleteMultipleDialog}
      handleClose={closeDeleteAlertDialog}
      title={t("category.deleteMulti")}
      size={"xs"}
    >
              <div style={{textAlign: "center"}}> <ErrorOutlineIcon sx={{fontSize:"90PX",color:"#f54040"}}  ></ErrorOutlineIcon></div>

      <form onSubmit={onSubmit}>
        <p>
          {t("common.delete_message")}
          <span style={{ fontWeight: "bold", marginLeft: 5 }}>les catégories selectionnés</span>!<br/>
          <p ><span style={{ fontWeight: "bold", marginLeft: 5,color:"red" }}>Note: </span>Tous les produits et sous-catégories de ces catégories seront supprimés</p>

        </p>
        <DeleteButtonsStyle>
          <ContainerButtonStyle>
            <Button
              disabled={isLoading}
              title={t("common.cancel_button")}
              onClick={closeDeleteAlertDialog}
            />
          </ContainerButtonStyle>
          <ContainerButtonStyle>
            <Button
              disabled={isLoading}
              type="submit"
              loader={isLoading}
              title={t("common.confirm_button")}
            />
          </ContainerButtonStyle>
        </DeleteButtonsStyle>
      </form>
    </Dialog>
  );
}
export default DeleteCategoryMultiple;
