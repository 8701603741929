import { styled } from "@mui/material";

export const DeleteButtonsStyle = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
}));

export const ContainerButtonStyle = styled("div")(({ theme }) => ({
  margin: 10,
}));

export const InputsContainer = styled("div")(({ theme }) => ({
  display: "flex",
}));

export const InputStyle = styled("div")(({ theme }) => ({
  width: "100%",
  marginTop: 20,
  marginBottom: 20,
}));

export const ErrorContainer = styled("p")(({ theme }) => ({
  color: "red",
  fontSize: 14,
  margin: 0,
  marginTop: 2,
  marginBottom: 0,
  textAlign: "start",
}));

export const ImageContainerStyle = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

export const ImageStyle = styled("img")(({ theme }) => ({
  width: 100,
  height: 100,
  objectFit: "contain",
}));
