import { useTranslation } from "react-i18next";
import { useCallback, useState } from "react";
import Dialog from "components/common/Dialog/Dialog";
import Button from "components/common/Button/Button";
import {
  ContainerButtonStyle,
  DeleteButtonsStyle,
} from "./ProductDraftPublishAlert.style";
import { IProductPublish} from "./ProductDraftPublishAlert.types";

import { useDeletePromotionCodeMutation } from "redux/api/promotionCodes/promotionCodes";
import { useSnackBar } from "hooks/useSnackBar";
import { IProductData } from "../ProductList/Product.types";

function ProductPublishAlert({
  products,
  openPublishProduct,
  isLoading,
  setOpenPublishProduct,
  onSubmit
}: IProductPublish) {
  const { t } = useTranslation();

  const { displaySuccessSnackBar, displayErrorApiSnackBar } = useSnackBar();


  console.log("products",products)
  const closePublishAlertDialog = useCallback(() => {
    setOpenPublishProduct(false);

  }, [setOpenPublishProduct]);

  
  return (
    <Dialog
      open={openPublishProduct}
      handleClose={closePublishAlertDialog}
      title={"Erreur publication de produits"}
    >
      <form onSubmit={onSubmit}>
        <p>
          <span style={{ fontWeight: "bold", marginLeft: 5 }}>vous n'avez pas rempli les champs obligatoires dans les produits suivants</span>!
        </p>
        <ul>
          {products?.map((product:string)=>(<li>
               {product}
          </li>))}
        </ul>
        <DeleteButtonsStyle>
          <ContainerButtonStyle>
            <Button
              disabled={isLoading}
              title={t("common.cancel_button")}
              onClick={closePublishAlertDialog}
            />
          </ContainerButtonStyle>
        
        </DeleteButtonsStyle>
      </form>
    </Dialog>
  );
}
export default ProductPublishAlert;
