export const brand = {
    page_title: "Marques de produits",
    name: "Nom",
    image: "Image",
    created_at: "Date de création",
    successfully_deleted: "Marque est supprimée avec succès",
    successfully_adding: "Marque est ajoutée avec succès",
    successfully_editing: "Marque est editée avec succès",
    delete: "Supprimer la Marque",
    deleteMulti:"Confirmation de suppression",
    add: "Ajouter la Marque",
    edit: "Modifier la Marque",
    list:"Liste de Marque"
  
  
  };
  