import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Avatar, Typography } from '@mui/material';

interface DataTableProps {
  data: {
    time: {
      country: string;
      activeUsers: number;
      averageSessionDuration: string;
    }[];
    countrieslist: {
      [key: string]: string;
    };
  };
}
const getCountryFlag = (countryCode: string) => {
  // Assuming you have a mapping of country codes to flag images
  const flagUrl = `url(https://www.flagsapi.com/${countryCode}/flat/64.png)`;
  return {
    backgroundImage: flagUrl,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    paddingLeft: '20px', // Adjust padding as needed
  };
};
const DataTable: React.FC<DataTableProps> = ({ data }) => {
  return (
    <React.Fragment>
    <Typography variant="h6" style={{textAlign:"center"}} color="textSecondary">
   Pays visiteurs</Typography>
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Pays</TableCell>
            <TableCell>Pages visités</TableCell>
            <TableCell>Session des utilisateurs</TableCell>
            <TableCell>Temps moyen</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.time?.map((row, index) => (
            <TableRow key={index}>
              <TableCell style={{display:"inline-flex"}} >
                <img src={data.countrieslist[row.country]=="gp"?`https://flagsapi.com/FR/flat/64.png`:`https://flagsapi.com/${data.countrieslist[row.country].toUpperCase()}/flat/64.png`} alt="drapeau" width={30} height={30} />
                {row.country}

              </TableCell>
              <TableCell>
                {row.country}
              </TableCell>
              <TableCell>{row.activeUsers}</TableCell>
              <TableCell>{row.averageSessionDuration} s</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </React.Fragment>
  );
};

export default DataTable;
