import { Box, Grid, Stack, Typography } from "@mui/material";
import { ApexOptions } from "apexcharts";
import { IClientData } from "features/Client/ClientList/Client.types";
import useClientList from "features/Client/ClientList/useClientList";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useGetUserDataStatisticsMutation } from "redux/api/statistics/user";
import Chart from 'react-apexcharts';
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { map } from "lodash";
import { useParams } from "react-router-dom";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import { RouteIdEnum } from "config/enums";

interface UserId{
    id:number;
}
interface IDataUserStat{
    options: any
      series:{name:string,data:any} [],
}
const UserDataChart=()=>{
  const {id}=useParams()
    const [state,setState]=useState<IDataUserStat>({    options: {
        
    }
,series:[]    })
const {clientData}=useClientList();
const email=clientData&&clientData.data.filter((client:IClientData)=>client.id===Number(id))[0]?.email
    const [getUserData,{data}]=useGetUserDataStatisticsMutation()
    const [startDate,setStartDate]=useState<Date>(new Date())
    const [endDate,setEndDate]=useState<Date>(new Date(new Date().setMinutes(new Date().getMinutes() + 1)))
    useEffect(()=>{
        const formData=new FormData()
        formData.append("user",`${id}`)
      startDate!==new Date()&&  formData.append("start_date",`${Math.floor(new Date(startDate).getTime() / 1000)}`)
       endDate!==new Date(new Date().setMinutes(new Date().getMinutes() + 1))&& formData.append("end_date",`${Math.floor(new Date(endDate).getTime() / 1000)}`)

        getUserData({formData})
    },[startDate,endDate])
    useEffect(()=>{
      const formData=new FormData()
      formData.append("user",`${id}`)

      getUserData({formData})

    },[])
    const {t}=useTranslation()
    console.log("data",data)
   const getOptionsSeries=(obj:Object):any=>{
    return ({
      options:{
        chart: {
          width: 380,
          type: "pie"
        },
        labels:data&&  [...Object.keys(obj),"Inactif"],
        colors:["#845ec2","#ffd3ff"],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: "bottom"
            }
          }
        }],
        dataLabels: {
          enabled: true,
          formatter: function (val:any, opts:any) { return opts.w.config.series[opts.seriesIndex] + '%'; },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"]
          }
        },
        legend: {
          show: true,
          position: "bottom",
          horizontalAlign: "center",
          fontSize: "14px",
          markers: {
            width: 19,
            height: 19,
            radius: 5
          },
          itemMargin: {
            horizontal: 8,
            vertical: 0
          }
        },
        tooltip: {
          y: {
            formatter: function (val:any) { return val + '%'; }
          }
        }
   },
   series: data && [...Object.values(obj).map(value => parseFloat(value.toFixed(2))), parseFloat((100 - Object.values(obj).reduce((acc, curr) => acc + parseFloat(curr), 0)).toFixed(2))]
    
   })}
   dayjs.locale("fr")
    useEffect(()=>{
      if(data){
        const selectedData = {

          "Pourcentage de commandes": data["Pourcentage de commandes"],
          "Pourcentage de chiffre d'affaire": data["Pourcentage de chiffre d'affaire"],
          "Pourcentage de commandes avec codes promos dans les commandes de cet utilisateur": data["Pourcentage de commandes avec codes promos dans les commandes de cet utilisateur"],
          "Pourcentage de commandes avec codes promos dans les commandes de tous les utilisateurs": data["Pourcentage de commandes avec codes promos dans les commandes de tous les utilisateurs"]
      };
        setState({ 
          options:{
            chart: {
              width: 380,
              type: "pie"
            },
            labels:data&& Object.keys(selectedData),
            colors:["#946dd3","#593796"],
            responsive: [{
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                },
                legend: {
                  position: "bottom"
                }
              }
            }],
            dataLabels: {
              enabled: true,
              formatter: function (val:any, opts:any) { return opts.w.config.series[opts.seriesIndex] + '%'; },
              offsetY: -20,
              style: {
                fontSize: "12px",
                colors: ["#304758"]
              }
            },
            legend: {
              show: true,
              position: "bottom",
              horizontalAlign: "center",
              fontSize: "14px",
              markers: {
                width: 19,
                height: 19,
                radius: 5
              },
              itemMargin: {
                horizontal: 8,
                vertical: 0
              }
            },
            tooltip: {
              y: {
                formatter: function (val:any) { return val + '%'; }
              }
            }
          },
          series:data&&Object.values(selectedData)})}}
          )

      return (
        <DashboardLayout
        title={clientData?clientData.data.filter((client:IClientData)=>client.id===Number(id))[0]?.email:""}
        selectedPath={RouteIdEnum.Dashboard}
        backPath={RouteIdEnum.Dashboard}
      >
     <Box
  sx={{
    boxShadow: 3,
    width: 'auto',
    height: 'auto',
    bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
    color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
    p: 1,
    m: 1,
    borderRadius: 2,
    fontSize: '0.875rem',
    fontWeight: '700',
  }}
>
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <Typography variant="h6" style={{ textAlign: "center" }} color="textSecondary">
        {email}
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h3 style={{ display: "inline-flex", alignItems: "center", justifyContent: "center" }}>
          Nombre de commandes:<p style={{ color: "red" }}>{data && data["Nombre de commandes"]}</p>
        </h3>
        <h3 style={{ display: "inline-flex", alignItems: "center", justifyContent: "center" }}>
          Chiffre d'affaire:<p style={{ color: "red" }}>{data && data["Chiffre d'affaire"]}</p>
        </h3>
      </div>
      <br/>
      <div style={{float:"right"}}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>

<DateTimePicker
  label={t("promotion_codes.start_date_title")}
  value={
    dayjs(startDate)

  }
  format="DD MMMM YYYY"
 // minDateTime={dayjs(new Date(new Date().setDate(new Date().getDate() - 1)))}
  maxDateTime={dayjs(endDate)}
 
  onChange={(start: any) => {setStartDate(new Date(start));
    

   }}
    
/>

</LocalizationProvider>


<LocalizationProvider dateAdapter={AdapterDayjs}>

<DateTimePicker
  label={t("promotion_codes.end_date_title")}
  value={
    dayjs(endDate)
  }
  format="DD MMMM YYYY"
  //minDateTime={dayjs(startDate).add(1,"minute")}
                 // defaultValue={ dayjs(new Date(new Date().setDate(new Date().getDate() + 1)))}

  onChange={(end: any) => {
    setEndDate(new Date(end));
    getUserData({start_date:Math.floor(startDate.getTime() / 1000),end_date:Math.floor(new Date(end).getTime() / 1000)})

  }}



/>
</LocalizationProvider>
</div>
    </Grid>
    <Grid item xs={12} sm={6}>
      {data && <Chart options={getOptionsSeries({"Pourcentage de commandes": data["Pourcentage de commandes"]}).options} series={getOptionsSeries({"Pourcentage de commandes": data["Pourcentage de commandes"]}).series} type="pie" height={350} />}
    </Grid>
    <Grid item xs={12} sm={6}>
      {data && <Chart options={getOptionsSeries({"Pourcentage de chiffre d'affaire": data["Pourcentage de chiffre d'affaire"]}).options} series={getOptionsSeries({"Pourcentage de chiffre d'affaire": data["Pourcentage de chiffre d'affaire"]}).series} type="pie" height={350} />}
    </Grid>
    <Grid item xs={12} sm={6}>
      {data && <Chart options={getOptionsSeries({"Pourcentage de commandes avec codes promos dans les commandes de cet utilisateur": data["Pourcentage de commandes avec codes promos dans les commandes de cet utilisateur"]}).options} series={getOptionsSeries({"Pourcentage de commandes avec codes promos dans les commandes de cet utilisateur": data["Pourcentage de commandes avec codes promos dans les commandes de cet utilisateur"]}).series} type="pie" height={350} />}
    </Grid>
    <Grid item xs={12} sm={6}>
      {data && <Chart options={getOptionsSeries({"Pourcentage de commandes avec codes promos dans les commandes de tous les utilisateurs": data["Pourcentage de commandes avec codes promos dans les commandes de tous les utilisateurs"]}).options} series={getOptionsSeries({"Pourcentage de commandes avec codes promos dans les commandes de tous les utilisateurs": data["Pourcentage de commandes avec codes promos dans les commandes de tous les utilisateurs"]}).series} type="pie" height={350} />}
    </Grid>
  </Grid>
</Box>
    </DashboardLayout>
        )

}
export default UserDataChart