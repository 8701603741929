import { AppBar, Toolbar, styled } from "@mui/material";
import {
  Menu as MenuIcon,
  ArrowBack,
  Lock,
  ExitToApp,MarkUnreadChatAlt
} from "@mui/icons-material";
import { APP_BAR_HEIGHT, CustomAppBarProps } from "./AppBar.type";

export const AppBarClassStyle = styled(AppBar)<CustomAppBarProps>(
  ({ theme, isappbarclassshift }) => ({
    backgroundColor: "#fff!important",
    height: `${APP_BAR_HEIGHT}px  !important`,
    "z-index": `${theme.zIndex.drawer + 1} !important`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: !isappbarclassshift
        ? theme.transitions.duration.leavingScreen
        : theme.transitions.duration.enteringScreen,
    }),
  })
);

export const AppBarClassContentStyle = styled(Toolbar)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-start",
  paddingLeft: "14px !important",
  minHeight: 0,
}));

export const LogoContainerStyle = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "start",
  alignItems: "center",
}));

export const MenuIconStyle = styled(MenuIcon)(({ theme }) => ({
  width: "27px !important",
  height: "27px !important",
}));

export const ShowLogoStyle = styled("div")(({ theme }) => ({
  left: "4%",
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

export const AppBarTitleStyle = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  marginLeft: 30,
}));

export const ArrowBackStyle = styled(ArrowBack)(({ theme }) => ({
  width: "27px !important",
  height: "27px !important",
}));

export const ScreenTitleStyle = styled("h1")(({ theme }) => ({
  margin: 0,
  padding: 0,
  textAlign: "center",
  overflow: "hidden",
  textOverflow: "ellipsis",
  wordBreak: "break-word",
  whiteSpace: "nowrap",
  fontWeight: "Bold",
  fontSize: 24,
  textTransform: "capitalize",
  color: theme.palette.secondary.main,
}));

export const LogoutContainerStyle = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
}));

export const UserNameStyle = styled("h4")(({ theme }) => ({
  margin: 0,
  padding: 0,
  textAlign: "center",
  overflow: "hidden",
  textOverflow: "ellipsis",
  wordBreak: "break-word",
  whiteSpace: "nowrap",
  fontSize: 12,
  color: theme.palette.secondary.main,
  [theme.breakpoints.down("sm")]: {
    maxWidth: 120,
    display: "none",
  },
}));

export const LockStyle = styled(Lock)(({ theme }) => ({
  width: "27px !important",
  height: "27px !important",
}));
export const MarkUnreadChatAltStyle = styled(MarkUnreadChatAlt)(({ theme }) => ({
  width: "27px !important",
  height: "27px !important",
}));
export const ExitToAppStyle = styled(ExitToApp)(({ theme }) => ({
  width: "27px !important",
  height: "27px !important",
}));

export const LogoTitleStyle = styled("h1")(({ theme }) => ({
  margin: 0,
  padding: 0,
  textAlign: "center",
  overflow: "hidden",
  textOverflow: "ellipsis",
  wordBreak: "break-word",
  whiteSpace: "nowrap",
  fontFamily: "Arial",
  fontSize: 24,
  color: theme.palette.secondary.main,
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));
