import { Box, Grid, Typography } from "@mui/material";

interface CardProps{
    title:string;
    children:any;
}


export const Card=(props:CardProps)=>{
    return(
    <Box
    sx={{
      boxShadow: 3,
      width: 'auto',
      height: 'auto',
      bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
      color: (theme) =>
        theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
      p: 1,
      m: 1,
      borderRadius: 2,
      fontSize: '0.875rem',
      fontWeight: '700',
    }}
  >
        <Grid item xs={12}>
     <Grid item style={{float: 'right'}}>
     </Grid>


     <Typography variant="h6" style={{textAlign:"center",backgroundColor:"#ddddff"}} color="textSecondary">
      {props.title}</Typography>
      <hr/>
      </Grid>
      {props.children}
    </Box>
    )
}