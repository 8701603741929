import { Fragment, useCallback, useEffect, useState } from "react";
import {
  ContainerStyle,
  ErrorStyle,
  FragmentStyle,
  OptionStyle,
} from "./AutoComplete.style";
import { AutoCompletePropsInterface } from "./AutoComplete.type";
import { useTranslation } from "react-i18next";
import { SelectChangeEvent, TextField,FormControl,Autocomplete } from "@mui/material";

function AutoComplete({
  required,
  value, // selected item
  error, // validation error msg
  onChange, // on change item
  label, // select label
  data, // items
  multiple,
  onBlur, // handle on blur
  none = false, // show empty option in select input
  disabel = false, // disabel select
  firstInput = false,
  onInputChange,
  getOptionLabel,
  renderInput,
  noOptionsText,
  renderTags,
  freeSolo,
  id
}: AutoCompletePropsInterface) {
  // translation hooks
  const { t } = useTranslation();
  // check if select is open or not
  const [open, setOpen] = useState<boolean>(false);
  //select input ref

  // on blur add text to label
  const handleChange = useCallback(
    (event:any,newValue:any) => {
        console.log("new",newValue)
      setOpen(false);
      if (onChange !== undefined) {
        console.log("onChange",event.target.value)
        onChange(event,newValue);
      }
    },
    [setOpen, onChange]
  );
  // on blur remove text from label
  const handleFocus = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  return ( 
    <FormControl fullWidth>
      <Autocomplete
       sx={{
        backgroundColor: 'white',
        
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor:  '#B269AE!important',
          },
        
        
        '&:hover ': {
          borderColor: 'white',
        },
        '&.Mui-focused ': {
          borderColor: '#B269AE!important',
          boxShadow: "0px 0px 6px 3px rgba(18, 63, 140, 0.3) !important",
          
        },
      },
      }}
        value={value}
        onChange={handleChange}
        freeSolo={freeSolo}
        options={data}
        onInputChange={onInputChange}
        multiple={multiple}
        renderTags={renderTags}
        renderInput={renderInput}
        getOptionLabel={getOptionLabel}
        noOptionsText={noOptionsText}

      />
     
    </FormControl>
  );
}
export default AutoComplete;
