import { IconButton } from "@mui/material";
import {
  AppBarClassContentStyle,
  AppBarClassStyle,
  AppBarTitleStyle,
  ArrowBackStyle,
  ExitToAppStyle,
  LockStyle,
  LogoContainerStyle,
  LogoTitleStyle,
  LogoutContainerStyle,
  MenuIconStyle,
  ScreenTitleStyle,
  UserNameStyle,
  MarkUnreadChatAltStyle
} from "./AppBar.style";
import { CustomAppBarPropsInterface } from "./AppBar.type";
import { useNavigate } from "react-router-dom";
import { useCurrentUserQuery, useLogoutMutation } from "redux/api/auth/auth";
import { useSnackBar } from "hooks/useSnackBar";
import { deleteTokenFromLocalStorage } from "utils/services/storage.service";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

function AppBar({
  drawer, // drawer is open
  handleDrawerStatus, // handle click to open drawer
  handleDialogChangePassword, // handle click to open dialog change password
  title, // screen title
  backPath, // redirect path
}: CustomAppBarPropsInterface) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { displaySuccessSnackBar, displayErrorApiSnackBar } = useSnackBar();
  const { data: userData } = useCurrentUserQuery({});

  const [logoutApi, { data: logoutData, error: logoutError }] =
    useLogoutMutation({});
  if (logoutError) {
    displayErrorApiSnackBar(logoutError);
  }
  useEffect(() => {
    if (logoutData) {
      deleteTokenFromLocalStorage();
      displaySuccessSnackBar(t("login.successfully_logout"));
      navigate("/login");
    }
  }, [logoutData]);

  // handle click logout button
  const handleLogout = () => {
    logoutApi({});
  };
  // handle click back button
  const handleBack = () => {
    navigate("/" + backPath);
  };

  if (logoutData) {
    deleteTokenFromLocalStorage();
    displaySuccessSnackBar(t("login.successfully_logout"));
    navigate("/login");
  }

  return (
    <AppBarClassStyle position="fixed" isappbarclassshift={true}>
      <AppBarClassContentStyle>
        <LogoContainerStyle>
          <IconButton
            color="secondary"
            aria-label="open drawer"
            onClick={handleDrawerStatus}
            edge="start"
          >
            <MenuIconStyle />
          </IconButton>
          <LogoTitleStyle>Kd Marché</LogoTitleStyle>
        </LogoContainerStyle>
        <AppBarTitleStyle>
          {backPath != null ? (
            <IconButton
              color="secondary"
              aria-label="back"
              onClick={handleBack}
            >
              <ArrowBackStyle />
            </IconButton>
          ) : (
            <div />
          )}
          <ScreenTitleStyle>{title}</ScreenTitleStyle>
          <LogoutContainerStyle>
            <UserNameStyle>
              {userData
                ? userData?.data?.data?.first_name +
                  " " +
                  userData?.data?.data?.last_name
                : ""}
            </UserNameStyle>

            <IconButton
              color="secondary"
              aria-label="change password"
              onClick={handleDialogChangePassword}
            >
              <LockStyle />
            </IconButton>
            <IconButton
              color="secondary"
              aria-label="Chat"
              onClick={() => window.open('https://chatterpal.me/live_chat/pub/32LpfQBd88ZM/chat', '_blank')}
            >
              <MarkUnreadChatAltStyle />
            </IconButton>
            <IconButton
              color="secondary"
              aria-label="logout"
              onClick={handleLogout}
            >
              <ExitToAppStyle />
            </IconButton>
          </LogoutContainerStyle>
        </AppBarTitleStyle>
      </AppBarClassContentStyle>
    </AppBarClassStyle>
  );
}
export default AppBar;
