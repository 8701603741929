import { SearchButtonPropsInterface } from "./SearchInput.type";
import { ChangeEvent, Fragment, useCallback, useEffect, useState } from "react";
import {
  CancelIconStyle,
  ContainerStyle,
  InputContainerStyle,
  InputSearchStyle,
  LoadingIconStyle,
  SearchIconButtonStyle,
  SearchIconStyle,
} from "./SearchInput.style";
import { CircularProgressStyle, LoaderContainerStyle } from "../Table/Table.style";
import { HourglassEmpty } from "@mui/icons-material";

function SearchInput({
  value, // text input value
  onChange, // handle on change
  placeholder = "", // placeholder
}: SearchButtonPropsInterface) {
  const [search, setSearch] = useState<string>(value);
  const [timer, setTimer] = useState<undefined | NodeJS.Timeout>(undefined);
  const [loading,setLoading]=useState(false)
  
  useEffect(() => {
    setLoading(true)
    setSearch(value)
    setTimeout(() => {
      setLoading(false);
    }, 1500);
    console.log("loading",loading)

  }, [value]);
  // handle click on password icon
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      clearTimeout(timer);
      setSearch(e.target.value);
      const _timer = setTimeout(() => {
        onChange(e);
      }, 1500);

      setTimer(_timer);
    },
    [setSearch, timer, onChange,loading]
  );

  // handle click on password icon
  const handleReset = useCallback(() => {
    clearTimeout(timer);
    setSearch("");
    const _timer = setTimeout(() => {
      onChange("");
    }, 1500);
    setTimer(_timer);
  }, [setSearch, timer, onChange]);

  return (
    <Fragment>
      <ContainerStyle>
        <InputContainerStyle>
          <InputSearchStyle
            placeholder={placeholder}
            value={search}
            onChange={handleChange}
          />
          
          <SearchIconButtonStyle
            color="primary"
            onClick={search !== "" ? handleReset : undefined}
          >
            {search !== "" && !loading  &&<CancelIconStyle />}
            {search === ""  && !loading && <SearchIconStyle />}
            {loading && search!=="" && <LoadingIconStyle />
         }
          </SearchIconButtonStyle>
        </InputContainerStyle>
      </ContainerStyle>
    </Fragment>
  );
}
export default SearchInput;
