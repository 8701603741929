import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import { CONFIG } from "config/config";
import {
  deleteTokenFromLocalStorage,
  getTokenFromLocalStorage,
} from "utils/services/storage.service";

const baseQuery = fetchBaseQuery({
  baseUrl: CONFIG.BASE_URL_API,
  prepareHeaders: (headers) => {
    if (getTokenFromLocalStorage()) {
      headers.set("authorization", `Bearer ${getTokenFromLocalStorage()}`);
    }
    return headers;
  },
});

export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  const status = result.meta?.response?.status;

  if (status === 401 && api.endpoint !== "login") {
    deleteTokenFromLocalStorage();
    setTimeout(() => {
      window.location.href = "/login";
    }, 500);
  }
  return result;
};
