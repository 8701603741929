import Table from "components/common/Table/Table";

import { useTranslation } from "react-i18next";
import { IExpeditionCategoriesData } from "./ExpeditionCategories.types";
import Dialog from "components/common/Dialog/Dialog";


import { IDialog } from "./ExpeditionCategories.types";
import { useCallback, useEffect } from "react";
import { Grid } from "@mui/material";
import useExpeditionCategoriesList from "./useExpeditionCategoriesList";
import { useGetCategoriesValuesExpeditionModeQuery } from "redux/api/delivery/expeditionMode/expeditionMode";
import { CircularProgressStyle } from "components/common/Table/Table.style";
function ExpeditionCategories({ openAddingDialog, setOpenAddingDialog,selectedElement,resetSelectedItem }: IDialog) {
  const { t } = useTranslation();



  
  const {
    data: categValue,
    isLoading
  } = useGetCategoriesValuesExpeditionModeQuery({
id:selectedElement})
  const closeAddingDialog = useCallback(() => {
    setOpenAddingDialog(false);
    resetSelectedItem()

  }, [setOpenAddingDialog]);




  return (
    <Dialog
    open={openAddingDialog}
    handleClose={closeAddingDialog}
    title={t("category.list")}
  >
   {isLoading ?<Grid container spacing={1} justifyContent="center" alignItems="center"><CircularProgressStyle/></Grid>: <Grid container spacing={1} justifyContent="center" alignItems="center">
    <Grid item xs={5}>

        <h3 style={{ textAlign: 'center' }} >Category</h3>
        </Grid>
        <Grid item xs={5}>
  <h3 style={{ textAlign: 'center' }}>Valeur</h3>
  </Grid>
        {categValue?.map((e: IExpeditionCategoriesData) => {
          return (
            <>
            <Grid item xs={5}>
            <p style={{ textAlign: 'center' }}>{e?.category_shipping?.name}</p>
            </Grid>
                    <Grid item xs={5}>

             <p style={{ textAlign: 'center' }}>{e.price}</p>
             </Grid>
             </>
          )
        })}
      </Grid>


      }

    </Dialog>

  );
}
export default ExpeditionCategories;
