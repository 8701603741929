import TextInput from "components/common/TextInput/TextInput";
import { ContainerButtonStyle, DeleteButtonsStyle,  InputStyle } from "./AddDelievery.style";
import { Button } from "@mui/material";
import Dialog from "components/common/Dialog/Dialog";

import { DialogTitleStyle } from "components/common/Dialog/Dialog.style";
import { ChangeEvent, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { IDeliveyAddingData,IAddDialog } from "./AddDelievery.types";
function AddDelivery({ openAddingDialog, setOpenAddingDialog }: IAddDialog){
    const {t}=useTranslation()
    const [addingData,setAddingData]=useState<IDeliveyAddingData>({
        name:"",
        description:"",
        region:"",
        horaire:""
    });
    const onSuccessAddingDialog = (event: React.FormEvent) => {
      /*  event.preventDefault();
    
        const formData = new FormData();
        formData.append("title", addingData.title);
        if (addingData.image) formData.append("image", addingData.image);
    
        addingCategory(formData);
        closeAddingDialog();*/
      };
      const closeAddingDialog = useCallback(() => {
        setOpenAddingDialog(false);
      }, [setOpenAddingDialog]);
    const onChange = useCallback(
        (field: string) =>
          (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setAddingData({ ...addingData, [field]: e.target.value });
          },
        [addingData]
      );
    return(
        <Dialog
        open={openAddingDialog}
        handleClose={closeAddingDialog}
        title={"Add Delivery"}
      >
        <form onSubmit={onSuccessAddingDialog}>     
   
        <InputStyle>
          <TextInput
            value={addingData.name}
            label={`${t("delivery.name")}`}
            onChange={onChange("name")}
            error={""}
          />
        </InputStyle>
        <InputStyle>
          <TextInput
            value={addingData.description}
            label={`${t("delivery.region")}`}
            onChange={onChange("region")}
            error={""}
          />
        </InputStyle>
        <h2>Horaire</h2>
        <div style={{justifyContent:"space-between"}}>
        <InputStyle>
          <TextInput
            value={addingData.region}
            label={`${t("delivery.start_time")}`}
            onChange={onChange("horaire")}
            error={""}
          />
        </InputStyle>
        <InputStyle>
          <TextInput
            value={addingData.region}
            label={`${t("delivery.end_time")}`}
            onChange={onChange("horaire")}
            error={""}
          />
        </InputStyle>
        </div>

        

    
        <DeleteButtonsStyle>
          <ContainerButtonStyle>
            <Button
              title={"Cancel"}
              onClick={()=>{}}
            />
          </ContainerButtonStyle>
          <ContainerButtonStyle>
            <Button
              type="submit"
              title={"Submit"}
            />
          </ContainerButtonStyle>
        </DeleteButtonsStyle>
      </form>
      </Dialog>
      )
}
export default AddDelivery