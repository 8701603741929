import { Grid, FormControl, styled } from "@mui/material";

export const PaginationContainerStyle = styled(Grid)(({ theme }) => ({
 margin: 15
}));

export const SelectContainerStyle = styled("div")(({ theme }) => ({
  marginTop: 5,
  marginBottom: 5,
  display: "flex",
  justifyContent: "flex-start",
  [theme.breakpoints.down("sm")]: {
    justifyContent: "center",
  },
}));

export const FormControlStyle = styled(FormControl)(({ theme }) => ({
  "&>div": {
    height: 30,
    overflow: "hidden",
  },
}));

export const PaginationTableContainerStyle = styled("div")(({ theme }) => ({
  marginTop: 5,
  marginBottom: 5,
  display: "flex",
  justifyContent: "center",
}));

export const LeftComponentContainerStyle = styled("div")(({ theme }) => ({
  marginTop: 5,
  marginBottom: 5,
  display: "flex",
  justifyContent: "flex-end",
  [theme.breakpoints.down("sm")]: {
    justifyContent: "center",
  },
}));
