export const ENDPOINTS = {
  LOGIN: "/auth/login-super-admin",
  LOGOUT: "/auth/logout",
  PROFILE: "/auth/profile",
  UPDATE_PASSWORD: "/auth/update-password-super-admin",
  PROMOTION_CODES: "/promotion-codes",
  CATEGORY: "/category",
  CLIENT: "/clients",
  UPDATE_STATUS: "/enable-disable",
  PRODUCT: "/products",
  ORDERS: "/order-histories",
  SUPPLIERS:"/providers",
  SUBCATEGORIES:"/subcategories",
  DELIVERY:"/deliveries",
  EXPEDITION_CATEG:"/category_shippings",
  EXPEDITION_MODE:"/shipping_modes",
  TAXES:"/taxes",
  PROMOTION_FLASH:"/promotions-flash",
  BRAND:"/brands",
  HORAIRES:"/horaires",
  HOLIDAYS:"/holidays"


};
