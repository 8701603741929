import React from 'react';
import { ApplyTaxButtonStyle, ApplyIconStyle, ApplyActionTitleStyle } from './ApplyTaxButton.style';
import { ApplyTaxButtonPropsInterface } from './ApplyTaxButton.types';

function ApplyTaxButton({ onClick, title, className }: ApplyTaxButtonPropsInterface) {
  return (
    <ApplyTaxButtonStyle onClick={onClick} className={className}>
      <ApplyIconStyle />
      <ApplyActionTitleStyle>{title}</ApplyActionTitleStyle>
    </ApplyTaxButtonStyle>
  );
}

export default ApplyTaxButton;
