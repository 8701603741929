import moment from "moment";

// Transfer date to a date string
export const dateToString = (date: Date) => {
  return date.toISOString().split("T")[0];
};

export const isTimeStampsDateBeforeToday = (date: string) => {
  const endDate = moment.unix(parseInt(date));
  const today = moment();
  return today.isSameOrBefore(endDate, "day");
};

export const isTimeStampsDateBetweenTwoDays = (
  date1: string,
  date2: string
) => {
  const startDate = moment.unix(parseInt(date1));
  const endDate = moment.unix(parseInt(date2));
  const today = moment();
  return (
    today.isSameOrAfter(startDate, "day") &&
    today.isSameOrBefore(endDate, "day")
  );
};
export const isTimeStampsDateBetweenTwoDays2 = (
  date1: number,
  date2: number
) => {
  const currentTimestamp = Math.floor(Date.now() / 1000); // Current timestamp in seconds
  return currentTimestamp >= date1 && currentTimestamp <= date2;
};


export const isNumber = (text: string | number): boolean => {
  // Regular expression to match a valid number or float
  const numberRegex = /^[+-]?(\d*\.\d+|\d+)$/;

  return numberRegex.test(text.toString());
};
