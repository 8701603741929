import { styled } from "@mui/material";
import {
  DRAWER_WIDTH_MAX,
  DRAWER_WIDTH_MIN,
} from "components/Dashboard/Menu/Menu.type";
import { APP_BAR_HEIGHT } from "components/Dashboard/AppBar/AppBar.type";

export const MainContentStyle = styled("main")(({ theme }) => ({
  backgroundColor: "#aa80800d!important",
  flexGrow: 1,
  minHeight: "100vh",
  overflow: "hidden",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "right bottom",
  backgroundAttachment: "fixed",
  marginLeft: 0,
  [theme.breakpoints.up("md")]: {
    marginLeft: DRAWER_WIDTH_MIN,
  },
  transition: theme.transitions.create("margin-left", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
}));

export const MainContentShiftStyle = styled("main")(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    flexGrow: 1,
    minHeight: "100vh",
    overflow: "hidden",
    backgroundColor: "#aa80800d!important",

    backgroundRepeat: "no-repeat",
    backgroundPosition: "right bottom",
    backgroundAttachment: "fixed",
    transition: theme.transitions.create("margin-left", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  [theme.breakpoints.up("md")]: {
    flexGrow: 1,
    minHeight: "100vh",
    overflow: "hidden",
    backgroundColor: "#aa80800d!important",

    backgroundRepeat: "no-repeat",
    backgroundPosition: "right bottom",
    backgroundAttachment: "fixed",
    marginLeft: DRAWER_WIDTH_MAX,
    transition: theme.transitions.create("margin-left", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));

export const ToolbarStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: 10,
  height: APP_BAR_HEIGHT,
  width: "82%",
}));

export const ChildrenStyle = styled("div")(({ theme }) => ({

}));

export const InputStyle = styled("div")(({ theme }) => ({
  width: "100%",
  marginTop: 20,
  marginBottom: 20,
}));

export const SendStyle = styled("div")(({ theme }) => ({
  width: "auto",
  float: "right",
}));
