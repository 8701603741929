import { useTranslation } from "react-i18next";
import { ChangeEvent, useCallback, useState, useEffect } from "react";
import Dialog from "components/common/Dialog/Dialog";
import Button from "components/common/Button/Button";
import {
  ContainerButtonStyle,
  DeleteButtonsStyle,
  ImageContainerStyle,
  ImageStyle,
  InputStyle,
} from "./EditSubCategory.style";
import { useSnackBar } from "hooks/useSnackBar";
import TextInput from "components/common/TextInput/TextInput";
import { useGetSubCategoriesQuery, useUpdateCategoryMutation } from "redux/api/category/category";
import FileInput from "components/common/FileInput/FileInput";
import { CONFIG, GlobalVariables } from "config/constant";
import {
  ISubCategoryEditingData,
  ISubCategoryEditingDataError,
  ISubCategoryEditingDialog,
} from "./EditSubCategory.types";
import { useParams } from "react-router-dom";
import useSubCategoryList from "../SubCategoryList/useSubCategoryList";

function EditSubCategory({
  id,
  iniData,
  openEditingDialog,
  setOpenEditingDialog,
}: ISubCategoryEditingDialog) {
  console.log("iniData",iniData)
  const { t } = useTranslation();
  const { displaySuccessSnackBar, displayErrorApiSnackBar } = useSnackBar();

  const [editingCategory, { data, error, isLoading, reset }] =
    useUpdateCategoryMutation();

  if (error) {
    displayErrorApiSnackBar(error);
    reset();
  }
  if (data) {
    displaySuccessSnackBar(t("category.sub_cat_successfully_editing"));
    reset();
  }


  const closeEditingDialog = useCallback(() => {
    setOpenEditingDialog(false);
    setEditingError({ title: "", image: "" });
    setEditingData({ title: "", image: null });
  }, [setOpenEditingDialog]);
  const validate=()=>{
    const addingErrorCateg:{title:string,image:string}={
      title:"",
      image:"",
    }
    if(!editingData.title){
      addingErrorCateg.title="Titre obligatoire"
    }
    else {
      addingErrorCateg.title=""

    }
    if(!editingData.image){
      addingErrorCateg.image="Image obligatoire"

    }
    else {
      addingErrorCateg.image=""

    }
    setEditingError(addingErrorCateg)
    console.log("AddingError",addingErrorCateg)

  }




  const onSuccessEditingDialog = (event: React.FormEvent) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("title", editingData.title);
    formData.append(
      "is_image_updated",
      editingData.is_image_updated?.toString() ?? "0"
    );
    validate();

    if (editingData.image) formData.append("image", editingData.image)
    if(editingData.image && editingData.title){
    editingCategory({ id: editingData.id, formData: formData });
    closeEditingDialog();
  }
  };

  const [editingData, setEditingData] = useState<ISubCategoryEditingData>({
    id: iniData.id,
    title: iniData.title,
    image: iniData.image,
    is_image_updated: 0,
  });
  const [editingError, setEditingError] = useState<ISubCategoryEditingDataError>({
    title: "",
    image: "",
  });
  const onChange = useCallback(
    (field: string) =>
      (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setEditingData({ ...editingData, [field]: e.target.value });
        validate()
        if (field === "image") {
          const files = (e.target as HTMLInputElement).files;
          if (files)
            setEditingData({
              ...editingData,
              is_image_updated: 1,
              image: files[0],
            });
        }
      },
    [editingData]
  );
  const onChangeOneImage = useCallback(
    (field: string) => (e: any) => {
      const files = (e.target as HTMLInputElement).files;
  
      if (files) {
        const file = files[0];
  
        // Check if the selected file is an image
        if (file.type.startsWith("image/")) {
          const reader = new FileReader();
  
          reader.onload = (event) => {
            if (event.target) {
              const image = new Image();
              image.src = event.target.result as string;
  
              image.onload = () => {
                const imageWidth = image.width;
                const imageHeight = image.height;
  
                console.log("Image Width:", imageWidth);
                console.log("Image Height:", imageHeight);
  
                // Ensure image is 300x300 pixels
                if (imageWidth === 300 && imageHeight === 300) {
                  setEditingError({ ...editingError, image: "" });
  
                  // Set the updated image and mark is_image_updated as 1
                  setEditingData({
                    ...editingData,
                    is_image_updated: 1, // Set to 1 when a valid image is selected
                    image: file,
                  });
                } else {
                  setEditingError({
                    ...editingError,
                    image: "L'image doit être de taille 300 × 300 pixels.",
                  });
                }
              };
            }
          };
  
          // Read the selected file as a data URL
          reader.readAsDataURL(file);
        } else {
          // Handle non-image file type
          console.error("Selected file is not an image.");
        }
      }
    },
    [editingData, editingError]
  );
  
  useEffect(() => {
    if (openEditingDialog)
      setEditingData({
        id: iniData.id,
        title: iniData.title,
        image: iniData.image,
      });
  }, [openEditingDialog, iniData.id, iniData.image, iniData.title]);

  return (
    <Dialog
      open={openEditingDialog}
      handleClose={closeEditingDialog}
      title={t("category.edit_sub_cat")}
    >
      <form onSubmit={onSuccessEditingDialog}>
        <InputStyle>
          <TextInput
            value={editingData.title}
            label={t("category.title")}
            onChange={onChange("title")}
            error={t(editingError.title)}
          />
        </InputStyle>
        <InputStyle>
          <FileInput
            value={
                             typeof editingData.image != "string" ? editingData.image : null

            }
            label={t("category.image")}
            onChange={onChangeOneImage("image")}
            error={t(editingError.image)}
            accept={GlobalVariables.FILE_FORMATS.map((e) => e.format).join(",")}
          />
        </InputStyle>
        {editingData.image && (
          <ImageContainerStyle>
            <ImageStyle
              src={
                typeof editingData.image === "string"
                  ? CONFIG.BASE_URL + editingData.image
                  : URL.createObjectURL(editingData.image)
              }
            ></ImageStyle>
          </ImageContainerStyle>
        )}

        <DeleteButtonsStyle>
          <ContainerButtonStyle>
            <Button
              disabled={isLoading}
              title={t("common.cancel_button")}
              onClick={closeEditingDialog}
            />
          </ContainerButtonStyle>
          <ContainerButtonStyle>
            <Button
              disabled={isLoading}
              type="submit"
              loader={isLoading}
              title={t("common.confirm_button")}
            />
          </ContainerButtonStyle>
        </DeleteButtonsStyle>
      </form>
    </Dialog>
  );
}
export default EditSubCategory;
