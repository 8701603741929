import React, { useState, useEffect } from 'react';
import ApexCharts from 'react-apexcharts';
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import { Chart } from "react-google-charts";
import { Box, Tooltip, Typography } from '@mui/material';
import dataJSON from "./response.json"
interface CountryData {
  country: string;
  activeUsers: number;
  averageSessionDuration: string;
  color:string;
}

const MapChart: React.FC<{ setTooltipContent: React.Dispatch<React.SetStateAction<string>> }> = ({ setTooltipContent }) => {
  const [data, setData] = useState<CountryData[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
 
        setData(dataJSON.data.countries.map((country)=>({...country,color:"#631958bd"})));
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };

    fetchData();
  }, []);
useEffect(()=>{
  console.log("data Map fetch",data)
},[data])
  return (
    <ComposableMap
    projection="geoMercator"
    projectionConfig={{ scale: 100 }}
    style={{ width: '100%', height: 'auto' }}
  >
    <Geographies geography={"/features.json"}>
      {({ geographies }) =>
        geographies.map(geo => {
          const countryStat = data.find(
            (stat) => stat.country === geo.properties.name
          )
          console.log("countryStat",countryStat)
          console.log("data Maps",data)
          const { country, activeUsers, averageSessionDuration } = data.find(item => item.country === geo.properties.NAME) || { country: "", activeUsers: 0, averageSessionDuration: "" ,color:"#631958bd"};
          return (
           countryStat? <Tooltip key={geo.rsmKey} title={`Pays:${countryStat?.country}`}>
            <Geography
              key={geo.rsmKey}
              geography={geo}
              fill={countryStat&&countryStat.color}
            
            />
            </Tooltip>:
            <Geography
            key={geo.rsmKey}
            geography={geo}
          
          />
          );
        })
      }
    </Geographies>
  </ComposableMap>
  );
};


// Define the ChartComponent
{/*const ChartComponent: React.FC = () => {
  return (
    <Chart
      width={'500px'}
      height={'300px'}
      chartType="Bar"
      loader={<div>Loading Chart...</div>}
      data={[
        ['Country', 'Active Users'],
        ['Tunisia', 9],
        ['Guadeloupe', 1],
        // Add more countries as needed
      ]}
      options={{
        chart: {
          title: 'Top Countries by Active Users',
        },
      }}
    />
  );
};*/}

// Define the ApexChartsComponent
{/*const ApexChartsComponent: React.FC = () => {
  return (
    <ApexCharts
      options={{
        xaxis: {
          categories: ['Tunisia', 'Guadeloupe'], // Add more countries as needed
        },
      }}
      series={[
        {
          name: 'Active Users',
          data: [9, 1], // Active users data for each country
        },
        {
          name: 'Average Session Duration',
          data: [5748.42, 7372.38], // Avg session duration data for each country
        },
      ]}
      type="bar"
      height={350}
    />
  );
};
*/}
const Dashboard: React.FC = () => {
    const [tooltipContent, setTooltipContent] = useState('');
  
    return (
        <Box
        sx={{
          boxShadow: 3,
          width: 'auto',
          height: 'auto',
          bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
          color: (theme) =>
            theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
          p: 1,
          m: 1,
          borderRadius: 2,
          fontSize: '0.875rem',
          fontWeight: '700',
        }}
      >
           <Typography variant="h6" style={{textAlign:"center"}} color="textSecondary">
   Carte</Typography>
            <MapChart setTooltipContent={setTooltipContent} />
         
          </Box>
    );
  };
  
  export default Dashboard;
  