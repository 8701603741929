export const category = {
  page_title: "Catégories de produits",
  title: "Titre",
  image: "Image",
  created_at: "Date de création",
  successfully_deleted: "Catégorie est supprimée avec succès",
  successfully_adding: "Catégorie est ajoutée avec succès",
  successfully_editing: "Catégorie est editée avec succès",
  delete: "Supprimer la Catégorie",
  deleteMulti:"Confirmation de suppression",
  add: "Ajouter la Catégorie",
  edit: "Modifier la Catégorie",
  add_sub_cat:"Ajouter une sous catégorie",
  edit_sub_cat:"Modifer une sous catégorie",
  delete_sub_cat:"Supprimer une sous catégorie",
  sub_cat_successfully_deleted: "Sous Catégorie est supprimée avec succès",
  sub_cat_successfully_adding: "Sous Catégorie est ajoutée avec succès",
  sub_cat_successfully_editing: "Sous Catégorie est editée avec succès",
  sub_cat_page_title: "Sous Catégories de Catégorie",
  list:"Liste de Catégories-Valeurs"


};
