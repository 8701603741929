import { styled, Badge } from "@mui/material";

export const BadgeStyle = styled(Badge)(({ theme }) => ({
  marginLeft:20,
  "& .MuiBadge-dot": {
    height: 20,
    width: 20,
    borderRadius: "50%",
  },
}));
