import { IconButton, styled } from '@mui/material';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';

export const ApplyTaxButtonStyle = styled(IconButton)(({ theme }) => ({
  width: 35,
  height: 35,
  margin: '0px 4px',
  borderRadius: 18,
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 5,
  paddingRight: 5,
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  '& p': {
    visibility: 'hidden',
  },
  transition: theme.transitions.create('all', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    width: 'auto',
    '& p': {
      visibility: 'visible',
      transition: theme.transitions.create('visibility', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
        delay: theme.transitions.duration.enteringScreen / 2,
      }),
      paddingRight: 5,
    },
  },
  backgroundColor: theme.palette.primary.main,
}));

export const ApplyIconStyle = styled(ReceiptLongIcon)(({ theme }) => ({
  color: 'white',
}));

export const ApplyActionTitleStyle = styled('p')(({ theme }) => ({
  fontSize: 14,
  color: 'white',
  margin: 0,
  padding: 0,
  marginLeft: 10,
  marginRight: 10,
}));
