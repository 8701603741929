import useTaxList from "features/Taxe/TaxeList/useTaxList";
import React, { useState } from "react";
import { RiCloseLine } from "react-icons/ri";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import { applyTax } from "UpdatedFeatures/api";
import { useInvalidateProductCacheMutation, } from "redux/api/product/product";
import useProductList from "./useProductList";
const TaxModal = ({
    open,
    setOpen,
    selectAll,
    productsIds,
    search,
    status,
    garantie,
    category,
    subCategory_id,
    date_limite_de_consommation,
    selectedPOS,
    selectedStatus,
}) => {

    console.log(search)
    console.log(selectedPOS)
    const [selectedTax, setSelectedTax] = useState("");
    const { taxesData } = useTaxList();
    const [invalidateProductCache] = useInvalidateProductCacheMutation();
    const mutation = useMutation({
        mutationFn: (
            selectedTax,
            productsIds,
            selectAll,
            search,
            status,
            garantie,
            category,
            subCategory_id,
            date_limite_de_consommation,
            selectedPOS,
            selectedStatus,) => applyTax(
                selectedTax,
                productsIds,
                selectAll,
                search,
                status,
                garantie,
                category,
                subCategory_id,
                date_limite_de_consommation,
                selectedPOS,
                selectedStatus,),
        onSuccess: () => {
            setOpen(false);
            toast.success("Taxe appliqué avec Succés");
            invalidateProductCache();
        },
        onError: () => {
            toast.error("Erreur")
        },
    });
    if (!open) return null;

    // Handle form submit
    const handleSubmit = (e) => {
        e.preventDefault();
        // Call the mutation to apply the tax
        mutation.mutate({
            taxId: selectedTax,
            productsIds,
            selectAll,
            search,
            status,
            garantie,
            category,
            subCategory_id,
            date_limite_de_consommation,
            selectedPOS,
            selectedStatus,
        });
    };

    return (
        <div
            className="fixed inset-0 z-50"
            style={{ zIndex: 9999, overflow: "visible" }}
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
        >
            {/* Overlay */}
            <div
                className="fixed inset-0 bg-gray-500 bg-opacity-75"
                aria-hidden="true"
                onClick={() => setOpen(false)} // Close modal when clicking the overlay
            />

            {/* Modal Content */}
            <div className="flex items-center justify-center min-h-screen p-4">
                <div
                    className="relative w-full max-w-lg mx-auto bg-white shadow-lg rounded-lg overflow-hidden"
                    onClick={(e) => e.stopPropagation()} // Prevent clicks inside the modal from closing it
                >
                    {/* Header */}
                    <div className="flex justify-between items-center p-6 bg-[#b269ae] border-b border-gray-500/30 pb-4">
                        <h3 id="modal-title" className="text-lg font-medium leading-6 text-white">
                            Appliquer un Taxe
                        </h3>
                        <button
                            type="button"
                            className="inline-flex items-center justify-center p-1.5 text-gray-400 hover:text-gray-500 hover:scale-105 duration-200"
                            onClick={() => setOpen(false)}
                        >
                            <RiCloseLine className="h-6 w-6 text-white" aria-hidden="true" />
                        </button>
                    </div>

                    <form className="space-y-2 p-6" onSubmit={handleSubmit}>
                        <div className="grid grid-cols-1 gap-x-4 sm:grid-cols-1 gap-y-4 mt-5">
                            <div className="w-full">
                                <label htmlFor="tax" className="block text-sm font-medium text-gray-700">
                                    Sélectionnez une taxe
                                </label>
                                <select
                                    id="tax"
                                    name="tax"
                                    value={selectedTax}
                                    onChange={(e) => setSelectedTax(e.target.value)} // Update state when selection changes
                                    required
                                    className="mt-1 block w-full rounded-lg border border-gray-300 bg-gray-50 shadow-sm focus:border-gray-800 focus:ring-1 focus:ring-gray-800 text-gray-700 h-12 px-4 transition duration-300 ease-in-out"
                                >
                                    <option value="">Sélectionnez une taxe</option>
                                    {taxesData?.data?.map((tax) => (
                                        <option key={tax.id} value={tax.id}>
                                            {tax.name} ({tax.percentage}%)
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className="mt-6 flex justify-end gap-6">
                            <button
                                type="submit"
                                className={`block rounded-md bg-[#b269ae] px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-yellow-300 duration-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600 ${mutation.isLoading ? "cursor-not-allowed opacity-50" : ""
                                    }`}
                                disabled={mutation.isLoading} // Disable button when loading
                            >
                                {mutation.isLoading ? "Submitting..." : "Appliquer"}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default TaxModal;
