import {
  DraftActionTitleStyle,
  DraftButtonStyle,
  DraftIconStyle,
} from "./DraftButton.style";
import { AddButtonPropsInterface } from "./DraftButton.type";
import { useTranslation } from "react-i18next";

function EditButton({ onAddClick, title, className }: AddButtonPropsInterface) {
  // translation hooks
  const { t } = useTranslation();
  return (
    <DraftButtonStyle onClick={onAddClick} className={className}>
      <DraftIconStyle />
      <DraftActionTitleStyle>{t(title)}</DraftActionTitleStyle>
    </DraftButtonStyle>
  );
}
export default EditButton;
