import { APP_BAR_HEIGHT } from "../AppBar/AppBar.type";
import { ChevronLeft } from "@mui/icons-material";
import { Drawer, styled } from "@mui/material";
import { MenuDrawerProps } from "./Drawer.type";
import { DRAWER_WIDTH_MAX, DRAWER_WIDTH_MIN } from "../Menu/Menu.type";

export const MenuContainerStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: 10,
  height: APP_BAR_HEIGHT,
  width: "82%",
}));

export const CloseButtonStyle = styled(ChevronLeft)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const LogoMenuStyle = styled("img")(({ theme }) => ({
  transform: "scale(0.7)",
  position: "absolute",
  left: "12%",
}));

export const DrawerStyle = styled(Drawer)<MenuDrawerProps>(
  ({ theme, isdraweropened }) => ({
    "& .MuiPaper-root": {
      width: isdraweropened
        ? `${DRAWER_WIDTH_MAX}px !important`
        : DRAWER_WIDTH_MIN,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    [theme.breakpoints.down("sm")]: {
      flexShrink: 0,
      whiteSpace: "nowrap",
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: isdraweropened
          ? theme.transitions.duration.enteringScreen
          : theme.transitions.duration.leavingScreen,
      }),

      borderRight: `0px !important`,
      "& .MuiPaper-root": {
        width: isdraweropened
          ? `${DRAWER_WIDTH_MAX}px !important`
          : DRAWER_WIDTH_MIN,
        position: "relative",
        whiteSpace: "nowrap",
        backgroundColor: theme.palette.primary.light,
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  })
);

export const ToolbarStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: 10,
  height: APP_BAR_HEIGHT,
  width: "82%",
}));

export const LogoStyle = styled("img")(({ theme }) => ({
  height: "25%",
  width: "25%",
  display: "flex",
  justifyContent: "center",
  alignSelf: "center",
  marginBottom: 10,
}));
