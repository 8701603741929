export enum RouteIdEnum {
  Root = "",
  Any = "*",
  id = ":id",
  Add = "add",
  Edit = "edit",
  Home = "home",
  Login = "login",
  FORGOT_PASSWORD = "/forgot-password",
  Clients = "clients",
  PromotionCodes = "promotion-codes",
  Categories = "categories",
  Products = "products",
  POS = "pos",
  Vendeurs = "vendeurs",
  stockPlus = "stockPlus",
  OrderHistory = "order-history",
  Supplier = "supplier",
  SubCategory = "subcategories/:id",
  Delivery = "delivery",
  Expedition_Categories = "expedition-categories",
  Expedition_Mode = "expedition-mode",
  Tax = "taxes",
  Promo_Flash = "promo-flash",
  Dashboard = "dashboard",
  Brand = "brand",
  Stats_Visit = "stats-visit",
  Advanced_Stat = "advanced-stat", // <-- Add this line
  Opening_Hours = "opening-hours",
  Chat = "chat",
}
