export const login = {
  login: "Connexion",
  disabled_user: "Utilisateur désactivé",
  email_place_holder: "Veuillez saisir votre email.",
  password_place_holder: "Veuillez saisir votre mot de passe.",
  email: "Email",
  password: "Mot de passe",
  password_confirmation: "Confirmation de mot de passe",
  successfully_logout: "Déconnexion avec succès",
  successfully_update_password: "Mot de passe est modifié avec succès",
};
