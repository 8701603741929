import { createApi } from "@reduxjs/toolkit/query/react";
import { ENDPOINTS } from "config/constant/endpoints.config";
import { Paginator } from "types/interfaces";
import { injectParamsPaginator } from "utils/helpers/api-url";
import { baseQueryWithReauth } from "../rtkBaseQuery";

export const statisticsUsersApi = createApi({
  reducerPath: "statisticsUsersApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["StatisticUser"],
  endpoints: (builder) => ({
    getUserStatistics: builder.mutation({
      query: ({
        start_date,
        end_date
      }: {start_date?:number,end_date?:number}) => ({
        url: `/stats/users`,
        method: "POST",
        body: start_date && end_date? {  start_date,
            end_date
           }:{},
      }),
      invalidatesTags: ["StatisticUser"],
    }),
  })
  
})

export const { useGetUserStatisticsMutation} =statisticsUsersApi;
