import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from "@reduxjs/toolkit";
import { promotionCodesApi } from "./api/promotionCodes/promotionCodes";
import { SnackBarReducer } from "./slices/SnackBar/SnackBarSlice";
import { categoryApi } from "./api/category/category";
import { productApi } from "./api/product/product";
import { authApi } from "./api/auth/auth";
import { clientApi } from "./api/client/client";
import { orderApi } from "./api/order/order";
import { suppliersApi } from "./api/suppliers/suppliers";
import { taxesApi } from "./api/tax/taxes";
import { expeditionModeiApi } from "./api/delivery/expeditionMode/expeditionMode";
import { expeditionCategoryApi } from "./api/delivery/expeditionCategory/expeditionCategory";
import {expeditionCategsApi} from "./api/delivery/expeditionCategs/expeditionCategs"
import { promotionFlashsApi } from "./api/promotionFlash/promotionFlash";
import { statisticsOrderApi } from "./api/statistics/orders";
import { statisticsProductApi } from "./api/statistics/products";
import { statisticsPromotionCodeApi } from "./api/statistics/promotionCodes";
import { statisticsProviderApi } from "./api/statistics/providers";
import { statisticsUsersApi } from "./api/statistics/users";
import { statisticsUserDataApi } from "./api/statistics/user";
import { statisticsExpeditionModeApi } from "./api/statistics/expeditionModes";
import { statisticsBrandApi } from "./api/statistics/brand";
import { brandApi } from "./api/brand/brand";
import { HorairesApi } from "./api/Horaires/Horaires";
import { HolidaysApi } from "./api/Holidays/Holidays";
const rootReducer = combineReducers({
  [promotionCodesApi.reducerPath]: promotionCodesApi.reducer,
  [categoryApi.reducerPath]: categoryApi.reducer,
  [productApi.reducerPath]: productApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [clientApi.reducerPath]: clientApi.reducer,
  [orderApi.reducerPath]: orderApi.reducer,
  [suppliersApi.reducerPath]: suppliersApi.reducer,
  [taxesApi.reducerPath]:taxesApi.reducer,
  [expeditionModeiApi.reducerPath]:expeditionModeiApi.reducer,
  [expeditionCategoryApi.reducerPath]:expeditionCategoryApi.reducer,
  [expeditionCategsApi.reducerPath]:expeditionCategsApi.reducer,
  [promotionFlashsApi.reducerPath]:promotionFlashsApi.reducer,
  [statisticsOrderApi.reducerPath]:statisticsOrderApi.reducer,
  [statisticsProductApi.reducerPath]:statisticsProductApi.reducer,
  [statisticsPromotionCodeApi.reducerPath]:statisticsPromotionCodeApi.reducer,
  [statisticsProviderApi.reducerPath]:statisticsProviderApi.reducer,
  [statisticsUsersApi.reducerPath]:statisticsUsersApi.reducer,
  [statisticsUserDataApi.reducerPath]:statisticsUserDataApi.reducer,
  [statisticsExpeditionModeApi.reducerPath]:statisticsExpeditionModeApi.reducer,
  [brandApi.reducerPath]:brandApi.reducer,
  [HorairesApi.reducerPath]:HorairesApi.reducer,
  [HolidaysApi.reducerPath]:HolidaysApi.reducer,
  [statisticsBrandApi.reducerPath]:statisticsBrandApi.reducer,
  snackBarReducer: SnackBarReducer,
});
export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat([
      promotionCodesApi.middleware,
      categoryApi.middleware,
      productApi.middleware,
      clientApi.middleware,
      authApi.middleware,
      orderApi.middleware,
      suppliersApi.middleware,
      taxesApi.middleware,
      expeditionModeiApi.middleware,
      expeditionCategoryApi.middleware,
      expeditionCategsApi.middleware,
      promotionFlashsApi.middleware,
      statisticsOrderApi.middleware,
      statisticsProductApi.middleware,
      statisticsPromotionCodeApi.middleware,
      statisticsProviderApi.middleware,
      statisticsUsersApi.middleware,
      statisticsUserDataApi.middleware,
      statisticsExpeditionModeApi.middleware,
      brandApi.middleware,
      statisticsBrandApi.middleware,
      HorairesApi.middleware,
      HolidaysApi.middleware,



    ]),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
