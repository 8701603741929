import PropTypes, { array } from 'prop-types';
import { useState, useEffect } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Chip, Grid, Stack, Typography,Button } from '@mui/material';

// third-party
import ReactApexChart from 'react-apexcharts';
import useProductList from 'features/Product/ProductList/useProductList';
import useCategoryList from 'features/Category/CategoryList/useCategoryList';
import useSubCategoryList from 'features/SubCategory/SubCategoryList/useSubCategoryList';
import { ICategoryData } from 'features/Category/CategoryList/Category.types';
import SelectInput from 'components/common/SelectInput/SelectInput';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { InputStyle } from 'components/common/TextInput/TextInput.style';
import { AutoInputsContainerStyle } from 'features/Product/ProductAdd/Product.style';
import { useTranslation } from 'react-i18next';
import { useGetProductStatisticsMutation } from 'redux/api/statistics/products';
import { useGetProductsByCategoryQuery } from 'redux/api/product/product';
import { useGetAllSubCategoriesQuery } from 'redux/api/category/category';
import { useGetOrderStatisticsMutation } from 'redux/api/statistics/orders';
// chart options
const areaChartOptions = {
  chart: {
    height: 450,
    type: 'area',
    toolbar: {
      show: false
    }
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    curve: 'smooth',
    width: 2
  },
  grid: {
    strokeDashArray: 0
  }
};

// ==============================|| INCOME AREA CHART ||============================== //

const SalesChart = (props:{ slot:any }) => {
  const theme:any = useTheme();
  const [slot, setSlot] = useState('euro');

 const {categoryData}=useCategoryList()

  const { primary, secondary } = theme.palette.text;
  const line = theme.palette.divider;

  const [options, setOptions] = useState<any>(areaChartOptions);
  const [selectedCategory,setSelectedCategory]=useState(0);
  const [selectedSubCategory,setSelectedSubCategory]=useState(0);

  const [selectedProduct,setSelectedProduct]=useState(0)
  const [startDate,setStartDate]=useState<Date>(new Date())
  const [endDate,setEndDate]=useState<Date>(new Date(new Date().setMinutes(new Date().getMinutes() + 1)))
  const [getStats,{data,error,isLoading}]=useGetProductStatisticsMutation()
  const [initialValue,setInitialValues]=useState<number[]>([])
  const [array,setArray]=useState();
  const {
    data: productsData,
    isSuccess,
    refetch: refetchProductItem,
 }=useGetProductsByCategoryQuery(selectedSubCategory?selectedSubCategory:0)
 const [getStatsAllProducts,{data:dataProds}]=useGetOrderStatisticsMutation()
 const [labels,setLabels]=useState<string[]>([])
 const [data1,setData1]=useState([])
 const [data2,setData2]=useState([])

 const {
  data: subCategoryData,
} = useGetAllSubCategoriesQuery({id:
 selectedCategory})

  const onChangeSelectCategory=(e:any)=>{
    setSelectedCategory(e)
  }
  const onChangeSelectProd=(e:any)=>{
    setSelectedProduct(e)
    e!==0?  getStats({start_date:Math.floor(new Date(e).getTime() / 1000),
    end_date:Math.floor(endDate.getTime() / 1000)}):
    getStatsAllProducts({start_date:Math.floor(new Date(e).getTime() / 1000),
    end_date:Math.floor(endDate.getTime() / 1000)})
  
  }
  useEffect(()=>{
    getStatsAllProducts({})
  },[])
  const onChangeSelectSubCategory=(e:any)=>{
    setSelectedSubCategory(e)
  }
  useEffect(()=>{
   selectedProduct!==0 && startDate!==new Date() ?getStats({start_date:Math.floor(new Date(startDate).getTime() / 1000),
    end_date:Math.floor(endDate.getTime() / 1000)}):
  startDate===new Date() && endDate===new Date(new Date().setMinutes(new Date().getMinutes() + 1))&&  getStatsAllProducts({start_date:Math.floor(new Date(startDate).getTime() / 1000),
    end_date:Math.floor(endDate.getTime() / 1000)})
  },[startDate,endDate])
  useEffect(()=>{
    dataProds&&setLabels(Object.keys(dataProds).sort((a: string, b: string): number => {
      // Convert date strings to Date objects for comparison
      const dateA: Date = new Date(a);
      const dateB: Date = new Date(b);
      
      // Compare the dates
      return dateA.getTime() - dateB.getTime();
  }))},[dataProds])
  useEffect(()=>{
    console.log("dataProds",dataProds)
  },[dataProds])
  useEffect(()=>{
    console.log("data",data)
  },[data])
  useEffect(()=>{
    data&& selectedProduct!==0&&data[selectedProduct]&&setLabels(Object.keys(data[selectedProduct]).sort((a: string, b: string): number => {
      // Convert date strings to Date objects for comparison
      const dateA: Date = new Date(a);
      const dateB: Date = new Date(b);
      
      // Compare the dates
      return dateA.getTime() - dateB.getTime();
  }))},[data,selectedProduct])


  const {t}=useTranslation()
  const initArray=(length:number)=>{
    let arr=[];
    for(var i=0;i<length-1;i++){
    arr.push(0)
    }
    console.log("arr",arr)
    return arr;
  }

  useEffect(() => {
    data &&setInitialValues(initArray(Object.values(data)?.length))
    setOptions((prevState:any) => ({
      ...prevState,
      colors: ["#EEE6D8"],
      xaxis: {
        categories:labels,
        labels: {
          style: {
            colors: [
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary
            ]
          }
        },
        axisBorder: {
          show: true,
          color: line
        },
        tickAmount: slot === 'month' ? 11 : 7
      },
      yaxis: {
        labels: {
          style: {
            colors: [secondary]
          }
        }
      },
      grid: {
        borderColor: line
      },
      tooltip: {
        theme: 'light'
      }
    }));
  }, [primary, secondary, line, theme, slot,data,labels,selectedProduct,dataProds,startDate,endDate]);


  const [series, setSeries] = useState<{name:string,data:number[]}[]>([
    {
      name: 'Ventes(CA)',
      data: dataProds&&Object.values(dataProds).map((data:any)=>data.orders_count)
    },
    {
      name: 'Volume global',
      data:dataProds&& Object.values(dataProds).map((data:any)=>data.total_ttc)
    }
  ]);
  interface MyData {
    total_sales: number;
    total_ttc: number;
}

interface MyObject {
    [date: string]: MyData;
}
function sortObjectByDate(object: { [key: string]: any }): { [key: string]: any } {
  const keys = Object.keys(object).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
  const sortedObject: { [key: string]: any } = {};
  
  keys.forEach(key => {
      sortedObject[key] = object[key];
  });
  console.log("sortedObject",sortedObject)
  return sortedObject;
}
  useEffect(() => {
   const serie=slot=="euro"?{
    name: 'Ventes (CA)',
    data: selectedProduct===0 && dataProds?Object.values(dataProds).map((stat:any)=>stat.orders_count):data&&data[selectedProduct]?Object.values(sortObjectByDate(data[selectedProduct])).map((stat:any)=>stat.total_sales):initArray(9)

  }: {
    name:'Volume global',
    data:selectedProduct===0 && dataProds? Object.values(dataProds).map((stat:any)=>stat.total_ttc):data&&data[selectedProduct]?Object.values(sortObjectByDate(data[selectedProduct])).map((stat:any)=>stat.total_ttc):initArray(9)

  }
    setSeries([
      
     serie
  
    ]);
  },[slot,dataProds,selectedProduct,data])
  dayjs.locale("fr")
  return ( <Box
    sx={{
      boxShadow: 3,
      width: 'auto',
      height: 'auto',
      bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
      color: (theme) =>
        theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
      p: 1,
      m: 1,
      borderRadius: 2,
      fontSize: '0.875rem',
      fontWeight: '700',
    }}
  >
     <Grid item xs={12}>
            <Stack direction="row" alignItems="center" spacing={0}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>

<DateTimePicker
  label={t("promotion_codes.start_date_title")}
  value={
    dayjs(startDate)

  }
  format="DD MMMM YYYY"
 // minDateTime={dayjs(new Date(new Date().setDate(new Date().getDate() - 1)))}
  maxDateTime={dayjs(endDate)}
 
  onChange={(start: any) => {setStartDate(new Date(start));
  selectedProduct!==0?  getStats({start_date:Math.floor(new Date(start).getTime() / 1000),
    end_date:Math.floor(endDate.getTime() / 1000)}):
    getStatsAllProducts({start_date:Math.floor(new Date(start).getTime() / 1000),
    end_date:Math.floor(endDate.getTime() / 1000)})
   }}
    
/>

</LocalizationProvider>


<LocalizationProvider dateAdapter={AdapterDayjs}>

<DateTimePicker
  label={t("promotion_codes.end_date_title")}
  value={
    dayjs(endDate)
  }
  format="DD MMMM YYYY"
  //minDateTime={dayjs(startDate).add(1,"minute")}
                 // defaultValue={ dayjs(new Date(new Date().setDate(new Date().getDate() + 1)))}

  onChange={(end: any) => {
    setEndDate(new Date(end));
   selectedProduct!==0? getStats({start_date:Math.floor(startDate.getTime() / 1000),end_date:Math.floor(new Date(end).getTime() / 1000)})
:getStatsAllProducts({start_date:Math.floor(startDate.getTime() / 1000),end_date:Math.floor(new Date(end).getTime() / 1000)})

  }}



/>
</LocalizationProvider>
<Button
                size="small"
                onClick={() => setSlot('euro')}
                color={slot === 'euro' ? 'primary' : 'secondary'}
                variant={slot === 'euro' ? 'outlined' : 'text'}
              >
                Vente(CA)
              </Button>
              <Button
                size="small"
                onClick={() => setSlot('volume')}
                color={slot === 'volume' ? 'primary' : 'secondary'}
                variant={slot === 'volume' ? 'outlined' : 'text'}
              >
                Volume globale
              </Button>
</Stack>
<br/>
<Stack direction="row" alignItems="center" spacing={0}>


            {productsData && selectedCategory!==0 && (
              <div style={{width:"100%"}}>
                <SelectInput
                  
                  none={true}
                  defaultValue={{id:0,name:"All"}}
                  label={"Produits"}
                  error={""}
                  value={selectedProduct}
                  onChange={onChangeSelectProd}
                  data={productsData.data?.map((e: ICategoryData) => ({
                    id: e.id,
                    name: e.title,
                  }))}
                />
                </div>
              )}
               {subCategoryData && (
                
                <SelectInput
                  none={true}
                  label={"Sous Categorie"}
                  error={""}
                  defaultValue={{id:0,name:"Selectionner une sous catégorie"}}
                  value={selectedSubCategory}
                  onChange={onChangeSelectSubCategory}
                  data={subCategoryData.data?.map((e: ICategoryData) => ({
                    id: e.id,
                    name: e.title,
                  }))}
                />
              )}
              {categoryData && (
                
                <SelectInput
                  none={true}
                  label={"Catégorie"}
                  error={""}
                  defaultValue={{id:0,name:"Selectionner une catégorie"}}
                  value={selectedCategory}
                  onChange={onChangeSelectCategory}
                  data={categoryData.data?.map((e: ICategoryData) => ({
                    id: e.id,
                    name: e.title,
                  }))}
                />
              )}
             
             
            </Stack>
     <Typography variant="h6" color="textSecondary">
     Historique des Ventes
      </Typography>
  </Grid>
    
    <ReactApexChart options={options} series={series} type="area" height={450} />
    
    
    </Box>);
};

SalesChart.propTypes = {
  slot: PropTypes.string
};

export default SalesChart;
