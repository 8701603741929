import { FileInputPropsInterface } from "./FileInput.type";
import fileUpload from "../../../assets/img/fileUpload.svg";
import {
  ContainerStyle,
  ErrorStyle,
  FileNameStyle,
  IconContainerStyle,
  ImgIconUploadFileStyle,
  InputFileStyle,
} from "./FileInput.style";

function FileInput({
  value, // file value (file)
  onChange, // handle change
  error, // error msg
  label, // input label
  disabled, // input is disabled
  accept, // array of extension input accept
}: FileInputPropsInterface) {
  return (
    <ContainerStyle>
      <InputFileStyle
        type="file"
        id={label}
        onChange={onChange}
        accept={accept}
        disabled={disabled}
      />
      <label
        htmlFor={label}
        style={{ cursor: disabled ? "default" : "pointer" }}
      >
        {value ? (
          <FileNameStyle>{`${label}: ${value.name}`}</FileNameStyle>
        ) : (
          <FileNameStyle>{label}</FileNameStyle>
        )}
        <IconContainerStyle>
          <ImgIconUploadFileStyle src={fileUpload} alt="upload icon" />
        </IconContainerStyle>
      </label>
      {error && <ErrorStyle>{error}</ErrorStyle>}
    </ContainerStyle>
  );
}
export default FileInput;
