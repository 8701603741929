export const product = {
  page_title: "Liste des produits",
  title: "Titre",
  description: "Description",
  price: "Prix",
  quantity: "Quantité",
  category: "Catégorie",
  pointOfSaleProduct: "Point de vente / Produit",
  successfully_deleted: "Produit est supprimé avec succès",
  successfully_adding: "Produit est ajouté avec succès",
  successfully_editing: "Produit est edité avec succès",
  delete: "Supprimer le Produit",
  add: "Ajouter Produit",
  edit: "Modifier Produit",
  info_add: "Ajouter les informations de produit",
  images_add: "Ajouter les images de produit",
  images_edit: "Modifier les images de produit",
  image_add: "Ajouter une image",
  image_required: "Ajouter une image *",
  color_name: "Nom du couleur",
  color_hex: "Code couleur HEX",
  is_main_image: "Image Principale",
  file: "Image",
  color_hex_required: "Code couleur HEX est obligatoire",
  color_name_required: "Nom du couleur est obligatoire",
  file_required: "Image est obligatoire",
  title_required: "Titre est obligatoire",
  price_required: "Prix est obligatoire",
  description_required: "Description est obligatoire",
  quantity_required: "Quantité est obligatoire",
  category_required: "Catégorie est obligatoire",
  limit_stock_range: "Limite de stock comprise entre 0 et 100",
  limit_stock_required: "Limite de stock obligatoire",
  min_quantity_range: "Quantité minimale comprise entre 0 et 100",
  min_quantity_required: "Quantité minimale obligatoire",
  discount_range: "Solde est compris entre 0 et 100",
  price_HT_required: "Prix_HT est obligatoire",
  price_TTC_required: "Prix_TTC est obligatoire ",
  images_edit_info: "La mise à jour des images supprime les anciennes images!",
  HTprice: "Prix d'achat HT",
  subCategory_product_id: "Sous catégorie",
  tax_id: "Taxe",
  TTC_price: "Prix TTC",
  bar_code: "Code à barre",
  credits: "Solde",
  tag: "Etiquette",
  status: "Status",
  publication_date: "Date de publication*",
  show: "Affichage",
  nutriscore: "Nutriscore",
  limit_stock: "Seuil de stock",
  min_quantity: "Quantité minimale",
  subcategory: "Sous catégorie",
  nutriscore_data: {
    name: "Nom",
    value: "Valeur",
    id_product: "Id_produit",
    add: "Ajouter une valeur nutritionnelle",
    edit: "Modifier une valueur nutritionnelle",
  },
  color: "Couleur",
  color_code: "Code Couleur",
  must_have_subcat: "La catégorie doit avoir une sous catégorie",
  marge: "Marge Distributeur",
  categ_exped: "Catégorie d'expédition",
  category_product_id_required: "Catégorie Produit obligatoire",
  subCategory_product_id_required: "Sous Catégorie produit obligatoire",
  tax_id_required: "Taxe obligatoire",
  bar_code_required: "Code à barre obligatoire",
  tag_required: "Etiquette obligatoire",
  status_required: "Status obligatoire",
  publication_date_required: "Date de publication obligatoire",
  show_required: "Affichage obligatoire",
  nutriscore_required: "Nutriscore obligatoire",
  nutri_values_required: "Valeurs nutritionnelles obligatoires",
  provider_id_required: "Fournisseur obligatoire",
  marge_required: "Marge obligatoire",
  product_format: "Format produit",
  draft_message: "Voulez vous mettre en brouillon",
  draft: "Mettre produits en brouillon",
  publish_message: "Voulez vous publier",
  publish: "Publier produits",
  status_updated: "Statut Produit mise à jour",
  successfully_Dupliqued: "Produit dupliqué avec succès",
  Duplique: "Duplication de produit",
  garentie: "Garentie",
  garentie_required: "la garantie est obligatoire",

};
