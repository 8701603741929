export const order = {
  id:"Id",
  page_title: "Historiques des commandes",
  name: "Nom et Prénom de client",
  product: "Nom du produit",
  products:"Produits",
  quantity: "Quantité achetée",
  promotion_code: "Code de promotion",
  date:"Date",
  mode:"Mode d'expédition",
  total:"Total",
  status:"Status",
  successfully_changed:"Status changé avec succés"

};
