import axios from 'axios';
import { useHistory, useNavigate } from 'react-router-dom'; // Or 'next/router' if using Next.js

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const apiClient = axios.create({
    baseURL: API_BASE_URL,
});

// Function to check if the token is expired
const isTokenExpired = (token) => {
    if (!token) return true; // If no token, treat it as expired
    const payload = JSON.parse(atob(token.split('.')[1])); // Decode JWT payload
    const expiry = payload.exp * 1000; // Convert expiry time to milliseconds
    return Date.now() > expiry; // Check if the current time is past the expiry time
};

// Request Interceptor: Automatically add token and check for expiration
apiClient.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');

        if (isTokenExpired(token)) {
            // If token is expired, remove it and redirect to login
            localStorage.removeItem('token');
            const navigate = useNavigate(); 
            navigate('/login');
            return Promise.reject(new Error('Token expired, redirecting to login.'));
        }

        // Attach token to the headers
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

// Response Interceptor: Handle 401 Unauthorized errors
apiClient.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');
            const navigate = useNavigate(); 
            navigate('/login');
        }
        return Promise.reject(error);
    }
);

export default apiClient;
