import PropTypes, { array } from 'prop-types';
import { useState, useEffect } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Chip, Grid, Stack, Typography,Button } from '@mui/material';

// third-party
import ReactApexChart from 'react-apexcharts';
import { useGetOrderStatisticsMutation } from 'redux/api/statistics/orders';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { InputStyle } from 'components/common/TextInput/TextInput.style';
import { AutoInputsContainerStyle } from 'features/Product/ProductAdd/Product.style';
import { useTranslation } from 'react-i18next';

// chart options
const areaChartOptions = {
  chart: {
    height: 450,
    type: 'area',
    toolbar: {
      show: false
    }
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    curve: 'smooth',
    width: 2
  },
  grid: {
    strokeDashArray: 0
  }
};

// ==============================|| INCOME AREA CHART ||============================== //

const OrderHistory = (props:{ slot:any }) => {
  const theme:any = useTheme();
  const {t}=useTranslation()
  const [slot, setSlot] = useState('euro');
  const [initialValue,setInitialValues]=useState<number[]>([])
  const [startDate,setStartData]=useState<Date>(new Date())
  const [endDate,setEndDate]=useState<Date>(new Date(new Date().setMinutes(new Date().getMinutes() + 1)))
  const [getStats,{data,error,isLoading}]=useGetOrderStatisticsMutation()
  useEffect(()=>{
    getStats({})
  },[])
const initArray=(length:number)=>{
  let arr=[];
  for(var i=0;i<length-1;i++){
  arr.push(0)
  }
  console.log("arr",arr)
  return arr;
}
const [orderStats,setOrderStats]=useState(data)

  const { primary, secondary } = theme.palette.text;
  const line = theme.palette.divider;

  const [options, setOptions] = useState<any>(areaChartOptions);

  useEffect(() => {
    data &&setInitialValues(initArray(Object.values(data)?.length))
    setOrderStats(data)
    setOptions((prevState:any) => ({
      ...prevState,
      colors: ['#e5e7e6'],
      xaxis: {
        categories:data ?Object.keys(data):["26-03-2024","27-03-2024"],
        labels: {
          style: {
            colors: [
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary
            ]
          }
        },
        axisBorder: {
          show: true,
          color: line
        },
        tickAmount: slot === 'month' ? 11 : 7
      },
      yaxis: {
        labels: {
          style: {
            colors: [secondary]
          }
        }
      },
      grid: {
        borderColor: line
      },
      tooltip: {
        theme: 'light'
      }
    }));
  }, [primary, secondary, line, theme, slot,data]);

  const [series, setSeries] = useState<{name:string,data:number[]}[]>([
    {
      name: 'Volume ',
      data:initialValue
    },
 
  ]);
  const serie=slot=="volume"? {
    name: 'Volume',
    data: orderStats&&Object.values(orderStats).map((stat:any)=>stat.orders_count)
  }:
  {
    name: 'Euro',
    data: orderStats&&Object.values(orderStats).map((stat:any)=>stat.total_ttc)
  }
  useEffect(() => {
    setSeries([
     serie
    ]);
  }, [slot,orderStats]);
   dayjs.locale("fr")
  return ( <Box
    sx={{
      boxShadow: 3,
      width: 'auto',
      height: 'auto',
      bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
      color: (theme) =>
        theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
      p: 1,
      m: 1,
      borderRadius: 2,
      fontSize: '0.875rem',
      fontWeight: '700',
    }}
  >
     <Grid item xs={12}>
     <Grid item style={{float: 'right'}}>
            <Stack direction="row" alignItems="center" spacing={0}>
              <Button
                size="small"
                onClick={() => setSlot('euro')}
                color={slot === 'euro' ? 'primary' : 'secondary'}
                variant={slot === 'euro' ? 'outlined' : 'text'}
              >
                Euro
              </Button>
              <Button
                size="small"
                onClick={() => setSlot('volume')}
                color={slot === 'volume' ? 'primary' : 'secondary'}
                variant={slot === 'volume' ? 'outlined' : 'text'}
              >
                Volume
              </Button>


  <LocalizationProvider dateAdapter={AdapterDayjs}>

    <DateTimePicker
      label={t("promotion_codes.start_date_title")}
      value={
        dayjs(startDate)

      }
     // minDateTime={dayjs(new Date(new Date().setDate(new Date().getDate() - 1)))}
      maxDateTime={dayjs(endDate)}
      format="DD MMMM YYYY"
      onChange={(start: any) => {setStartData(new Date(start));
        getStats({start_date:Math.floor(new Date(start).getTime() / 1000),
        end_date:Math.floor(endDate.getTime() / 1000)}) }}
    />

  </LocalizationProvider>


  <LocalizationProvider dateAdapter={AdapterDayjs}>

    <DateTimePicker
      label={t("promotion_codes.end_date_title")}
      value={
        dayjs(endDate)
      }
      format="DD MMMM YYYY"
      minDateTime={dayjs(startDate).add(1,"minute")}
                     // defaultValue={ dayjs(new Date(new Date().setDate(new Date().getDate() + 1)))}

      onChange={(end: any) => {
        setEndDate(new Date(end));
        getStats({start_date:Math.floor(startDate.getTime() / 1000),end_date:Math.floor(new Date(end).getTime() / 1000)})

    
      }}



    />
  </LocalizationProvider>


            </Stack>
          </Grid>
     <Typography variant="h6" color="textSecondary">
     Historique des commandes
      </Typography>
  </Grid>
    
    <ReactApexChart options={options} series={series} type="area" height={450} />
    
    
    </Box>);
};

OrderHistory.propTypes = {
  slot: PropTypes.string
};

export default OrderHistory;
