import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CONFIG } from "config/constant";
import { ENDPOINTS } from "config/constant/endpoints.config";
import { ISuppliersAddingData } from "features/Supplier/SupplierAdd/AddSupplier.types";
import { Paginator } from "types/interfaces";
import { injectParamsPaginator } from "utils/helpers/api-url";
import { baseQueryWithReauth } from "../rtkBaseQuery";
import { ITaxAddingData } from "features/Taxe/TaxAdd/AddTax.types";
import { ITaxEditingData } from "features/Taxe/TaxEdit/EditTax.types";

export const taxesApi = createApi({
  reducerPath: "TaxesApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Taxes"],
  endpoints: (builder) => ({
    getTaxById: builder.query({
      query: (id: string) => ENDPOINTS.TAXES +"/"+ id,
    }),
    getAllTaxes: builder.query({
      query: (params: { paginator?: Paginator }) => {
        return injectParamsPaginator(
          ENDPOINTS.TAXES,
          params.paginator
        );
      },
      providesTags: ["Taxes"],
    }),
    deleteTax: builder.mutation({
      query: (id: number) => ({
        url: `${ENDPOINTS.TAXES}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Taxes"],
    }),
    addTax: builder.mutation({
      query: ({
        name,
        percentage,
        country,
      }: ITaxAddingData) => ({
        url: `${ENDPOINTS.TAXES}`,
        method: "POST",
        params: { name,percentage,country},
      }),
      invalidatesTags: ["Taxes"],
    }),
    deleteMultipleTaxes: builder.mutation({
      query: (params: { tax_ids: number[], select_all: boolean ,search:string}) => ({
        url: `${ENDPOINTS.TAXES}/delete-multiple`,
        method: "POST",
        body: {
          tax_ids: params.tax_ids,
          select_all: params.select_all,
          search:params.search
        },
      }),
      invalidatesTags: ["Taxes"],

    }),
    updateTax: builder.mutation({
      query: ({
        id,
        name,
        percentage,
        country
      }: ITaxEditingData) => ({
        url: `${ENDPOINTS.TAXES}/${id}`,
        method: "POST",
        params: { 
            name,
            percentage,
            country
        },
      }),
      invalidatesTags: ["Taxes"],
    }),
  }),
});

export const {
  useGetAllTaxesQuery,
  useGetTaxByIdQuery,
  useDeleteTaxMutation,
  useAddTaxMutation,
  useUpdateTaxMutation,
  useDeleteMultipleTaxesMutation
} = taxesApi;
