import { MenuItem, styled,InputLabel } from "@mui/material";
import Select from "@mui/material/Select";

export const FragmentStyle = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexFlow: "column",
}));

export const ContainerStyle = styled(Select)(({ theme }) => ({
  backgroundColor: 'white',
  height: "45px !important ",
  color: "white",
  width: "100%",
  marginTop: "6px !important",
  fontSize: 14,
  margin: 0,
  borderColor: theme.palette.primary.main,
  borderRadius: 5,
  appearance: "none",
  textOverflow: "ellipsis",
  "&:-webkit-autofill + label": {
    top: 0,
    transform: "translateY(-70%) scale(1)",
    color: theme.palette.primary.main,
  },
  "&:focus": {
    boxShadow: "0px 0px 6px 3px rgba(18, 63, 140, 0.3) !important",
    "&+label": {
      color: theme.palette.primary.main,
      top: 0,
      transform: "translateY(-70%) scale(1)",
    },
  },
  "&:not(:placeholder-shown)": {
    borderColor: theme.palette.primary.main,
    color: "black",
    fontSize: 14,

    "&+label": {
      top: 0,
      transform: "translateY(-70%) scale(1)",
      color: theme.palette.primary.main,
    },
  },
  
  '&.Mui-focused fieldset': {
    borderColor: '#B269AE!important',
    boxShadow: "0px 0px 6px 3px rgba(18, 63, 140, 0.3) !important",
  },
  '& fieldset': {
    borderColor: "#B269AE!important",
    height: "50px!important",
   
  },
}));
export const LabelStyle = styled(InputLabel)(({ theme }) => ({
  position: "absolute",
  fontZize: "1rem",
  left: 0,
  height: 18.5,
  top: 0,
  transform: "translateY(-70%)!important",
  backgroundColor: "transparent",
  backgroundSize: "100% 50%",
  backgroundPosition: "top left",
  color: theme.palette.primary.main,
  paddingRight: 1,
  paddingLeft: 1,
  margin: "0 18px",
  transition: "0.1s ease-out",
  transformOrigin: "left top",
  pointerEvents: "none",
  fontSize: 15,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxWidth: "calc(100% - 20px)",
  textShadow:
    "1px 0 1px #fff, -1px 0 1px #fff, 0 1px 1px #fff, 0 -1px 1px #fff",
}));
export const TitleStyle = styled("p")(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 16,
  margin: 0,
  padding: 0,
}));
export const OptionStyle = styled(MenuItem)(({ theme }) => ({
  padding: 5,
  "-webkit-appearance": "none",
  "-moz-appearance": "none",
  appearance: "none",
}));

export const ErrorStyle = styled("p")(({ theme }) => ({
  color: "red",
  fontSize: 14,
  marginTop: 2,
  marginBottom: 0,
}));
