import { Grid, ImageListItem, styled } from "@mui/material";

export const AutoInputsContainerStyle = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginTop: 10,
  marginBottom: 10,
}));

export const InputStyle = styled("div")(({ theme }) => ({
  width: "100%",
  marginTop: 20,
  marginBottom: 20,
}));
export const ImageContainerStyle = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

export const ImageStyle = styled("img")(({ theme }) => ({
  width: 100,
  height: 100,
  objectFit: "contain",
}));

export const DeleteButtonsStyle = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
}));

export const ContainerButtonStyle = styled("div")(({ theme }) => ({
  margin: 10,
}));

export const ImageGalleryStyle = styled("img")(({ theme }) => ({
  width: 200,
  height: 200,
  objectFit: "contain",
}));

export const ImageListItemStyle = styled(ImageListItem)(({ theme }) => ({
  alignItems: "center",
}));
