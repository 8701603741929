import { ButtonPropsInterface } from "./Button.type";
import { ButtonStyle, CircularProgressStyle } from "./Button.style";
import { useTranslation } from "react-i18next";

function Button({
  type,
  loader = false,
  title,
  onClick,
  disabled = false,
  icon,
  style,
}: ButtonPropsInterface) {
  // translation hooks
  const { t } = useTranslation();
  return (
    <ButtonStyle
      disabled={disabled}
      style={style}
      type={type}
      onClick={onClick}
    >
      {!loader ? t(title) : <CircularProgressStyle size={20} />}
      {icon && icon}
    </ButtonStyle>
  );
}
export default Button;
