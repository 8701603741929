import React from 'react';
import { ApplyCEButtonStyle, ApplyCEIconStyle, ApplyCETextStyle } from './ApplyCEButton.style';
import { ApplyCEButtonProps } from './ApplyCEButton.types';

const ApplyCEButton: React.FC<ApplyCEButtonProps> = ({ onClick }) => {
  return (
    <ApplyCEButtonStyle onClick={onClick}>
      <ApplyCEIconStyle />
      <ApplyCETextStyle>Appliquer C.E</ApplyCETextStyle>
    </ApplyCEButtonStyle>
  );
};

export default ApplyCEButton;
