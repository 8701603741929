import Table from "components/common/Table/Table";
import { RouteIdEnum } from "config/enums";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import { useTranslation } from "react-i18next";
import { IDeliveryData } from "./DeliveryList.types";
import { Grid, IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import AddButton from "components/common/AddButton/AddButton";
import SearchInput from "components/common/SearchInput/SearchInput";
import {
  ActionContainerStyle,
  ButtonContainerStyle,
} from "./DeliveryList.style";
import { Add, Delete, Edit } from "@mui/icons-material";
//import useCategoryList from "./useCategoryList";
import { CONFIG } from "config/config";
import { useCallback, useState } from "react";
//import DeleteCategory from "../CategoryDelete/DeleteCategory";
import AddDeleivery from "../DeleiveryAdd/AddDelievery";
//import EditCategory from "../CategoryEdit/EditCategory";
import { Link, Navigate, useNavigate } from "react-router-dom";
import useCategoryList from "features/Category/CategoryList/useCategoryList";
//import AddSubCategory from "features/SubCategory/SubCategoryAdd/AddSubCategory";


function Delivery() {
  const { t } = useTranslation();
  const navigate=useNavigate();
 /*const {
    onSearchChange,
    onPageChange,
    onPerPageChange,
    onOrderChange,
    categoryData,
    isLoading,
    search,
    orderBy,
    orderDir,
    page,
    perPage,
  } = useCategoryList();*/
  const categoryData=[
    {
        name:"name",
        description:"description",
        region:"tunis",
        horaire:"8h",

    },
    {}
  ]

  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [deletedCategory, setDeletedCategory] = useState<{
    id: number;
    title: string;
  }>();

 /* const openDeleteDialogStatus = useCallback(
    (id: number, name: string) => () => {
      setOpenDeleteDialog(true);
      setDeletedCategory({ id: id, name: name });
    },
    [setOpenDeleteDialog, setDeletedCategory]
  );*/

  const [openAddingDialog, setOpenAddingDialog] = useState<boolean>(false);
  const openAddingDialogStatus = useCallback(() => {
    setOpenAddingDialog(true);
  }, []);

  const [openEditingDialog, setOpenEditingDialog] = useState<boolean>(false);
  const [editedCategory, setEditedCategory] = useState<{
    id: number;
    title: string;
    image: string;
  }>();

  /*const openEditingDialogStatus = useCallback(
    (id: number, name: string) => () => {
      setEditedCategory({ id, name });
      setOpenEditingDialog(true);
    },
    []
  );*/

  return (
    <DashboardLayout
      title={t("category.page_title")}
      selectedPath={RouteIdEnum.Delivery}
    >
      <Grid container mb={2} justifyContent="space-between" spacing={1}>
        <Grid item xs={12} sm={4}>
          <SearchInput
            onChange={()=>{}}
            value={""}
            placeholder={t("common.search")}
          />
        </Grid>
        <ButtonContainerStyle item xs={12} sm={4}>
          <AddButton
            onAddClick={openAddingDialogStatus}
            title={t("common.add")}
          />
        </ButtonContainerStyle>
      </Grid>

      <Table
        loader={false}
        headerData={[
          {
            key: "name",
            title: t("delivery.name"),
          },
          {
            key: "description",
            title: t("delivery.description"),
          },
          {
            key: "region",
            title: t("delivery.region"),
          },
          {
            key: "horaire",
            title: t("delivery.horaire"),
          },
          {
            key: "created_at",
            title: t("category.created_at"),
          },
          { key: "action", title: t("common.actions") },
        ]}
        bodyData={categoryData.map((e: any) => {
          return {
            name: (<p>{e.name}</p>),
            description: (
              <p>
               {e.description}
              </p>
            ),
            region: (
                <p>
                 {e.region}
                </p>
              ),
              horaire: (
                <p>
                 {e.horaire}
                </p>
              ),
            action: (
              <ActionContainerStyle>
                {/*
                  <Tooltip title={t("common.edit")}>
                    <IconButton
                      color="primary"
                      component="span"
                      onClick={openEditingDialogStatus(e.id, e.name, e.image)}
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
            */ }
                {/*
                  <Tooltip title={t("common.delete")}>
                    <IconButton
                      color="primary"
                      component="span"
                      onClick={openDeleteDialogStatus(e.id, e.name)}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
        */ }
               
              </ActionContainerStyle>
            ),
          };
        })}

      />
      {/*deletedCategory && (
        <DeleteCategory
          id={deletedCategory.id}
          title={deletedCategory.title}
          openDeleteDialog={openDeleteDialog}
          setOpenDeleteDialog={setOpenDeleteDialog}
        ></DeleteCategory>
      )*/}
     {<AddDeleivery
        openAddingDialog={openAddingDialog}
        setOpenAddingDialog={setOpenAddingDialog}
      ></AddDeleivery>

     }
      {/*editedCategory && (
        <EditCategory
          iniData={{
            id: editedCategory.id,
            title: editedCategory.title,
            image: editedCategory.image,
          }}
          openEditingDialog={openEditingDialog}
          setOpenEditingDialog={setOpenEditingDialog}
        ></EditCategory>
        )*/}
    </DashboardLayout>
  );
}
export default Delivery;
