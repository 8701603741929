import Table from "components/common/Table/Table";
import { RouteIdEnum } from "config/enums";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import { useTranslation } from "react-i18next";
import { IBrandData } from "./Brand.types";
import { Checkbox, Grid, IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import AddButton from "components/common/AddButton/AddButton";
import SearchInput from "components/common/SearchInput/SearchInput";
import {
  ActionContainerStyle,
  ButtonContainerStyle,
  CategoryImageStyle,
} from "./Brand.style";
import { Add, Delete, Edit } from "@mui/icons-material";
import useBrandList from "./useBrandList";
import { CONFIG } from "config/config";
import { useCallback, useState } from "react";
import DeleteCategory from "../BrandDelete/DeleteBrand";
import AddCategory from "../BrandAdd/AddBrand";
import EditCategory from "../BrandEdit/EditBrand";
import { Link } from "react-router-dom";
import DeleteButton from "components/common/DeleteButton/DeleteButton";
import AddSubCategory from "features/SubCategory/SubCategoryAdd/AddSubCategory";
import { useDeleteMultipleCategoriesMutation } from "redux/api/category/category";
import DeleteCategoryMultiple from "../BrandDeleteMultiple/DeleteBrandMultiple";
import { useSnackBar } from "hooks/useSnackBar";
import DeleteBrand from "../BrandDelete/DeleteBrand";
import AddBrand from "../BrandAdd/AddBrand";
import DeleteBrandMultiple from "../BrandDeleteMultiple/DeleteBrandMultiple";
import EditBrand from "../BrandEdit/EditBrand";


function Brand() {
  const { t } = useTranslation();
  const {
    onSearchChange,
    onPageChange,
    onPerPageChange,
    onOrderChange,
    brandData,
    isLoading,
    search,
    orderBy,
    orderDir,
    page,
    perPage,
  } = useBrandList();
  const [brand,setBrand]=useState<IBrandData>({
    id:0,
    name:"",
    image:"",
    created_at:"",
    updated_at:""
  })
  const [openDeleteMultipleModal,setOpenDeleteMultipleModal]=useState(false)

  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [deletedCategory, setDeletedCategory] = useState<{
    id: number;
    name: string;
  }>();
  const openDeleteMultipleDialogStatus =() => {
    setOpenDeleteMultipleModal(true);
  
};
  const [isAddSubCat,setIsAddSubCat]=useState(false)

  const openDeleteDialogStatus = useCallback(
    (id: number, name: string) => () => {
      setOpenDeleteDialog(true);
      setDeletedCategory({ id: id, name: name });
    },
    [setOpenDeleteDialog, setDeletedCategory]
  );
  const { displaySuccessSnackBar, displayErrorApiSnackBar } = useSnackBar();
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const handleSelectAll = () => {
    setSelectedItems([]);
    setSelectAll(!selectAll);

  };
  const handleCheckboxChange = (id: number) => {
    const isSelected = selectedItems.includes(id);
    if (isSelected) {
      setSelectedItems(selectedItems.filter((item) => item !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };
  const [deleteCode,{ data, error,reset,isLoading:loading }] =
    useDeleteMultipleCategoriesMutation();


const deleteMultiple=(event:any)=>{
  event.preventDefault()
  deleteCode({
    categories_ids:selectedItems,
    select_all:selectAll,
    search:search
})
if(!error)
setOpenDeleteMultipleModal(false)

}
 
  const [openSubCatAddingDialog, setSubCatOpenAddingDialog] = useState<boolean>(false);

  const [openAddingDialog, setOpenAddingDialog] = useState<boolean>(false);
  const openAddingDialogStatus = useCallback(() => {
    setIsAddSubCat(false)

    setOpenAddingDialog(true);
  }, []);
  const openAddingSubCatDialogStatus = useCallback((brand:IBrandData) => {
    setIsAddSubCat(true)

    setSubCatOpenAddingDialog(true);
    setBrand({...brand})
  }, []);
  const [openEditingDialog, setOpenEditingDialog] = useState<boolean>(false);
  const [editedBrand, setEditedBrand] = useState<{
    id: number;
    name: string;
    image: string;
  }>();
  

if (error) {
  displayErrorApiSnackBar(error);
  reset();
}
if (data) {
  displaySuccessSnackBar(t("brand.successfully_deleted"));
  reset();

}
  const openEditingDialogStatus = useCallback(
    (id: number, name: string, image: string) => () => {
      setEditedBrand({ id, name, image });
      setOpenEditingDialog(true);
    },
    []
  );

  return (
    <DashboardLayout
      title={t("brand.page_title")}
      selectedPath={RouteIdEnum.Brand}
    >
      <Grid container mb={2} justifyContent="space-between" spacing={1}>
        <Grid item xs={12} sm={4}>
          <SearchInput
            onChange={onSearchChange}
            value={search}
            placeholder={t("common.search")}
          />
        </Grid>
        <ButtonContainerStyle item xs={12} sm={4}>
        {((selectAll || selectedItems.length != 0) )&&<DeleteButton onAddClick={openDeleteMultipleDialogStatus} title={t("common.delete")} />
       }
         &nbsp;
                   <AddButton
            onAddClick={openAddingDialogStatus}
            title={t("common.add")}
          />
        </ButtonContainerStyle>
      </Grid>

      <Table
        loader={isLoading}
        headerData={[
          {
            key: "checkbox",
            title: <Checkbox  color="secondary" checked={selectAll} onChange={handleSelectAll} />,
          },
          {
            key: "name",
            title: t("brand.name"),
            onClick: onOrderChange("name"),
            asc: orderBy === "name" ? orderDir === "asc" : undefined,
          },
          {
            key: "image",
            title: t("brand.image"),
          },
          {
            key: "created_at",
            title: t("brand.created_at"),
          },
          { key: "action", title: t("common.actions") },
        ]}
        bodyData={brandData?.data.map((e: IBrandData) => {
          return {
            checkbox: (
              <Checkbox  checked={(!selectAll && selectedItems.includes(e.id))||(selectAll && !selectedItems.includes(e.id))}
              onChange={() => handleCheckboxChange(e.id)}
              />

            ),
            name: <p>{e.name}</p>,
            image: (
              <p>
                {
                  <CategoryImageStyle
                    src={CONFIG.BASE_URL + e.image}
                  ></CategoryImageStyle>
                }
              </p>
            ),
            created_at: <div>{moment(e.created_at).format("DD/MM/YYYY")}</div>,
            action: (
              <ActionContainerStyle>
                {
                  <Tooltip title={t("common.edit")}>
                    <IconButton
                      color="primary"
                      component="span"
                      onClick={openEditingDialogStatus(e.id, e.name, e.image)}
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
                }
                {
                  <Tooltip title={t("common.delete")}>
                    <IconButton
                      color="primary"
                      component="span"
                      onClick={openDeleteDialogStatus(e.id, e.name)}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                }
               
              </ActionContainerStyle>
            ),
          };
        })}
        paginationData={{
          page: page,
          lastPage: brandData?.last_page ?? 1,
          perPage: perPage,
          onPageChange: (event, value) => onPageChange(value),
          onPerPageChange: (e: any) => onPerPageChange(e),
        }}
      />
      {deletedCategory && (
        <DeleteBrand
          id={deletedCategory.id}
          name={deletedCategory.name}
          openDeleteDialog={openDeleteDialog}
          setOpenDeleteDialog={setOpenDeleteDialog}
        ></DeleteBrand>
      )}
     {
      <AddBrand
        openAddingDialog={openAddingDialog}
        setOpenAddingDialog={setOpenAddingDialog}
      ></AddBrand>
     }
      {selectedItems && (
        <DeleteBrandMultiple
        isLoading={loading}
          catesgs={selectedItems}
          openDeleteMultipleDialog={openDeleteMultipleModal}
          setOpenDeleteMultipleDialog={setOpenDeleteMultipleModal}
          onSubmit={deleteMultiple}
        ></DeleteBrandMultiple>
      )}
      {editedBrand && (
        <EditBrand
          iniData={{
            id: editedBrand.id,
            name: editedBrand.name,
            image: editedBrand.image,
          }}
          openEditingDialog={openEditingDialog}
          setOpenEditingDialog={setOpenEditingDialog}
        ></EditBrand>
      )}
    </DashboardLayout>
  );
}
export default Brand;
