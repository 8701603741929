import { createApi } from "@reduxjs/toolkit/query/react";
import { ENDPOINTS } from "config/constant/endpoints.config";
import { Paginator } from "types/interfaces";
import { injectParamsPaginator } from "utils/helpers/api-url";
import { baseQueryWithReauth } from "../rtkBaseQuery";

export const HorairesApi = createApi({
  reducerPath: "HorairesApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Horaires"],
  endpoints: (builder) => ({
    getHoraires: builder.query({
      query: () => {
        return injectParamsPaginator(ENDPOINTS.HORAIRES );
      },
      providesTags: ["Horaires"],
    }),
    createOrUpdateHoraires: builder.mutation<any, FormData>({
      query(data: any) {
        return {
          url: `${ENDPOINTS.HORAIRES}`,
          method: "POST",
          credentials: "include",
          body: data,
        };
      },
      invalidatesTags: ["Horaires"],
    }),
  }),
});

export const {
  useGetHorairesQuery,
  useCreateOrUpdateHorairesMutation,
} = HorairesApi;
