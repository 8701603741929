import { createApi } from "@reduxjs/toolkit/query/react";
import { ENDPOINTS } from "config/constant/endpoints.config";
import { Paginator } from "types/interfaces";
import { injectParamsPaginator } from "utils/helpers/api-url";
import { baseQueryWithReauth } from "../rtkBaseQuery";

export const statisticsUserDataApi = createApi({
  reducerPath: "statisticsUserDataApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["StatisticUserData"],
  endpoints: (builder) => ({
    getUserDataStatistics: builder.mutation({
      query: ({
       formData
      }) => ({
        url: `/stats/user`,
        method: "POST",
        body:formData
      }),
      invalidatesTags: ["StatisticUserData"],
    }),
  })
  
})

export const { useGetUserDataStatisticsMutation} =statisticsUserDataApi;
