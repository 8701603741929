export namespace RouterPaths {
  export namespace RootPaths {
    export const anyPath = "*";
    export const rootPath = "/";
  }
  export namespace PokemonPaths {
    // export const pokemonPage = joinPaths([RouteIdEnum.Root, RouteIdEnum.Pokemon]);
    // export const pokemonPageDetails = joinPaths([
    //   RouteIdEnum.Root,
    //   RouteIdEnum.Pokemon,
    //   RouteIdEnum.id,
    // ]);
  }
}
