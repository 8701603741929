import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import Chart from 'react-apexcharts';
interface IPieChartProps{
    series: number[],
    options:any,
    title:string,
    type?:"donut"|"pie"
}

// Define your component
const UserCharts = (chartProps:IPieChartProps) => {
  // Data for the pie chart
 
  <h6>Statistiques genre</h6>
  return (
    <Box
    sx={{
      boxShadow: 3,
      width: 'auto',
      height: 'auto',
      bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
      color: (theme) =>
        theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
      p: 1,
      m: 1,
      borderRadius: 2,
      fontSize: '0.875rem',
      fontWeight: '700',
    }}
  >
        <Grid item xs={12}>
     <Grid item style={{float: 'right'}}>
     </Grid>


     <Typography variant="h6" style={{textAlign:"center"}} color="textSecondary">
     {chartProps.title}
      </Typography>
      </Grid>
      <Chart
        options={chartProps.options}
        series={chartProps.series}
        type={chartProps.type}
        width={380}
      />
  </Box>
  );
}

// Export your component
export default UserCharts;