import { RouteIdEnum } from "config/enums";
import { Navigate } from "react-router-dom";
import { isLoggedIn } from "utils/services/storage.service";
interface PrivateRouteInterface {
  element: JSX.Element;
}
const PrivateRoute = ({ element }: PrivateRouteInterface) => {
  return isLoggedIn() ? (
    element
  ) : (
    <Navigate to={"/" + RouteIdEnum.Login} replace />
  );
};

export default PrivateRoute;
 