import { useTranslation } from "react-i18next";
import { useCallback, useState } from "react";
import Dialog from "components/common/Dialog/Dialog";
import Button from "components/common/Button/Button";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import {
  ContainerButtonStyle,
  DeleteButtonsStyle,
} from "./DeletePromotionCodeMultiple.style";
import { IPromotionCodesDeleteMultiple } from "./DeletePromotionCodeMultiple.types";

import { useDeletePromotionCodeMutation } from "redux/api/promotionCodes/promotionCodes";
import { useSnackBar } from "hooks/useSnackBar";

function DeletePromotionCodeMultiple({
  codes,
  openDeleteMultipleDialog,
  isLoading,
  setOpenDeleteMultipleDialog,
  onSubmit
}: IPromotionCodesDeleteMultiple) {
  console.log("codes",codes)
  const { t } = useTranslation();

  const { displaySuccessSnackBar, displayErrorApiSnackBar } = useSnackBar();



  const closeDeleteAlertDialog = useCallback(() => {
    setOpenDeleteMultipleDialog(false);

  }, [setOpenDeleteMultipleDialog]);

  
  return (
    <Dialog
      open={openDeleteMultipleDialog}
      handleClose={closeDeleteAlertDialog}
      title={t("promotion_codes.delete")}
      size={"xs"}
    >
      <div style={{textAlign: "center"}}> <ErrorOutlineIcon sx={{fontSize:"90PX",color:"#f54040"}}  ></ErrorOutlineIcon></div>

      <form onSubmit={onSubmit}>
        <p>
          {t("common.delete_message")}
          <span style={{ fontWeight: "bold", marginLeft: 5 }}>Les codes promotions selectionnés</span>!
        </p>
        <DeleteButtonsStyle>
          <ContainerButtonStyle>
            <Button
              disabled={isLoading}
              title={t("common.cancel_button")}
              onClick={closeDeleteAlertDialog}
            />
          </ContainerButtonStyle>
          <ContainerButtonStyle>
            <Button
              disabled={isLoading}
              type="submit"
              loader={isLoading}
              title={t("common.confirm_button")}
            />
          </ContainerButtonStyle>
        </DeleteButtonsStyle>
      </form>
    </Dialog>
  );
}
export default DeletePromotionCodeMultiple;
