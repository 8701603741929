import { createApi } from "@reduxjs/toolkit/query/react";
import { ENDPOINTS } from "config/constant/endpoints.config";
import { Paginator } from "types/interfaces";
import { injectParamsPaginator } from "utils/helpers/api-url";
import { baseQueryWithReauth } from "../rtkBaseQuery";

export const statisticsBrandApi = createApi({
  reducerPath: "statisticsBrandApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["StatisticBrand"],
  endpoints: (builder) => ({
    getBrandStatistics: builder.mutation({
      query: ({
        start_date,
        end_date
      }: {start_date?:number,end_date?:number}) => ({
        url: `/stats/brands`,
        method: "POST",
        body:start_date && end_date? {  start_date,
            end_date,
           }:{},
      }),
      invalidatesTags: ["StatisticBrand"],
    }),
    getAllBrandStatistics: builder.mutation({
        query: ({
          start_date,
          end_date
        }: {start_date?:number,end_date?:number}) => ({
          url: `/stats/allbrands`,
          method: "POST",
          body:start_date && end_date? {  start_date,
              end_date,
             }:{},
        }),
        invalidatesTags: ["StatisticBrand"],
      }),
  })
})

export const {useGetAllBrandStatisticsMutation ,useGetBrandStatisticsMutation} =statisticsBrandApi;
