const FilterButtons = ({ setSelectedFilter, selectedFilter, selectedOption }) => {

    const statusOptions = selectedOption == 0 ? [
        { value: 0, label: "En cours de traitement" },
        { value: 1, label: "En cours de livraison" },
        { value: 2, label: "Livré" },
        { value: 3, label: "Annulé" },
    ] : [
        { value: 6, label: "Nouvelle commande." },
        { value: 7, label: "Payée et en attente de retrait" },
        { value: 8, label: "Retiré" },

    ]

    return (
        <div className="inline-flex rounded-md ">
            <button
                onClick={() => setSelectedFilter('')}
                className="px-4 py-2 text-sm font-medium  hover:scale-105 duration-200 text-[#b269ae]"
            >
                Réinstaller
            </button>

            {statusOptions.map((option, index) => (
                <button
                    key={option.value}
                    onClick={() => setSelectedFilter(option)}
                    className={`px-4 shadow-md py-2 text-sm font-medium border focus:z-10 focus:ring-2 focus:ring-[#b269ae] 
                        ${selectedFilter.label === option.label
                            ? 'bg-[#b269ae] text-white'
                            : 'text-[#b269ae] border-[#b269ae] hover:bg-[#b269ae] hover:text-white'}
                        ${index === 0 ? 'rounded-s-lg' : ''} 
                        ${index === statusOptions.length - 1 ? 'rounded-e-lg' : ''}`}
                >
                    {option.label}
                </button>
            ))}
        </div>
    );
};

export default FilterButtons;
