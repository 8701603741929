import React, { useState,useEffect } from 'react';
import { useCreateOrUpdateHorairesMutation,useGetHorairesQuery  } from "redux/api/Horaires/Horaires";
import { styled } from '@mui/material/styles';
import { Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, IconButton, TextField, Switch, FormControlLabel } from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import { useSnackBar } from "hooks/useSnackBar";

interface Period {
  start: string;
  end: string;
}

interface DayData {
  day: string;
  open: boolean;
  periods: Period[];
}

const initialData: DayData[] = [
  { day: 'Lundi', open: false, periods: [] },
  { day: 'Mardi', open: false, periods: [] },
  { day: 'Mercredi', open: false, periods: [] },
  { day: 'Jeudi', open: false, periods: [] },
  { day: 'Vendredi', open: false, periods: [] },
  { day: 'Samedi', open: false, periods: [] },
  { day: 'Dimanche', open: false, periods: [] },
];

const CustomSwitch = styled(Switch)(({ theme }) => ({
    width: 100, // Augmentez la largeur du Switch
    height: 38,
    padding: 8,
    scale: "1 !important",
    '& .MuiSwitch-switchBase': {
      margin: 1,
      padding: 0,
      transform: 'translateX(6px)',
      '&.Mui-checked': {
        color: '#fff',
        transform: 'translateX(54px)', // Ajustez la position en fonction de la largeur
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          '&:before': {
            content: '"Ouvert"',
            display: 'block',
            position: 'absolute',
            lineHeight: 1,
            textAlign: "-webkit-left",
            padding: "4px 3px",
            color: theme.palette.common.white,
            fontSize: 14, 
            fontWeight: "bold",
            right: "none",
            left:0
          },
        },
      },
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: theme.palette.common.white,
      width: 32,
      height: 32,
    },
    '& .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: theme.palette.grey[400],
      borderRadius: 20 / 2,
      position: 'relative',
      '&:before': {
        content: '"Fermé"',
        display: 'block',
          position: 'absolute',
          lineHeight: 1,
          textAlign: "-webkit-left",
          padding: "4px 0px",
          right: "7px",
          color: theme.palette.common.white,
          fontSize: 14, 
          fontWeight: "bold"
      },
    },
  }));

const Horaires: React.FC = () => {
    const { data:dbDAta, error:errorr, isLoading:load, isError:errorIS } = useGetHorairesQuery("");
    const [createOrUpdateHoraires, { isLoading, isError }] = useCreateOrUpdateHorairesMutation();

  const [data, setData] = useState<DayData[]>(initialData);
    useEffect(() => {
        console.log("data0",data)
        if (dbDAta) {
            const filteredData = dbDAta.data.map((item: any) => ({
                day: item.day,
                open: item.open,
                periods: item.periods ? [...item.periods] : [],
              }));
              setData(filteredData);
           
          }
          console.log("data1",data)
    }, [dbDAta]);
  
   /*  if (load) return <div>Loading...</div>; // Affichez un message de chargement pendant le chargement des données
    if (errorIS) return <div>Error: {errorr}</div>; */


  const handleOpenChange = (index: number) => {
    const newData = [...data];
    newData[index].open = !newData[index].open;
    setData(newData);
  };

  const handleAddPeriod = (index: number) => {
    const newData = [...data];
    newData[index].periods.push({ start: '', end: '' });
    setData(newData);
  };

  const handleDeletePeriod = (dayIndex: number, periodIndex: number) => {
    const newData = [...data];
    newData[dayIndex].periods.splice(periodIndex, 1);
    setData(newData);
  };

  const handlePeriodChange = (dayIndex: number, periodIndex: number, field: 'start' | 'end', value: string) => {
    const newData = [...data];
    newData[dayIndex].periods[periodIndex][field] = value;
    setData(newData);
  };
  const { displaySuccessSnackBar, displayErrorApiSnackBar } = useSnackBar();

  const handleSubmit = (data:DayData[]) => {
    const formData = new FormData();
    data.forEach(day => {
    formData.append(`day[${day.day}][open]`, String(day.open)); // Convertir en chaîne de caractères
    day.periods.forEach((period, index) => {
      formData.append(`day[${day.day}][periods][${index}][start]`, period.start);
      formData.append(`day[${day.day}][periods][${index}][end]`, period.end);
    });
  });
    createOrUpdateHoraires(formData)
      .unwrap()
      .then((data) => {
        console.log('Horaires created/updated successfully:', data);
        displaySuccessSnackBar("Horaires créés/mis à jour avec succès");
      })
      .catch((error) => {
        console.error('Error creating/updating Horaires:', error);
        displayErrorApiSnackBar(error);
      });
  };

  return (
    <Container>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Jour</TableCell>
              <TableCell>Ouvert/Fermé</TableCell>
              <TableCell>Horaires</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((day, dayIndex) => (
              <TableRow key={day.day}>
                <TableCell>{day.day}</TableCell>
                <TableCell>
                  <FormControlLabel
                    control={
                      <CustomSwitch
                        checked={day.open}
                        onChange={() => handleOpenChange(dayIndex)}
                      />
                    }
                    label=""
                  />
                </TableCell>
                <TableCell>
                  {day.open ?  (<>{day.periods.map((period, periodIndex) => (
                    <div key={periodIndex} style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
                      <TextField
                        type="time"
                        value={period.start}
                        onChange={(e) => handlePeriodChange(dayIndex, periodIndex, 'start', e.target.value)}
                        style={{ marginRight: 8 }}
                      />
                      <TextField
                        type="time"
                        value={period.end}
                        onChange={(e) => handlePeriodChange(dayIndex, periodIndex, 'end', e.target.value)}
                        style={{ marginRight: 8 }}
                      />
                      <IconButton onClick={() => handleDeletePeriod(dayIndex, periodIndex)}>
                        <Delete />
                      </IconButton>
                    </div>
                  ))}</>):null}
                  <Button variant="outlined" startIcon={<Add />} onClick={() => handleAddPeriod(dayIndex)}  disabled={!day.open}>
                    Ajouter Période
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button variant="contained" color="primary" onClick={() => handleSubmit(data)} style={{ marginTop: 16, float: "right" }}>
        Enregistrer les Horaires
      </Button>
    </Container>
  );
};

export default Horaires;
