import { useTranslation } from "react-i18next";
import { ChangeEvent, useCallback, useState, useEffect } from "react";
import Dialog from "components/common/Dialog/Dialog";
import Button from "components/common/Button/Button";
import {
  ContainerButtonStyle,
  DeleteButtonsStyle,
  ImageContainerStyle,
  ImageStyle,
  InputStyle,
} from "./EditExpeditionCategory.style";
import { useSnackBar } from "hooks/useSnackBar";
import TextInput from "components/common/TextInput/TextInput";
import { useUpdateCategoryMutation } from "redux/api/category/category";
import FileInput from "components/common/FileInput/FileInput";
import { CONFIG, GlobalVariables } from "config/constant";
import {
  IExpeditionCategoryEditingData,
  IExpeditionCategoryEditingDataError,
  IEditingDialog,
} from "./EditExpeditionCategory.types";
import { useUpdateTaxMutation } from "redux/api/tax/taxes";
import { useUpdateExpeditionCategMutation } from "redux/api/delivery/expeditionCategs/expeditionCategs";

function EditExpeditionCategory({
  iniData,
  openEditingDialog,
  setOpenEditingDialog,
}: IEditingDialog) {
  const { t } = useTranslation();

  const { displaySuccessSnackBar, displayErrorApiSnackBar } = useSnackBar();

  const [editingTax, { data, error, isLoading, reset }] =
    useUpdateExpeditionCategMutation();
    if (error) {
      displayErrorApiSnackBar(error);
      reset();
    }
    if (data) {
      displaySuccessSnackBar(t("delivery.successfully_editing"));
      reset();
    }


  const closeEditingDialog = useCallback(() => {
    setOpenEditingDialog(false);
    setEditingData({ name: "" })
    setEditingError({name: ""})
  }, [setOpenEditingDialog]);

  const onSuccessEditingDialog = (event: React.FormEvent) => {
    event.preventDefault();


    editingTax({
      id: editingData.id,
      name:editingData.name
    });
    if(!editingData.name){
      setEditingData({name:"Nom est obligatoire"})
    }
    else {
      setEditingData({name:""})
    closeEditingDialog()
    }
  
 
  };

  const [editingData, setEditingData] = useState<IExpeditionCategoryEditingData>({
    id: iniData.id,
    name: iniData.name,
  });
  const [editingError, setEditingError] = useState<IExpeditionCategoryEditingDataError>({
    name: "",

  });
  
  const onChange = useCallback(
    (field: string) =>
      (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setEditingData({ ...editingData, [field]: e.target.value });
      },
    [editingData]
  );

  useEffect(() => {
 
    if (openEditingDialog)
      setEditingData({
        id: iniData.id,
        name: iniData.name,

      });
  }, [openEditingDialog, iniData.id, iniData.name]);

  return (
    <Dialog
      open={openEditingDialog}
      handleClose={closeEditingDialog}
      title={t("delivery.edit_cat")}
    >
      <form onSubmit={onSuccessEditingDialog}>
        <InputStyle>
          <TextInput
            value={editingData.name}
            label={t("tax.name")}
            onChange={onChange("name")}
            error={t(editingError.name)}
          />
        </InputStyle>

      
        

        <DeleteButtonsStyle>
          <ContainerButtonStyle>
            <Button
              disabled={isLoading}
              title={t("common.cancel_button")}
              onClick={closeEditingDialog}
            />
          </ContainerButtonStyle>
          <ContainerButtonStyle>
            <Button
              disabled={isLoading}
              type="submit"
              loader={isLoading}
              title={t("common.confirm_button")}
            />
          </ContainerButtonStyle>
        </DeleteButtonsStyle>
      </form>
    </Dialog>
  );
}
export default EditExpeditionCategory;
