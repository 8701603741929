import Dialog from "components/common/Dialog/Dialog";
import { IImportCSVDialog } from "./AddModalProductsCSV.types";
import {
  ContainerButtonStyle,
  DeleteButtonsStyle,
  InputStyle,
} from "./AddModalProductsCSV.style";
import FileInput from "components/common/FileInput/FileInput";
import { useTranslation } from "react-i18next";
import Button from "components/common/Button/Button";
import { useEffect, useState } from "react";
import { useImportProductMutation } from "redux/api/product/product";
import { any } from "prop-types";
import { useSnackBar } from "hooks/useSnackBar";


const AddCSV = ({
  openImportCSVDialog,
  setOpenImportingCSVDialog,
  setAlert,
}: IImportCSVDialog) => {
  const { displaySuccessSnackBar, displayErrorApiSnackBar } = useSnackBar();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const { t } = useTranslation();
  const [importProduct, { data, error, isLoading, reset }] =
    useImportProductMutation();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (fileList && fileList.length > 0) {
      setSelectedFile(fileList[0]);
    } else {
      setSelectedFile(null);
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      await importProduct({ formData: formData })
        .then((result: any) => {
          if (result && result.data?.status == "success") {
            displaySuccessSnackBar(
              "Les produits ont été importés avec succès."
            );
            if (result.data?.file_url) {
              const fileUrl = result.data.file_url;
              const filename = "errors.csv";
              const a = document.createElement("a");
              a.href = fileUrl;
              a.download = filename;
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
            }
          }

          if (result && result.data?.status == "success" && result.data?.file_url) {
            setAlert(true)
          }
          setOpenImportingCSVDialog(false);
        })
        .catch((error) => {
          console.error("Error importing product:", error);
          displayErrorApiSnackBar(error);
        });
    }
  };

  return (
    <>
      <Dialog
        open={openImportCSVDialog}
        handleClose={() => {
          setOpenImportingCSVDialog(false);
        }}
        title={t("common.import_csv")}
      >
        <form>
          <InputStyle>
            <FileInput
              value={selectedFile}
              label={"Fichier CSV"}
              onChange={handleFileChange}
              error={""}
              accept={"csv"}
            />
          </InputStyle>
          <p style={{ color: "red" }}>
            <b>NB:</b>{" "}
          </p>
          <ul>
            <li>
              Les colonnes à importer depuis le fichier Excel sont : titre,
              description, prix_ht, prix_achat, prix_ttc, format, quantite et
              sous_categorie.
              <li>Le titre est obligatoire.</li>
              <li>Le statut par défaut des produits ajoutés est "brouillon".</li>
              <li>
                En cas d'erreur lors de l'insertion des données dans une ligne,
                celle-ci doit être enregistrée dans un fichier Excel nommé
                "failed_products", qui sera téléchargé automatiquement à la fin de
                l'importation.
              </li>
            </li>
          </ul>
          <DeleteButtonsStyle>
            <ContainerButtonStyle>
              <Button
                title={t("common.cancel_button")}
                onClick={() => setOpenImportingCSVDialog(false)}
              />
            </ContainerButtonStyle>
            <ContainerButtonStyle>
              <Button
                disabled={isLoading}
                onClick={handleSubmit}
                type="submit"
                title={t("common.confirm_button")}
              />
            </ContainerButtonStyle>
          </DeleteButtonsStyle>
        </form>
      </Dialog>
    </>
  );
};

export default AddCSV;
