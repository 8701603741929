import { useTranslation } from "react-i18next";
import { TablePropsInterface } from "./Table.type";
import { Paper, TableBody, TableContainer, TableRow } from "@mui/material";
import {
  CellContentStyle,
  CircularProgressStyle,
  ExpandLessStyle,
  ExpandMoreStyle,
  HeaderStyle,
  IconButtonStyle,
  LoaderContainerStyle,
  TableCellStyle,
  TableHeaderCellStyle,
  TableStyle,
  UnfoldMoreStyle,
} from "./Table.style";
import Pagination from "../Pagination/Pagination";

function Table({
  headerData,
  bodyData,
  paginationData,
  loader,
}: TablePropsInterface) {
  console.log("bodyData",bodyData);
  const { t } = useTranslation();
  return (
    <>
      <TableContainer component={Paper}>
        <TableStyle>
          <HeaderStyle>
            <TableRow>
              {headerData.map((e) => (
                <TableHeaderCellStyle
                  key={e.key}
                  onClick={e.onClick}
                  isheaderclickable={e.onClick !== undefined}
                  align="left"
                  style={e.onClick !== undefined ? { cursor: "pointer" } : {}}
                >
                  <CellContentStyle>
                    <p>{e.title}</p>
                    {e.img != null && e.img}
                    {e.onClick && (
                      <IconButtonStyle onClick={e.onClick}>
                        {e.asc === undefined && <UnfoldMoreStyle />}
                        {e.asc === true && <ExpandLessStyle />}
                        {e.asc === false && <ExpandMoreStyle />}
                      </IconButtonStyle>
                    )}
                  </CellContentStyle>
                </TableHeaderCellStyle>
              ))}
            </TableRow>
          </HeaderStyle>
          {!loader && (
            <TableBody>
              {bodyData?.map((row, index) => (
                <TableRow key={index}   sx={row.bgcolor ? {backgroundColor : row.bgcolor} : {}}>
                  {headerData.map((e) => (
                    <TableCellStyle
                      key={index + "-" + e.key}
                      align="left"
                      padding="none"
                    >
                      {row[e.key]}{row["style"]}
                    </TableCellStyle>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          )}
        </TableStyle>
        {loader && (
          <LoaderContainerStyle>
            <CircularProgressStyle color="primary" />
          </LoaderContainerStyle>
        )}
        {!loader && (bodyData === undefined || bodyData?.length === 0) && (
          <LoaderContainerStyle>
            <p>{t("common.no_data")}</p>
          </LoaderContainerStyle>
        )}
        {!loader && paginationData && (
          <Pagination
            page={paginationData.page}
            lastPage={paginationData.lastPage}
            perPage={paginationData.perPage}
            onPerPageChange={paginationData.onPerPageChange}
            onPageChange={paginationData.onPageChange}
          />
        )}
      </TableContainer>
    </>
  );
}
export default Table;
