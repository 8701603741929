import { createApi } from "@reduxjs/toolkit/query/react";
import { ENDPOINTS } from "config/constant/endpoints.config";
import { baseQueryWithReauth } from "../rtkBaseQuery";

export const authApi = createApi({
  reducerPath: "AuthApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    login: builder.mutation({
      query: ({ email, password }: { email: string; password: string }) => ({
        url: `${ENDPOINTS.LOGIN}`,
        method: "POST",
        body: { email, password },
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: `${ENDPOINTS.LOGOUT}`,
        method: "POST",
      }),
    }),
    currentUser: builder.query({
      query: () => ({
        url: `${ENDPOINTS.PROFILE}`,
        method: "GET",
      }),
    }),
    updatePassword: builder.mutation({
      query: ({
        current_password,
        new_password,
        new_password_confirmation,
      }) => ({
        url: `${ENDPOINTS.UPDATE_PASSWORD}`,
        method: "POST",
        body: { current_password, new_password, new_password_confirmation },
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useCurrentUserQuery,
  useUpdatePasswordMutation,
} = authApi;
