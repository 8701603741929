import { persistData, removePersistData } from "utils/helpers";
import { GlobalVariables } from "../../config/constant";

/**
 * Confirms if there is a connected user or not
 *
 * @returns {boolean}
 */
export const isLoggedIn = () => !!localStorage.getItem(GlobalVariables.TOKEN);

export const getTokenFromLocalStorage = () =>
  localStorage.getItem(GlobalVariables.TOKEN);

export const saveTokenToLocalStorage = (token: string) =>
  persistData(GlobalVariables.TOKEN, token);

export const deleteTokenFromLocalStorage = () =>
  removePersistData(GlobalVariables.TOKEN);
