import { useState, useEffect, useCallback, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import { useLoginMutation } from "redux/api/auth/auth";
import { useSnackBar } from "hooks/useSnackBar";
import { saveTokenToLocalStorage } from "utils/services/storage.service";
import imglogin from "assets/img/imglogin.jpeg";

export default function Login() {
  const [data, setData] = useState({ email: "", password: "" });
  const navigate = useNavigate();
  const { displayErrorApiSnackBar } = useSnackBar();
  const [login, { data: dataLogin, error: errorLogin }] = useLoginMutation();

  const onChange = useCallback(
    (field) => (e) => {
      setData({ ...data, [field]: e.target.value });
    },
    [data]
  );

  useEffect(() => {
    if (errorLogin) {
      displayErrorApiSnackBar(errorLogin);
    }
  }, [errorLogin, displayErrorApiSnackBar]);

  useEffect(() => {
    if (dataLogin) {
      saveTokenToLocalStorage(dataLogin.data.token);
      navigate("/");
    }
  }, [dataLogin, navigate]);

  const onSubmitLogin = (event) => {
    event.preventDefault();
    login({ email: data.email, password: data.password });
  };

  return (
    <div className="flex min-h-screen flex-1">
      <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <img className="h-16 w-auto" src="/logo.jpeg" alt="Votre entreprise" />
            <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Connectez-vous à votre compte
            </h2>
          </div>
          <div className="mt-10">
            <form onSubmit={onSubmitLogin} className="space-y-6">
              <div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                  Adresse email
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    onChange={onChange("email")}
                    className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-yellow-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                  Mot de passe
                </label>
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    onChange={onChange("password")}
                    className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-yellow-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-yellow-600 focus:ring-yellow-600"
                  />
                  <label htmlFor="remember-me" className="ml-3 block text-sm leading-6 text-gray-700">
                    Se souvenir de moi
                  </label>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-yellow-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-yellow-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                >
                  Se connecter
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="relative hidden w-0 flex-1 lg:block">
        <img className="absolute inset-0 h-full w-full " src={imglogin} alt="" />
      </div>
    </div>
  );
}
