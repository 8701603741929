import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import React from 'react';
import Chart from 'react-apexcharts';
interface IProps{
    data:number[]
}

const ConnectionStats = (props:IProps) => {
  const options:any = {
    
      chart: {
        width: 380,
        type: "pie"
      },
      labels:["Pc","Tablette","Smartphone"],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: "bottom"
          }
        }
      }],
      dataLabels: {
        enabled: true,
        formatter: function (val:any, opts:any) { return opts.w.config.series[opts.seriesIndex] + '%'; },
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#304758"]
        }
      },
      legend: {
        show: true,
        position: "bottom",
        horizontalAlign: "center",
        fontSize: "14px",
        markers: {
          width: 19,
          height: 19,
          radius: 5
        },
        itemMargin: {
          horizontal: 8,
          vertical: 0
        }
      },
      tooltip: {
        y: {
          formatter: function (val:any) { return val + '%'; }
        }
      }
    }
  

  return (
    <Box
    sx={{
      boxShadow: 3,
      width: 'auto',
      height: 'auto',
      bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
      color: (theme) =>
        theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
      p: 1,
      m: 1,
      borderRadius: 2,
      fontSize: '0.875rem',
      fontWeight: '700',
    }}
  >
        <Grid item xs={12}>
     <Grid item style={{float: 'right'}}>
     </Grid>


     <Typography variant="h6" style={{textAlign:"center"}} color="textSecondary">
      Statistique sur le mode de connexion</Typography>
      </Grid>
    <Chart options={options} series={props.data} type="pie" width="380" />
 </Box>
  );
};

export default ConnectionStats;