import { useTranslation } from "react-i18next";
import { useCallback, useState } from "react";
import Dialog from "components/common/Dialog/Dialog";
import Button from "components/common/Button/Button";
import {
  ContainerButtonStyle,
  DeleteButtonsStyle,
} from "./PublishProduct.style";
import { IProductPublish} from "./PublishProduct.types";

import { useDeletePromotionCodeMutation } from "redux/api/promotionCodes/promotionCodes";
import { useSnackBar } from "hooks/useSnackBar";

function PublishProduct({
  product_ids,
  openDeleteMultipleDialog,
  isLoading,
  setOpenDeleteMultipleDialog,
  onSubmit
}: IProductPublish) {
  const { t } = useTranslation();

  const { displaySuccessSnackBar, displayErrorApiSnackBar } = useSnackBar();



  const closeDeleteAlertDialog = useCallback(() => {
    setOpenDeleteMultipleDialog(false);

  }, [setOpenDeleteMultipleDialog]);

  
  return (
    <Dialog
      open={openDeleteMultipleDialog}
      handleClose={closeDeleteAlertDialog}
      title={t("product.publish")}
    >
      <form onSubmit={onSubmit}>
        <p>
          {t("common.publish_message")}
          <span style={{ fontWeight: "bold", marginLeft: 5 }}>Les produits selectionnés</span>!
        </p>
        <DeleteButtonsStyle>
          <ContainerButtonStyle>
            <Button
              disabled={isLoading}
              title={t("common.cancel_button")}
              onClick={closeDeleteAlertDialog}
            />
          </ContainerButtonStyle>
          <ContainerButtonStyle>
            <Button
              disabled={isLoading}
              type="submit"
              loader={isLoading}
              title={t("common.confirm_button")}
            />
          </ContainerButtonStyle>
        </DeleteButtonsStyle>
      </form>
    </Dialog>
  );
}
export default PublishProduct;
