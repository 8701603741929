import useCategoryList from "features/Category/CategoryList/useCategoryList";
import {
  ChangeEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  IProductData,
  IProductDataAddingDataError,
  IProductDataAddingImage,
  IProductDataAddingImageError,
} from "./Product.types";
import { useTranslation } from "react-i18next";
import { isNumber } from "utils/helpers/global.helpers";
import { useNavigate } from "react-router-dom";
import { useAddProductMutation } from "redux/api/product/product";
import { RouteIdEnum } from "config/enums";
import { useSnackBar } from "hooks/useSnackBar";
import { useGetAllSubCategoriesQuery } from "redux/api/category/category";
import { AltRoute } from "@mui/icons-material";

export default function useAddProductList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { displaySuccessSnackBar, displayErrorApiSnackBar } = useSnackBar();
  const { categoryData } = useCategoryList();

  const [addingData, setAddingData] = useState<IProductData>({
    title: "",
    description: "",
    price: "",
    priceLolo: "",
    priceNonSubscriber: "",
    priceLoloTtc: "",
    priceNonSubscriberTtc: "",
    quantity: {},
    category_product_id: "",
    images: [],
    HT_price: "",
    subCategory_product_id: "",
    tax_id: null,
    color_hex: "",
    TTC_price: "",
    bar_code: "",
    credits: "",
    tag: "",
    status: "1",
    publication_date: "",
    show: "",
    nutriscore: "",
    limit_stock: "",
    min_quantity: "",
    color: "",
    nutrivalues: [],
    provider_id: "",
    marge: "",
    categ_exped: null,
    keyWords: [],
    format: "",
    unite_format: "",
    brand: "",
    garentie: "",
    DateDurabMin: "",
    dateLimiteCons: "",
    kdQuantity: "",
    frais_stock: null
  });
  const { data: subcategories, isLoading } = useGetAllSubCategoriesQuery({
    id: addingData?.category_product_id,
    all: true,
  });
  const [nbrPos, setNbrPos] = useState(0);
  const [compteurImages, setCompteurImages] = useState(1);
  const [subCategs, setSubCategs] = useState([]);
  const [addingError, setAddingError] = useState<IProductDataAddingDataError>({
    title: "",
    description: "",
    price: "",
    priceLolo: "",
    priceNonSubscriber: "",
    priceLoloTtc: "",
    priceNonSubscriberTtc: "",
    quantity: "",
    category_product_id: "",
    image: "",
    HT_price: "",
    subCategory_product_id: "",
    tax_id: "",
    color_hex: "",
    TTC_price: "",
    bar_code: "",
    credits: "",
    tag: "",
    status: "",
    publication_date: "",
    show: "",
    nutriscore: "",
    limit_stock: "",
    min_quantity: "",
    provider_id: "",
    nutrivalues: [],
    marge: "",
    categ_exped: "",
    keyWords: [],
    format: "",
    unite_format: "",
    brand: "",
    garentie: "",
    DateDurabMin: "",
    dateLimiteCons: "",
    kdQuantity: "",
    frais_stock:""
  });
  const [addingImage, setAddingImage] = useState<IProductDataAddingImage[]>([]);
  const [addingOneImage, setAddingOneImage] = useState<IProductDataAddingImage>(
    {
      id: 0,
      color_name: "",
      color_hex: "",
      is_main_image: 0,
      file: null,
    }
  );

  const [isMainImageSelected, setIsMainImageSelected] =
    useState<boolean>(false);
  const [addingOneImageError, setAddingOneImageError] =
    useState<IProductDataAddingImageError>({
      color_name: "",
      color_hex: "",
      file: "",
    });

  const onChange = useCallback(
    (field: string) =>
      (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setAddingError({ ...addingError, [field]: "" });
        setAddingData({ ...addingData, [field]: e.target.value });
      },
    [addingData, addingError]
  );
  const onChangeQuantity = useCallback(
    (posId: string) =>
      (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setAddingError((prevErrors) => ({
          ...prevErrors,
          quantity: "",
        }));
        setAddingData((prevData) => ({
          ...prevData,
          quantity: {
            ...prevData.quantity,
            [posId]: e.target.value,
          },
        }));
      },
    []
  );
  const handleDatePickerChange = (date: string, field: string) => {
    // Check if the date is valid
    if (date && !isNaN(new Date(date).getTime())) {
      const formattedDate = new Date(date).toISOString().split('T')[0];
      setAddingData({ ...addingData, [field]: formattedDate });
    } else {
      // If date is invalid or empty, set the field to null or handle accordingly
      setAddingData({ ...addingData, [field]: null });
    }
  };

  const onChangeSelectCategory = useCallback(
    (field: string) => (e: string) => {
      setAddingData({ ...addingData, category_product_id: e });
      /*       validateWhenChange()
       */ setSubCategs(subcategories);
      setAddingError({ ...addingError, category_product_id: "" });
    },
    [addingData, addingError]
  );
  const onChangeSelectShow = useCallback(
    (field: string) => (e: string) => {
      console.log(e)
      setAddingData({ ...addingData, show: e });
      setAddingError({ ...addingError, show: "" });
    },
    [addingData, addingError]
  );
  const onChangeSelectFormatUnit = useCallback(
    (field: string) => (e: string) => {
      console.log(e)
      const title = e == "1" ? "Kg" : "L"
      console.log(title)
      setAddingData({ ...addingData, unite_format: title });
      setAddingError({ ...addingError, unite_format: "" });
    },
    [addingData, addingError]
  );
  const onChangeSelectProvider = useCallback(
    (field: string) => (e: string) => {
      setAddingData({ ...addingData, provider_id: e });
      setAddingError({ ...addingError, provider_id: "" });
    },
    [addingData, addingError]
  );
  const onChangeSelectCategExped = (
    event: SyntheticEvent<Element, Event>,
    newValue: { id: string; label: string } | null
  ) => {
    console.log("newValue", newValue);
    setAddingData({ ...addingData, categ_exped: newValue });
    setAddingError({ ...addingError, categ_exped: "" });
  };

  const onChangeSelectFrais = (
    event: SyntheticEvent<Element, Event>,
    newValue: { id: string; label: string } | null
  ) => {
    console.log("newValue", newValue);
    setAddingData({ ...addingData, frais_stock: newValue });
    setAddingError({ ...addingError, frais_stock: "" });
  };
  

  useEffect(() => {
    console.log("addingData", addingData);
  }, [addingData]);
  const onChangeSelectSubCategory = useCallback(
    (field: string) => (e: string) => {
      setAddingData({ ...addingData, subCategory_product_id: e });
      setAddingError({ ...addingError, subCategory_product_id: "" });
    },
    [addingData, addingError]
  );
  const onChangeSelectStatus = useCallback(
    (field: string) => (e: string) => {
      setAddingData({ ...addingData, status: e });

      let IntErrors: any = {
        title: "",
        description: "",
        price: "",
        priceLolo: "",
        priceNonSubscriber: "",
        quantity: "",
        category_product_id: "",
        image: "",
        HT_price: "",
        subCategory_product_id: "",
        tax_id: "",
        color_hex: "",
        TTC_price: "",
        bar_code: "",
        credits: "",
        tag: "",
        status: "",
        publication_date: "",
        show: "",
        nutriscore: "",
        limit_stock: "",
        min_quantity: "",
        provider_id: "",
        nutrivalues: [],
        marge: "",
        categ_exped: "",
        keyWords: [],
        format: "",
        brand: "",
        garentie: "",
        frais_stock: "",
      };
      setAddingError(IntErrors);
    },
    [addingData, addingError]
  );

  const onChangeKeyWords = (e: any, keyWords: any) => {
    setAddingData({ ...addingData, keyWords: keyWords });
    setAddingError({ ...addingError, keyWords: [] });
  };
  const onChangeSelectTax = (
    e: any,
    newValue: { id: number; name: string; percentage: string }
  ) => {
    setAddingData({ ...addingData, tax_id: newValue });
    setAddingError({ ...addingError, tax_id: "" });
  };
  const onChangeSelectBrand = useCallback(
    (field: string) => (e: string) => {
      setAddingData({ ...addingData, brand: e });
      setAddingError({ ...addingError, brand: "" });
    },
    [addingData, addingError]
  );

  const onChangeSelectNutriscore = useCallback(
    (field: string) => (e: string) => {
      setAddingData({ ...addingData, nutriscore: e });
      setAddingError({ ...addingError, nutriscore: "" });
    },
    [addingData, addingError]
  );
  const onChangeOneImage = useCallback(
    (field: string) =>
      (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const files = (e.target as HTMLInputElement).files;

        if (field === "color_name") {
          setAddingOneImage({
            ...addingOneImage,
            color_name: e.target.value,
          });
        }
        if (field === "color_hex") {
          setAddingOneImage({
            ...addingOneImage,
            color_hex: e.target.value,
          });
        }
        setAddingOneImageError({
          color_name: "",
          color_hex: "",
          file: "",
        });
        if (field === "is_main_image") {
          setAddingOneImage({
            ...addingOneImage,
            is_main_image: addingOneImage.is_main_image ? 0 : 1,
          });
        }

        if (files) {
          const file = files[0];

          // Check if the selected file is an image
          if (file.type.startsWith("image/")) {
            const reader = new FileReader();

            reader.onload = (event) => {
              if (event.target) {
                const image = new Image();
                image.src = event.target.result as string;

                image.onload = () => {
                  // Access the image dimensions
                  const imageWidth = image.width;
                  const imageHeight = image.height;

                  console.log("Image Width:", imageWidth);
                  console.log("Image Height:", imageHeight);
                  if (imageWidth == 300 && imageHeight == 300) {
                    setAddingOneImage({
                      ...addingOneImage,
                      [field]: field === "file" ? file : e.target.value,
                    });
                  } else {
                    setAddingOneImageError({
                      ...addingOneImageError,
                      file: "L'image doit être de taille 300 × 300 pixels.",
                    });
                  }
                };
              }
            };

            // Read the selected file as a data URL
            reader.readAsDataURL(file);
          } else {
            // Handle non-image file type
            console.error("Selected file is not an image.");
          }
        }
      },
    [addingOneImage, addingData, addingError]
  );

  const onChangeOneImage2 = useCallback(
    (field: string) =>
      (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setAddingOneImage({
          ...addingOneImage,
          [field]: e.target.value,
        });
        setAddingOneImageError({
          color_name: "",
          color_hex: "",
          file: "",
        });
        if (field === "is_main_image") {
          setAddingOneImage({
            ...addingOneImage,
            is_main_image: addingOneImage.is_main_image ? 0 : 1,
          });
        }
        if (field === "file") {
          const files = (e.target as HTMLInputElement).files;
          console.log("fileee", files);

          if (files)
            setAddingOneImage({
              ...addingOneImage,
              file: files[0],
            });
        }
      },
    [addingOneImage]
  );
  const handleDeleteImage = (itemData: any) => {
    console.log("itemData", addingImage);
    setAddingImage((prevState: any) =>
      prevState.filter((item: any) => item.id !== itemData.id)
    );
  };
  useEffect(() => {
    console.log("addingOne", addingOneImage);
  }, [addingOneImage]);
  const onSaveImage = useCallback(() => {
    const newAddingOneImage = {
      ...addingOneImage,
      id: compteurImages + 1,
    };

    // Update addingImage state with the newAddingOneImage
    setAddingImage((prevState) => [...prevState, newAddingOneImage]);

    // Update isMainImageSelected state if necessary
    if (newAddingOneImage.is_main_image) {
      setIsMainImageSelected(true);
    }

    setAddingOneImage({
      id: compteurImages + 1,
      color_name: "",
      color_hex: "",
      is_main_image: 0,
      file: null,
    });

    // Increment compteurImages state
    setCompteurImages(compteurImages + 1);

    // Reset addingOneImageError state
    setAddingOneImageError({
      color_name: "",
      color_hex: "",
      file: "",
    });

    // Log the updated addingOneImage
    console.log("aachraff", newAddingOneImage);
  }, [addingOneImage]);

  //addling dialog
  const [openAddingDialog, setOpenAddingDialog] = useState<boolean>(false);
  const openAddingDialogContainer = useCallback(() => {
    setOpenAddingDialog(true);
  }, []);

  const closeAddingDialogContainer = useCallback(() => {
    setOpenAddingDialog(false);
    setAddingOneImage({
      id: compteurImages,
      color_name: "",
      color_hex: "",
      is_main_image: 0,
      file: null,
    });
    setAddingOneImageError({
      color_name: "",
      color_hex: "",
      file: "",
    });
  }, [setOpenAddingDialog]);

  const onSuccessAddingDialog = (event: React.FormEvent) => {
    event.preventDefault();
    console.log("isValidImage", isImageValidate());
    if (isImageValidate()) {
      onSaveImage();
      closeAddingDialogContainer();
    }
  };
  const isImageValidate = () => {
    let ok = true;
    let addingImg = {
      color_name: "",
      color_hex: "",
      file: "",
    };
    // if (addingOneImage.color_hex === "") {
    //   ok = false;
    //   addingImg.color_hex = t("product.color_hex_required");
    // }
    // if (addingOneImage.color_name === "") {
    //   ok = false;
    //   addingImg.color_name = t("product.color_name_required");
    // }
    console.log("addingOneImage.file", addingOneImage);
    if (addingOneImage.file === null) {
      ok = false;
      addingImg.file = t("product.file_required");
    }
    if (!ok) {
      setAddingOneImageError(addingImg);
    }

    return ok;
  };
  const validateWhenChange = () => {
    console.log("addut");
  };

  const isValidate = () => {
    let ok = true;
    const addingProduct: any = {};
    const validationRules: { [key: string]: { rule: Function; message: string }[] } = {
      title: [{ rule: isRequired, message: t("product.title_required") }],
      limit_stock: [{ rule: isRequired, message: t("product.limit_stock_required") },
      { rule: isPositiveNumber, message: "Le seuil de stock doit être positif" },
      { rule: isStockLimitValid, message: "Le seuil de stock ne doit pas dépasser 100" },
      ],

      description: [{ rule: isRequired, message: t("product.description_required") }],
      price: [
        { rule: isRequired, message: t("product.price_required") },
        { rule: isPositiveNumber, message: t("product.price_required") },
      ],
      category_product_id: [{ rule: isRequired, message: t("product.category_product_id_required") }],
      subCategory_product_id: [{ rule: isRequired, message: t("product.subCategory_product_id_required") }],
      HT_price: [{ rule: isRequired, message: t("product.price_HT_required") }, { rule: isPositiveNumber, message: "Le Prix d'achat doit être positif" }],
      tax_id: [{ rule: isRequired, message: "Taxe obligatoire" }],
      brand: [{ rule: isRequired, message: "Marque obligatoire" }],
      quantity: [{ rule: isQuantityValid, message: t("product.quantity_required") }],
      bar_code: [{ rule: isRequired, message: t("product.bar_code_required") }],
      publication_date: [{ rule: isRequired, message: t("product.publication_date_required") }],
      status: [{ rule: isRequired, message: t("product.status_required") }],
      show: [{ rule: hasShow, message: t("product.show_required") }],
      categ_exped: [{ rule: isRequired, message: "Catégorie expédition obligatoire" }],
      provider_id: [{ rule: isRequired, message: t("product.provider_id_required") }],
      marge: [{ rule: isPositiveNumber, message: "Le Marge doit être positif" }],
      images: [{ rule: hasImages, message: "image is required" }],
    };
    if (addingData.status == "1") {
      delete validationRules.description;
      delete validationRules.price;
      delete validationRules.category_product_id;
      delete validationRules.subCategory_product_id;
      delete validationRules.HT_price;
      delete validationRules.tax_id;
      delete validationRules.brand;
      delete validationRules.quantity;
      delete validationRules.bar_code;
      delete validationRules.publication_date;
      delete validationRules.show;
      delete validationRules.categ_exped;
      delete validationRules.provider_id;
      delete validationRules.images;
      delete validationRules.limit_stock;
    }
    if (addingData.status == "2") {
      delete validationRules.publication_date;
    }
    if (addingData.status == "3" && !addingData.publication_date) {
      ok = false;
      addingProduct.publication_date = t("product.publication_date_required");
    }
    console.log("validationRules", validationRules);
    // Rest of your logic for handling status and validations

    Object.keys(validationRules).forEach((field) => {
      validationRules[field].forEach(({ rule, message }) => {
        if (!rule(addingData[field])) {
          ok = false;
          addingProduct[field] = message;
        } else {
          addingProduct[field] = "";
        }
      });
    });

    if (!ok) {
      setAddingError(addingProduct);
    }

    return ok;
  };

  // Additional helper to validate stock limit
  const isStockLimitValid = (value: any) => {
    return isNumber(value) && Number(value) <= 100;
  };
  // Validation helpers
  const isRequired = (value: any) => value !== "" && value !== undefined && value !== null;
  // Helper to check if a value is a number
  const isNumber = (value: any) => {
    return value !== undefined && value !== null && !isNaN(Number(value));
  };

  // Modified isPositiveNumber
  const isPositiveNumber = (value: any) => {
    return isNumber(value) && Number(value) >= 0;
  };

  const hasShow = (value: any) => Array.isArray(value) && value.length > 0;
  const hasImages = (value: any) => Array.isArray(value) && value.length > 0;

  const isQuantityValid = (quantity: any) => {
    console.log("quantityquantity", quantity);
    if (Object.keys(quantity).length === 0 || Object.keys(quantity).length < nbrPos) return false;
    for (const value of Object.values(quantity)) {
      if (!value || parseInt(String(value), 10) < 0) {

        return false;
      }
    }
    return true;
  };


  const addProductForm = () => {
    addingData.images = [];
    addingImage.map((item, index) => {
      addingData.images.push({
        id: 0,
        file: item.file,
        is_main_image: item.is_main_image,
        color_hex: item.color_hex,
        color_name: item.color_name,
      });
    });
    const data: any = {
      title: addingData.title,
      description: addingData.description,
      price: addingData.price,
      priceLolo: addingData.priceLolo,
      priceNonSubscriber: addingData.priceNonSubscriber,
      quantity: addingData.kdQuantity,
      quantities: addingData.quantity,
      provider: addingData.provider_id,
      category_product_id: addingData.category_product_id,
      Subcategory_product_id: addingData.subCategory_product_id,
      nutri_score: addingData.nutriscore,
      display: addingData.show,
      purchase_price: addingData.HT_price,
      priceLoloTtc:
        parseFloat(addingData.priceLolo) +
        (parseFloat(addingData.priceLolo) *
          parseFloat(addingData.tax_id?.percentage ?? "0")) /
        100,
      priceNonSubscriberTtc:
        parseFloat(addingData.priceNonSubscriber) +
        (parseFloat(addingData.priceNonSubscriber) *
          parseFloat(addingData.tax_id?.percentage ?? "0")) /
        100,
      price_ttc:
        parseFloat(addingData.price) +
        (parseFloat(addingData.price) *
          parseFloat(addingData.tax_id?.percentage ?? "0")) /
        100,
      barcode: addingData.bar_code,
      discount: addingData.credits,
      label: addingData.tag,
      status: addingData.status,
      publication_date: addingData.publication_date,
      stock_alert: addingData.limit_stock,
      tax_id: addingData.tax_id?.id,
      min_qty: addingData.min_quantity,
      nutritional_values: addingData.nutrivalues,
      images: addingData.images,
      categ_exped: addingData?.categ_exped?.id,
      marge: addingData.marge,
      keyWords: addingData.keyWords,
      format: addingData.format,
      unite_format: addingData.unite_format.id,
      brand: addingData.brand,
      garentie: addingData.garentie,
      dateLimiteCons: addingData.dateLimiteCons,
      DateDurabMin: addingData.DateDurabMin,
      frais_stock: addingData?.frais_stock?.id,
    };

    console.log("data", data);
    console.log("valid", isValidate());
    if (isValidate()) {
      console.log("data", data);
      addingProduct(data);
    }
  };

  const [addingProduct, { data, error, reset }] = useAddProductMutation();

  if (error) {
    displayErrorApiSnackBar(error);
    reset();
  }
  if (data) {
    displaySuccessSnackBar(t("product.successfully_adding"));
    reset();
    navigate("/" + RouteIdEnum.Products);
  }

  return {
    categoryData,
    addingData,
    onChange,
    addingError,
    onChangeSelectCategory,
    onChangeSelectNutriscore,
    openAddingDialogContainer,
    addingImage,
    addProductForm,
    openAddingDialog,
    closeAddingDialogContainer,
    onSuccessAddingDialog,
    addingOneImage,
    onChangeOneImage,
    addingOneImageError,
    isMainImageSelected,
    subcategories,
    setAddingData,
    setAddingError,
    onChangeSelectSubCategory,
    onChangeSelectTax,
    onChangeSelectProvider,
    onChangeSelectShow,
    onChangeSelectCategExped,
    onChangeSelectFrais,
    onChangeKeyWords,
    onChangeSelectStatus,
    validateWhenChange,
    handleDeleteImage,
    onChangeSelectBrand,
    onChangeQuantity,
    handleDatePickerChange,
    setNbrPos,
    onChangeSelectFormatUnit
  };
}
