import { Box, Container, Grid, Typography, styled } from "@mui/material";

export const RootStyle = styled(Container)(({ theme }) => ({
  width: "100vw",
  height: "100vh",
  alignItems: "center",
  justifyContent: "center",
}));

export const LogoStyle = styled("img")(({ theme }) => ({
  height: "25%",
  width: "25%",
  display: "flex",
  justifyContent: "center",
  alignSelf: "center",
  marginBottom: 10,
}));

export const TitleStyle = styled(Typography)(({ theme }) => ({
  paddingRight: 60,
  paddingLeft: 60,
  paddingTop: 20,
  paddingBottom: 20,
  [theme.breakpoints.down("xs")]: {
    padding: 10,
  },
}));

export const BoxFormContainerStyle = styled(Box)(({ theme }) => ({
  paddingRight: 60,
  paddingLeft: 60,
  paddingBottom: 20,
  [theme.breakpoints.down("xs")]: {
    padding: 10,
  },
}));

export const InputStyle = styled("div")(({ theme }) => ({
  width: "100%",
  marginTop: 20,
  marginBottom: 20,
}));

export const UnderlineLinkStyle = styled("p")(({ theme }) => ({
  margin: 0,
  paddingBottom: 20,
  fontSize: 16,
  textAlign: "end",
  textDecoration: "underline",
  color: theme.palette.primary.main,
  cursor: "pointer",
  "&:focus": {
    outlineOffset: 0,
  },
  "&:active": {
    color: theme.palette.primary.main,
  },
  "&:visited": {
    color: theme.palette.primary.main,
  },
  "&:-webkit-any-link": {
    color: theme.palette.primary.main,
  },
}));

export const FooterContainerStyle = styled(Grid)(({ theme }) => ({
  fontSize: 10,
  color: theme.palette.primary.main,
  textTransform: "none",
  "a, button": {
    fontSize: 10,
    color: theme.palette.primary.main,
    textDecoration: "none",
    textTransform: "none",
  },
  ".footer-powered": {
    paddingRight: 5,
  },
}));

export const ButtonContainerStyle = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  [theme.breakpoints.down("xs")]: {
    justifyContent: "center",
  },
}));

export const ActionContainerStyle = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
}));

export const CategoryImageStyle = styled("img")(({ theme }) => ({
  width: 50,
  height: 50,
  objectFit: "contain",
}));
