import { useTranslation } from "react-i18next";
import { ChangeEvent, useCallback, useState,useEffect } from "react";
import Dialog from "components/common/Dialog/Dialog";
import Button from "components/common/Button/Button";
import Table from "components/common/Table/Table";
//import { ButtonContainerStyle } from "./AddValuesExpeditionCategories.types";
import {
  ContainerButtonStyle,
  DeleteButtonsStyle,
  FormStyle,
  InputStyle,
  InputStyleCostMode,
  InputStyleItem,
  ItemModeLine,
  ModeInputs

} from "./AddValuesExpeditionCategories.style";
import ExpeditionCategories from "features/ExpeditionCategory/ExpeditionCategoryList/ExpeditionCategories";
import InputsContainer from "components/common/InputsContainer/InputsContainer";
import { useAddPromotionCodeMutation } from "redux/api/promotionCodes/promotionCodes";
import {
    IExpeditionValueAddingData,
    IExpeditionValuesAddingDataError,
  } from "./AddValuesExpeditionCategories.types";
import { useSnackBar } from "hooks/useSnackBar";
import moment from "moment";
import TextInput from "components/common/TextInput/TextInput";

import { DialogTitleStyle } from "components/common/Dialog/Dialog.style";
import AddButton from "components/common/AddButton/AddButton";
import { useLocation, useNavigate } from "react-router-dom";
//import AddExpeditonCategoryMode from "../ExpeditionCategoryMode/ExpeditionCategoryMode";
//import { IExpeditionCategoriesModeDialog, IExpeditionCategoryModeAddingData } from "../ExpeditionCategoryMode/ExpeditionCategoryMode.types";
import useCategoryList from "features/Category/CategoryList/useCategoryList"
import { useAddExpedModeValueToCategExpedMutation, useAddExpeditionModeMutation } from "redux/api/delivery/expeditionMode/expeditionMode";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import { RouteIdEnum } from "config/enums";
import { AutoInputsContainerStyle } from "features/Product/ProductAdd/Product.style";
import { Grid } from "@mui/material";
import { useGetAllSubCategoriesQuery } from "redux/api/category/category";
import useExpeditionCategoriesList from "features/ExpeditionCategory/ExpeditionCategList/useExpeditionCategoriesList";
import useExpeditionModeList from "features/ExpeditionMode/ExpeditionModeList/useExpeditionModeList";
function AddValuesExpeditionCategories()
 {
  const { t } = useTranslation();
  const [categ,setCategs]=useState<any>([])
  /* const [currentCategExped,setCurrentCategExped]=useState<IExpeditionCategoryModeAddingData>({
    id:0,
    category:"",
    value:""
   })*/
   const {
    expeditionModes,
  } = useExpeditionModeList();
   const navigate=useNavigate()
  const { displaySuccessSnackBar, displayErrorApiSnackBar } = useSnackBar();
  const [openAddingDialog,setOpenAddingDialog]=useState(false)
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const location=useLocation()
  const [addingCode, { data, error, isLoading, reset }] =
    useAddExpedModeValueToCategExpedMutation({

    });


const {title,id}=location?.state
  console.log("title",location.state)
  function getPropertiesWithKeyword(obj: Record<string, any>, keyword: string): Array<{ property: string, value: any }> {
    const result: Array<{ property: string, value: any }> = [];

    for (const prop in obj) {
      if (obj.hasOwnProperty(prop) && prop.toLowerCase().includes(keyword.toLowerCase())) {
        result.push({ property: prop, value: obj[prop] });
      }
    }

    return result;
  }
const validate=()=>{
  const addingDataErrorMode:any={
    title:"",
      start:"",
      end:"",
      tax_value:"",
      categ_value:""
  }
  if(!addingData.title){
    addingDataErrorMode.title="Mode obliagtoire"
  }
  else 
  {
    addingDataErrorMode.title=""

  }

  if(!addingData.categ_value){
    addingDataErrorMode.categ_value="Valeurs categorie d'expédition obliagtoire"
  }
  else 
  {
    addingDataErrorMode.categ_value=""

  }
  setAddingError(addingDataErrorMode)
}
  const onSuccessAddingDialog =async (event: React.FormEvent) => {
    event.preventDefault()

    const propertiesWithCateg: Array<{ property: string, value: any }> = getPropertiesWithKeyword(addingData, 'categ-');
    console.log("propertiesCateg",propertiesWithCateg)
    const categs=propertiesWithCateg.map((categ)=>({mode:categ.property.split("-")[1],value:categ.value}))
    console.log("categs",categs)
    delete addingData.categ_value

    let data:any={
      title:title,
      mode_value:categs,
      id:id

     }
     console.log("data",data)
     validate()
    // if(addingData.title  && addingData.categ_value){
     addingCode(data);


  };
  if (error) {
    displayErrorApiSnackBar(error);
    reset();
  }
  if (data) {
    displaySuccessSnackBar(t("delivery.successfully_adding_mod"));
    navigate(-1)
    reset();
  }
  const [addingData, setAddingData] = useState<IExpeditionValueAddingData>({
    title: "",
    categ_value:[]
  });
  const [categMode,setCategMode]=useState<any>([])
  const openAddingDialogStatus = useCallback(() => {
    setOpenAddingDialog(true);
  }, []);
  const [addingError, setAddingError] =
    useState<IExpeditionValuesAddingDataError>({
        title: "",
        categ_value:[]
    });
   /*  const getCurrentCategory=(category:any)=>{
      setCurrentCategExped(category)
    }*/
  const onChange = useCallback(
    (field: string) =>
      (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setAddingData({ ...addingData, [field]: e.target.value });

      },
    [addingData]
  );
 /* useEffect(()=>{
   currentCategExped.category && currentCategExped.value&&  setCategMode((prev:any)=>[...prev,currentCategExped])
  },[currentCategExped])*/

  return (
    <DashboardLayout
      title={t("delivery.add_mod")}
      selectedPath={RouteIdEnum.Expedition_Categories}
      backPath={RouteIdEnum.Expedition_Categories}
    >
        <h1>{`Ajout des valeurs des modes d'expédition de la catégories ${title}`}</h1>

        <InputsContainer title={t("delivery.categories")}>
        <Grid container spacing={1}>


        <hr/>

  <Table
   loader={false}
   headerData={[
     {
       key: "title",
       title: t("delivery.mode_exped"),
     },
     {
       key: "value",
       title: t("delivery.value"),
     },
   ]}
   bodyData={expeditionModes?.data?.map((mode:any)=>{
     return {
       title: <p>{mode.title}</p>,
       value:  <InputStyleCostMode>
       <TextInput
       type="number"
       min={0}
       value={categ.value}
       onChange={onChange(`categ-${mode.id}`)}
     />
     </InputStyleCostMode>,
 }})}

          />
      





    {/*   <InputStyle>
          <TextInput
            value={`${currentCategExped.description}`}
            label={t("delivery.category")}

            onChange={onChangeCurrentCategExped("description")}
          />
        </InputStyl     <InputsContainer title={t("delivery.info_add")}>
        <Grid container spacing={1}>
          <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
          <TextInput
            value={addingData.title}
            error={t(addingError.title)}

            label={t("delivery.mode")}
            onChange={onChange("title")}
          />
          </AutoInputsContainerStyle>
          <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
          <TextInput
            value={`${addingData.start}`}
            type="number"
            error={t(addingError.start)}

            label={t("delivery.start_time")}

            onChange={onChange("start")}
          />
                      </AutoInputsContainerStyle>
                      <AutoInputsContainerStyle item xs={12} md={12} lg={6}>

          <TextInput
            value={`${addingData.end}`}
            label={t("delivery.end_time")}
            type="number"
            error={t(addingError.end)}

            min={Number(addingData.start)}
            onChange={onChange("end")}
          />
        </AutoInputsContainerStyle>
        <AutoInputsContainerStyle item xs={12} md={12} lg={6}>
          <TextInput
            value={`${addingData.tax_value}`}
            label={t("delivery.tax")}
            error={t(addingError.tax_value)}

            type="number"

            onChange={onChange("tax_value")}
          />
        </AutoInputsContainerStyle>
        </Grid>
        </InputsContainer>e>
        <InputStyle>
          <TextInput
            value={`${currentCategExped.value}`}
            label={t("delivery.value")}

            onChange={onChangeCurrentCategExped("value")}
          />
        </InputStyle>
        <Add onClick={()=>{setIsAddCategExped(!isAddCategExped)}}>Add</Add>
        {isAddCategExped &&<div><InputStyle>
          <TextInput
            value={`${currentCategExped.description}`}
            label={t("delivery.category")}

            onChange={onChangeCurrentCategExped("description")}
          />
        </InputStyle>
        <InputStyle>
          <TextInput
            value={`${currentCategExped.value}`}
            label={t("delivery.value")}

            onChange={onChangeCurrentCategExped("value")}
          />
        </InputStyle>
        </div>}*/}


        </Grid>
        </InputsContainer>
        <Grid container spacing={1} style={{ justifyContent: "flex-end" }}>
        <AutoInputsContainerStyle item xs={4} md={4} lg={2}>
          <Button
            type="submit"
            title={t("delivery.add")}
            onClick={(e:any)=>onSuccessAddingDialog(e)}
          />
        </AutoInputsContainerStyle>
      </Grid>

        </DashboardLayout>


  );
}
export default AddValuesExpeditionCategories
;
