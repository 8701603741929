import { styled } from "@mui/material";

export const ContainerStyle = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
}));

export const InputFileStyle = styled("input")(({ theme }) => ({
  width: 0.1,
  height: 0.1,
  opacity: 0,
  overflow: "hidden",
  position: "absolute",
  zIndex: -1,
  "&+label": {
    fontSize: 14,
    cursor: "pointer",
    color: "white",
    height: "auto",
    backgroundColor: theme.palette.primary.main,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.primary.main,
    borderRadius: 5,
    paddingRight: 17,
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 17,
    width: "100%",
    verticalAlign: "center",
    display: "flex",
    alignItems: "center",
  },
}));

export const FileNameStyle = styled("span")(({ theme }) => ({
  textOverflow: "ellipsis",
  maxHeight: 52,
  overflow: "hidden",
  whiteSpace: "nowrap",
}));

export const IconContainerStyle = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  height: 16,
  flex: 1,
}));

export const ImgIconUploadFileStyle = styled("img")(({ theme }) => ({
  height: 16,
  width: 16,
  marginLeft: 9,
}));

export const ErrorStyle = styled("p")(({ theme }) => ({
  color: "red",
  fontSize: 14,
  width: "100%",
  marginTop: 2,
  marginBottom: 0,
}));
