export const promotion_codes = {
  code: "Code de promotion",
  start_date: "Date de début ",
  percentage: "Pourcentage",
  end_date: "Date d'expiration",
  successfully_deleted: "Code Promotion est supprimé avec succès",
  successfully_adding: "Code Promotion est ajouté avec succès",
  successfully_editing: "Code Promotion est edité avec succès",
  delete: "Supprimer un code de Promotion",
  add: "Ajouter un code de Promotion",
  edit: "Modifier le code de Promotion",
  start_date_title: "Date de début",
  end_date_title: "Date de fin",
  nbUser:"Nombre d'utilisateurs",
  discount:"Montant",
  subscriptions:"Subscriptions",
  client:"Clients",
  code_required:"Code obliatoire"
};
