import { DialogPropsInterface } from "./Dialog.type";
import { Dialog as MaterialDialog,DialogTitle,IconButton } from "@mui/material";
import {
  CloseIconStyle,
  DialogContentStyle,
  DialogTitleStyle,
  SubTitleStyle,
  BootstrapDialog,
} from "./Dialog.style";
import { Close } from "@mui/icons-material";

function Dialog({
  open, // dialog is open
  size,
  handleClose, //handle close
  title, // dialog title
  subTitle, // dialog sub title
  children, // dialog content
}: DialogPropsInterface) {
  console.log('ttt',size);
  return (
    <BootstrapDialog
      open={open}
      onClose={handleClose}
      maxWidth={size?size:"sm"}
      disableEnforceFocus={true}
      fullWidth={true}
      
    >
       <DialogTitleStyle sx={{ m: 0, p: 2 }} >
       {title}
        </DialogTitleStyle>
        <CloseIconStyle  onClick={handleClose} >
          <Close />
        </CloseIconStyle>
    
      <DialogContentStyle   style={{ minWidth: `auto` }} dividers>
      {{ subTitle } &&  
      <DialogTitle id="dialog-title" >
        <SubTitleStyle >{subTitle}</SubTitleStyle>
      </DialogTitle>}
      {children}
      
      </DialogContentStyle>
      
    </BootstrapDialog>
  );
}
export default Dialog;
