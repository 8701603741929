import { RouteIdEnum } from "config/enums";
import { Navigate } from "react-router-dom";
import { isLoggedIn } from "utils/services/storage.service";

interface PublicRouteInterface {
  element: JSX.Element;
}
const PublicRoute = ({ element }: PublicRouteInterface) => {
  return !isLoggedIn() ? (
    element
  ) : (
    <Navigate to={"/" + RouteIdEnum.Root} replace />
  );
};

export default PublicRoute;
