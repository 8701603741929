import { Grid, Select, Pagination as TablePagination } from "@mui/material";

import {
  FormControlStyle,
  LeftComponentContainerStyle,
  PaginationContainerStyle,
  PaginationTableContainerStyle,
  SelectContainerStyle,
} from "./Pagination.style";
import { PaginationPropsInterface } from "./Pagination.type";

function Pagination({
  page,
  lastPage,
  onPageChange,
  perPage,
  onPerPageChange,
  leftComponent,
}: PaginationPropsInterface) {
  return (
    <PaginationContainerStyle container alignItems="center">
      <Grid item xs={12} md={3}>
        <SelectContainerStyle>
          <FormControlStyle variant="outlined">
            <Select native value={perPage} onChange={onPerPageChange}>
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
            </Select>
          </FormControlStyle>
        </SelectContainerStyle>
      </Grid>
      <Grid item xs={12} md={6}>
        <PaginationTableContainerStyle>
          <TablePagination
            count={lastPage}
            page={page}
            onChange={onPageChange}
          />
        </PaginationTableContainerStyle>
      </Grid>
      <Grid item xs={12} md={3}>
        <LeftComponentContainerStyle>
          {leftComponent}
        </LeftComponentContainerStyle>
      </Grid>
    </PaginationContainerStyle>
  );
}
export default Pagination;
