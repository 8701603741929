import { styled } from "@mui/material";

export const DupliqueButtonsStyle = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
}));

export const ContainerButtonStyle = styled("div")(({ theme }) => ({
  margin: 10,
}));
