export const supplier = {
    page_title: "Liste des Fournisseurs",
    company_name: "Raison sociale",
    address: "Addresse",
    postal_code: "Code postal",
    city: "Ville",
    region: "Region",
    department:"Départment",
    country:"Pays",
    email:"Email",
    office_phone:"Téléphone Bureau",
    mobile_phone:"Téléphone Mobile",
    website:"Site web",
    Contact:"Contact",
    contact_last_name:"Last Name",
    contact_first_name:"First Name",
    contact_position:"Position",
    contact_mobile:"Mobile",
    contact_email:"Email",
    contact_office_phone:"Télephone Bureau",
    position:"Position",
    mobile:"Mobile",
    successfully_deleted: "Fournisseur est supprimé avec succès",
    successfully_adding: "Fournisseur est ajouté avec succès",
    successfully_editing: "Fournisseur est edité avec succès",
    delete: "Supprimer le Fournisseur",
    add: "Ajouter Fournisseur",
    edit: "Modifier Fournisseur",
    info_add: "Ajouter les informations de Fournisseur",
    images_add: "Ajouter les images de Fournisseur",
    images_edit: "Modifier les images de Fournisseur",
    image_add: "Ajouter une image",
    color_name: "Nom du couleur",
    color_hex: "Code couleur HEX",
    is_main_image: "Image Principale",
    file: "Image",
    color_hex_required: "Code couleur HEX est obligatoire",
    color_name_required: "Nom du couleur est obligatoire",
    file_required: "Image est obligatoire",
    title_required: "Titre est obligatoire",
    price_required: "Prix est obligatoire",
    quantity_required: "Quantité est obligatoire",
    category_required: "Catégorie est obligatoire",
    quantity_required_number: "Quantité doit être un entier",
    price_required_number: "Prix doit être un entier",
    images_edit_info: "La mise à jour des images supprime les anciennes images!",
    show :"Afficher Fournisseur",
    email_invalid:"email_invalide",
    contact_mobile_required:"Contact mobile Obligatoire",
    office_phone_required:"Téléphone bureau obligatoire",
    postal_code_required:"Code postal obligatoire",
    company_name_required:"Raison sociale obligatoire",
    mobile_phone_required:"Mobile obligatoire",

  };
  