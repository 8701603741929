import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import Dialog from "components/common/Dialog/Dialog";
import Button from "components/common/Button/Button";
import {
  ContainerButtonStyle,
  DupliqueButtonsStyle,
} from "./DupliqueProduct.style";
import { useSnackBar } from "hooks/useSnackBar";
import { useDupliqueProductMutation } from "redux/api/product/product";
import { IProductDuplique } from "./DupliqueProduct.types";

function DupliqueProduct({
  id,
  title,
  openDupliqueDialog,
  setOpenDupliqueDialog,
}: IProductDuplique) {
  const { t } = useTranslation();

  const { displaySuccessSnackBar, displayErrorApiSnackBar } = useSnackBar();

  const [DupliqueCode, { data, error, isLoading, reset }] =
    useDupliqueProductMutation();

  if (error) {
    displayErrorApiSnackBar(error);
  }
  if (data) {
    displaySuccessSnackBar(t("product.successfully_Dupliqued"));
    reset();
  }

  const closeDupliqueDialog = useCallback(() => {
    setOpenDupliqueDialog(false);
  }, [setOpenDupliqueDialog]);

  const onSuccessDupliqueAlert = (event: React.FormEvent) => {
    event.preventDefault();
    DupliqueCode(id);
    closeDupliqueDialog();
  };

  return (
    <Dialog
      open={openDupliqueDialog}
      handleClose={closeDupliqueDialog}
      title={t("product.Duplique")}
    >
      <form onSubmit={onSuccessDupliqueAlert}>
        <p>
        Etes-vous sûr de vouloir dupliquer
          <span style={{ fontWeight: "bold", marginLeft: 5 }}>{title}</span> !
        </p>
        <DupliqueButtonsStyle>
          <ContainerButtonStyle>
            <Button
              disabled={isLoading}
              title={t("common.cancel_button")}
              onClick={closeDupliqueDialog}
            />
          </ContainerButtonStyle>
          <ContainerButtonStyle>
            <Button
              disabled={isLoading}
              type="submit"
              loader={isLoading}
              title={t("common.confirm_button")}
            />
          </ContainerButtonStyle>
        </DupliqueButtonsStyle>
      </form>
    </Dialog>
  );
}
export default DupliqueProduct;
