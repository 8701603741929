import {
  Table,
  TableHead,
  TableCell,
  IconButton,
  CircularProgress,
  styled,
} from "@mui/material";
import { TableProps } from "./Table.type";
import {
  UnfoldMore,
  ExpandLessOutlined,
  ExpandMoreOutlined,
} from "@mui/icons-material";

export const TableStyle = styled(Table)(({ theme }) => ({
  minWidth: 700,
}));

export const HeaderStyle = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
}));

export const TableHeaderCellStyle = styled(TableCell)<TableProps>(
  ({ theme, isheaderclickable }) => ({
    color: "white",
    padding: 0,
    paddingLeft: 5,
    paddingRight: 5,
    "&:hover": {
      backgroundColor: isheaderclickable
        ? theme.palette.primary.light
        : "transparent",
    },
  })
);

export const CellContentStyle = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
}));

export const IconButtonStyle = styled(IconButton)(({ theme }) => ({
  marginRight: 2,
  marginLeft: 2,
  height: 30,
  width: 30,
}));

export const UnfoldMoreStyle = styled(UnfoldMore)(({ theme }) => ({
  color: "white",
  height: 20,
  width: 20,
}));

export const ExpandLessStyle = styled(ExpandLessOutlined)(({ theme }) => ({
  color: "white",
  height: 20,
  width: 20,
}));

export const ExpandMoreStyle = styled(ExpandMoreOutlined)(({ theme }) => ({
  color: "white",
  height: 20,
  width: 20,
}));

export const TableCellStyle = styled(TableCell)(({ theme }) => ({
  paddingLeft: 5,
  paddingRight: 5,
  wordWrap: "break-word",
  maxWidth: 202,
}));

export const LoaderContainerStyle = styled("div")(({ theme }) => ({
  height: 40,
  width: "100%",
  display: "flex",
  justifyContent: "center",
  overflow: "hidden",
}));

export const CircularProgressStyle = styled(CircularProgress)(({ theme }) => ({
  height: "30px !important",
  width: "30px !important",
}));
