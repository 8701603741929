import {
  ImportActionTitleStyle,
  ImportButtonStyle,
  ImportIconStyle,
} from "./ImportButton.style";
import { ImportButtonPropsInterface } from "./ImportButton.types";
import { useTranslation } from "react-i18next";

function ImportButton({ onAddClick, title, className }: ImportButtonPropsInterface) {
  // translation hooks
  const { t } = useTranslation();
  return (
    <ImportButtonStyle onClick={onAddClick} className={className}>
      <ImportIconStyle />
      <ImportActionTitleStyle>{t(title)}</ImportActionTitleStyle>
    </ImportButtonStyle>
  );
}
export default ImportButton;
