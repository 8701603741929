import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// material-ui
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Grid,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { RouteIdEnum } from "config/enums";
import Table from "components/common/Table/Table";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import { IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import SearchInput from "components/common/SearchInput/SearchInput";
import StatusBadge from "components/common/StatusBadge/StatusBadge";
import { ActionContainerStyle } from "../../features/Client/ClientList/Client.style";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
// project import
import OrdersTable from "./OrdersTable";
import OrderHistory from "./OrderHistory";
import BestSellingProducts from "./BestSellingProducts";
import SimpleMaps from "./SimpleMaps";
import ReportAreaChart from "./ReportAreaChart";
import SalesColumnChart from "./SalesColumnChart";
import MainCard from "../../components/statistics/MainCard";
import AnalyticEcommerce from "../../components/statistics/cards/statistics/AnalyticEcommerce";
import SelectInput from "components/common/SelectInput/SelectInput";
import dataJSon from "./response.json"

// assets
import {
  GiftOutlined,
  MessageOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import avatar1 from "assets/img/avatar-1.png";
import avatar2 from "assets/img/avatar-2.png";
import avatar3 from "assets/img/avatar-3.png";
import avatar4 from "assets/img/avatar-4.png";
import useProductList from "features/Product/ProductList/useProductList";
import useExpeditionModeList from "features/ExpeditionMode/ExpeditionModeList/useExpeditionModeList";
import useSuppliersList from "features/Supplier/SuppliersList/useSuppliersList";
import useOrderList from "features/Order/OrderList/useOrderList";
import Camembert from "components/statistics/cards/statistics/Camembert";
import SubscriberProfile from "./SubscriptionProfile";
import SalesChart from "./SalesChart";
import MostSoldProductChart from "./MostSoldProductChart";
import MostSoldBrandChart from "./MostSoldBrandChart";
import PromoSalesFlashStatsChart from "./PromotionSalesFlashStats";
import AbandonedCartsStatsChart from "./AbondonnedCartStatsChart";
import ConnectionStats from "./ConnectionChart";
import GeographicsStatistics from "./GeographicsStatistics";
import PromotionCodesStatsChart from "./PromotionCodesStatsChart";
import ProviderStatsChart from "./ProviderStatsChart";
import UserCharts from "./UserPieChartStats";
import { useGetUserStatisticsMutation } from "redux/api/statistics/users";
import UserDataChart from "./UserDataChart";
import UserList from "./UserList";
import ClientPage from "pages/Clients/Clients";
import Client from "features/Client/ClientList/Client";
import useClientList from "features/Client/ClientList/useClientList";
import { IClientData } from "features/Client/ClientList/Client.types";
import {  RemoveRedEyeRounded } from "@mui/icons-material";
import ExpeditionModeChart from "./ExpeditionModeChart";
import Dashboard from "./Google AnalyticsChart";
import DataTable from "./DataTableChart";
import { Data } from "./types";
import BrandCharts from "./BrandCharts";

// avatar style
const avatarSX = {
  width: 36,
  height: 36,
  fontSize: "1rem",
};

// action style
const actionSX = {
  mt: 0.75,
  ml: 1,
  top: "auto",
  right: "auto",
  alignSelf: "flex-start",
  transform: "none",
};

// sales report status
const status = [
  {
    value: "today",
    label: "Today",
  },
  {
    value: "month",
    label: "This Month",
  },
  {
    value: "year",
    label: "This Year",
  },
];
const volumeData = [100, 200, 150, 300, 250];
const euroData = [500, 600, 550, 700, 650];

// ==============================|| DASHBOARD - DEFAULT ||============================== //
const generateFakeData = () => {
  return {
    male: Math.floor(Math.random() * 100),
    female: Math.floor(Math.random() * 100),
    householdComposition: Math.floor(Math.random() * 5) + 1,
    age: Math.floor(Math.random() * 100),
    country:Math.floor(Math.random() * 100),
    postalCode:Math.floor(Math.random() * 100),
    city: Math.floor(Math.random() * 100),
    employmentStatus: Math.floor(Math.random() * 100),
    housingType: Math.floor(Math.random() * 100),
    ownershipStatus: Math.floor(Math.random() * 100),
  };
};
const data = {
  daily: [100, 150, 200, 250],
  weekly: [700, 800, 900, 1000],
  monthly: [3000, 3500, 4000, 4500],
  yearly: [15000, 16000, 17000, 18000],
};
const periodsData = ['Period 1', 'Period 2', 'Period 3'];
const euroData2 = [1000, 1500, 2000];
const volumeData3 = [50, 70, 90];
const brandData = [20, 25, 30];

const categories = ['Category 1', 'Category 2', 'Category 3', 'Category 4', 'Category 5'];
  const salesData = [60, 80, 100, 100, 120];
  const volumeData2 = [50, 70, 90, 110, 130];
  const averageBasketData = [5, 10, 30, 5, 20];
  const periods = ['Day 1', 'Day 2', 'Day 3'];
  const abandonedCountData = [10, 20, 15];
  const abandonedAmountData = [1000, 2000, 1500];
  const visitorCountData = [50, 60, 70];
  const signupCountData = [5, 10, 8];
  const subscriberCountData = [2, 4, 3];
  const averageCartEuroData = [50, 55, 60];
const DashboardDefault = () => {
  const { t } = useTranslation();
  const [slot, setSlot] = useState('euro');
  const [slotProd, setSlotProd] = useState('produit');
  const [slotBrand, setSlotBrand] = useState('day');
  const {
    onSearchChange,
    onPageChange,
    onPerPageChange,
    onOrderChange,
    clientData,
    isLoading,
    search,
    orderBy,
    orderDir,
    page,
    perPage,
    updateClientStatus,
  } = useClientList();
  const [getUsersStats,{data:statsUsers}]=useGetUserStatisticsMutation()
console.log("user stats",statsUsers)
useEffect(()=>{
  getUsersStats({})
},[])
  return (
    <DashboardLayout
      title={t("dashboard.dashboard_title")}
      selectedPath={RouteIdEnum.Dashboard}
    > <Grid container >
      
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <AnalyticEcommerce title="Total Page Views" count="4,42,236" percentage={59.3} isLoss extra="35,000" />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <AnalyticEcommerce title="Total Users" count="78,250" percentage={70.5} isLoss extra="8,900" />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <AnalyticEcommerce title="Total Order" count="18,800" percentage={27.4} isLoss  extra="1,943" />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <AnalyticEcommerce title="Total Sales" count="$35,078" percentage={27.4} isLoss  extra="$20,395" />
      </Grid>
      


        <Grid item xs={12} md={12} lg={12}>

          <OrderHistory  slot={slot}></OrderHistory>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>

<SalesChart  slot={slotProd}></SalesChart>
</Grid>
<Grid item xs={12} md={12} lg={12}>

<PromotionCodesStatsChart  slot={slotProd}></PromotionCodesStatsChart>
</Grid>
{<Grid item xs={12} md={12} lg={12}>

<ProviderStatsChart  slot={slotProd}></ProviderStatsChart>
  </Grid>}
  {<Grid item xs={12} md={12} lg={12}>

<ExpeditionModeChart  slot={slotProd}></ExpeditionModeChart>
  </Grid>}
  {<Grid item xs={12} md={12} lg={12}>

<BrandCharts  slot={slotProd}></BrandCharts>
  </Grid>}
  {<Grid item xs={12} md={12} lg={12}>

<Dashboard />
  </Grid>}
  {<Grid item xs={12} md={12} lg={12}>

<DataTable data={dataJSon.data as any} />
  </Grid>}
  <Grid item xs={12} md={6} lg={6}>
 
{statsUsers&&<UserCharts title="Statistiques genres" series={statsUsers&& (Object.values(statsUsers["gender_statistics"]) as { total: number; percentage: number }[]).map((stat:{total:number;percentage:number})=>stat.percentage)} 
options={{
    chart: {
      width: 380,
      type: "pie",
    },
    labels: statsUsers&& Object.keys(statsUsers["gender_statistics"]),
    colors: ["#074ae9", "#d224c2"], // Define colors for pie portions here
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: "bottom"
        }
      }
    }],
    dataLabels: {
      enabled: true,
      formatter: function (val:any, opts:any) { return opts.w.config.series[opts.seriesIndex] + '%'; },
      offsetY: -20,
      style: {
        fontSize: "12px",
      }
    },
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      fontSize: "14px",
      markers: {
        width: 19,
        height: 19,
        radius: 5
      },
      itemMargin: {
        horizontal: 8,
        vertical: 0
      },
      labels: {
      }
    },
    tooltip: {
      y: {
        formatter: function (val:any) { return val + '%'; }
      }
    }
  }
}
type="pie"
/>}
  </Grid>
  <Grid item xs={12} md={6} lg={6}>
 
{statsUsers&& <UserCharts title="Statistiques Actifs" series={statsUsers&& (Object.values(statsUsers["enable_statistics"]) as { total: number; percentage: number }[]).map((stat:{total:number;percentage:number})=>stat.percentage)}
 options={{
     chart: {
       width: 380,
       type: "pie"
     },
     labels: statsUsers&&Object.keys(statsUsers["enable_statistics"]),
     colors: ["#ff7d5f", "#ffbf4d"], 
     responsive: [{
       breakpoint: 480,
       options: {
         chart: {
           width: 200
         },
         legend: {
           position: "bottom"
         }
       }
     }],
     dataLabels: {
       enabled: true,
       formatter: function (val:any, opts:any) { return opts.w.config.series[opts.seriesIndex] + '%'; },
       offsetY: -20,
       style: {
         fontSize: "12px",
         colors: ["#304758"]
       }
     },
     legend: {
       show: true,
       position: "bottom",
       horizontalAlign: "center",
       fontSize: "14px",
       markers: {
         width: 19,
         height: 19,
         radius: 5
       },
       itemMargin: {
         horizontal: 8,
         vertical: 0
       }
     },
     tooltip: {
       y: {
         formatter: function (val:any) { return val + '%'; }
       }
     }
   }
 }
 type="pie"
 />}
   </Grid>
   <Grid item xs={12} md={6} lg={6}>
 
{statsUsers&& <UserCharts title="Statistiques Age"  series={statsUsers&& (Object.values(statsUsers["age_statistics"]) as { total: number; percentage: number }[]).map((stat:{total:number;percentage:number})=>stat.percentage)}
 options={{
     chart: {
       width: 380,
       type: "pie"
     },
     labels: statsUsers&& Object.keys(statsUsers["age_statistics"]),
     colors:["#08C5D1","#B6D8F2","#FFBF66"],
     responsive: [{
       breakpoint: 480,
       options: {
         chart: {
           width: 200
         },
         legend: {
           position: "bottom"
         }
       }
     }],
     dataLabels: {
       enabled: true,
       formatter: function (val:any, opts:any) { return opts.w.config.series[opts.seriesIndex] + '%'; },
       offsetY: -20,
       style: {
         fontSize: "12px",
         colors: ["#304758"]
       }
     },
     legend: {
       show: true,
       position: "bottom",
       horizontalAlign: "center",
       fontSize: "14px",
       markers: {
         width: 19,
         height: 19,
         radius: 5
       },
       itemMargin: {
         horizontal: 8,
         vertical: 0
       }
     },
     tooltip: {
       y: {
         formatter: function (val:any) { return val + '%'; }
       }
     }
   }
 }
 type="pie"
 
 />}
   </Grid>
   <Grid item xs={12} md={6} lg={6}>
 
 {statsUsers&&<UserCharts title="Statistiques Pays" series={statsUsers&& (Object.values(statsUsers["country_statistics"]) as { total: number; percentage: number }[]).map((stat:{total:number;percentage:number})=>stat.percentage)}
 options={{
     chart: {
       width: 380,
       type: "pie"
     },
     labels:statsUsers&& Object.keys(statsUsers["country_statistics"]),
     colors:["#00a6b1","#00b07d"],
     responsive: [{
       breakpoint: 480,
       options: {
         chart: {
           width: 200
         },
         legend: {
           position: "bottom"
         }
       }
     }],
     dataLabels: {
       enabled: true,
       formatter: function (val:any, opts:any) { return opts.w.config.series[opts.seriesIndex] + '%'; },
       offsetY: -20,
       style: {
         fontSize: "12px",
         colors: ["#304758"]
       }
     },
     legend: {
       show: true,
       position: "bottom",
       horizontalAlign: "center",
       fontSize: "14px",
       markers: {
         width: 19,
         height: 19,
         radius: 5
       },
       itemMargin: {
         horizontal: 8,
         vertical: 0
       }
     },
     tooltip: {
       y: {
         formatter: function (val:any) { return val + '%'; }
       }
     }
   }
 }
 type="pie"
 
 />}
   </Grid>

  
   <Grid item xs={12} md={6} lg={6}>
 
 {statsUsers&&<UserCharts title="Statistique Villes" series={statsUsers&& (Object.values(statsUsers["cities_statistics"]) as { total: number; percentage: number }[]).map((stat:{total:number;percentage:number})=>stat.percentage)} 
 options={{
     chart: {
       width: 380,
       type: "donut"
     },
     labels:statsUsers&&Object.keys(statsUsers["cities_statistics"]),
     colors:["#9089bd","#f3ecff","#6bbaa3"],
     responsive: [{
       breakpoint: 480,
       options: {
         chart: {
           width: 200
         },
         legend: {
           position: "bottom"
         }
       }
     }],
     dataLabels: {
       enabled: true,
       formatter: function (val:any, opts:any) { return opts.w.config.series[opts.seriesIndex] + '%'; },
       offsetY: -20,
       style: {
         fontSize: "12px",
         colors: ["#304758"]
       }
     },
     legend: {
       show: true,
       position: "bottom",
       horizontalAlign: "center",
       fontSize: "14px",
       markers: {
         width: 19,
         height: 19,
         radius: 5
       },
       itemMargin: {
         horizontal: 8,
         vertical: 0
       }
     },
     tooltip: {
       y: {
         formatter: function (val:any) { return val + '%'; }
       }
     }
   }
 }
 type="donut"
 />}
   </Grid>
   <Grid item xs={12} md={6} lg={6}>
 
 {statsUsers&&<UserCharts title="Statistique Abonnement" series={statsUsers&& (Object.values(statsUsers["subscription_statistics"]) as { total: number; percentage: number }[]).map((stat:{total:number;percentage:number})=>stat.percentage)} 
 options={{
     chart: {
       width: 380,
       type: "donut"
     },
     labels:statsUsers&&Object.keys(statsUsers["subscription_statistics"]),
     colors:["#62fbd4","#00c19d","#008b6a"],
     responsive: [{
       breakpoint: 480,
       options: {
         chart: {
           width: 200
         },
         legend: {
           position: "bottom"
         }
       }
     }],
     dataLabels: {
       enabled: true,
       formatter: function (val:any, opts:any) { return opts.w.config.series[opts.seriesIndex] + '%'; },
       offsetY: -20,
       style: {
         fontSize: "12px",
         colors: ["#304758"]
       }
     },
     legend: {
       show: true,
       position: "bottom",
       horizontalAlign: "center",
       fontSize: "14px",
       markers: {
         width: 19,
         height: 19,
         radius: 5
       },
       itemMargin: {
         horizontal: 8,
         vertical: 0
       }
     },
     tooltip: {
       y: {
         formatter: function (val:any) { return val + '%'; }
       }
     }
   }
 }
 type="donut"
 />}
   </Grid>
   <Grid item xs={12} md={6} lg={6}>
 
 {statsUsers&&<UserCharts title="Statistique Emploi" series={statsUsers&& (Object.values(statsUsers["job_statistics"]) as { total: number; percentage: number }[]).map((stat:{total:number;percentage:number})=>stat.percentage)} 
 options={{
     chart: {
       width: 380,
       type: "donut"
     },
     labels:Object.keys(statsUsers["job_statistics"]),
     colors:["#0098ff","#00cff5","#62fbd4"],
     responsive: [{
       breakpoint: 480,
       options: {
         chart: {
           width: 200
         },
         legend: {
           position: "bottom"
         }
       }
     }],
     dataLabels: {
       enabled: true,
       formatter: function (val:any, opts:any) { return opts.w.config.series[opts.seriesIndex] + '%'; },
       offsetY: -20,
       style: {
         fontSize: "12px",
         colors: ["#304758"]
       }
     },
     legend: {
       show: true,
       position: "bottom",
       horizontalAlign: "center",
       fontSize: "14px",
       markers: {
         width: 19,
         height: 19,
         radius: 5
       },
       itemMargin: {
         horizontal: 8,
         vertical: 0
       }
     },
     tooltip: {
       y: {
         formatter: function (val:any) { return val + '%'; }
       }
     }
   }
 }
 type="donut"
 
 />}
   </Grid>
   <Grid item xs={12} md={6} lg={6}>

<ConnectionStats data={[30, 20, 50]}  />
      </Grid>
      <Grid item xs={12} md={6} lg={6}>

<ConnectionStats data={[30, 20, 50]}  />
      </Grid>

      <Grid item xs={12} md={6} lg={6}>

<ConnectionStats data={[30, 20, 50]}  />
      </Grid>   
      <Grid item xs={12} md={12} lg={12}>
 
 {<UserCharts title="Statistiques Browser" series={
    dataJSon.data.TopBrowsers.map((browser:{browser:string,screenPageViews:number})=>browser.screenPageViews/dataJSon.data.TopBrowsers.map((browser:{browser:string,screenPageViews:number})=>browser.screenPageViews).reduce((accumulator, currentValue) => accumulator + currentValue, 0)*100)

 }
 options={{
     chart: {
       width: 380,
       type: "donut"
     },
     labels:dataJSon.data.TopBrowsers.map((browser:{browser:string,screenPageViews:number})=>browser.browser),
     colors:["#845ec2","#d65db1","#ff6f91","#ff9671","#ffc75f","#f9f871"],
     responsive: [{
       breakpoint: 480,
       options: {
         chart: {
           width: 200
         },
         legend: {
           position: "bottom"
         }
       }
     }],
     dataLabels: {
       enabled: true,
       formatter: function (val:any, opts:any) { return opts.w.config.series[opts.seriesIndex] + '%'; },
       offsetY: -20,
       style: {
         fontSize: "12px",
         colors: ["#304758"]
       }
     },
     legend: {
       show: true,
       position: "bottom",
       horizontalAlign: "center",
       fontSize: "14px",
       markers: {
         width: 19,
         height: 19,
         radius: 5
       },
       itemMargin: {
         horizontal: 8,
         vertical: 0
       }
     },
     tooltip: {
       y: {
         formatter: function (val:any) { return val + '%'; }
       }
     }
   }
 }
 type="donut"
 />}
   </Grid>
      {/*clientData &&clientData.data.map((client:IClientData)=>(<Grid item xs={12} md={6} lg={6}>

<UserDataChart id={client.id}   />
      </Grid>))
      */}
         <Grid item xs={12} md={12} lg={12}>

        <UserList />
      </Grid>
  

  {/*<Grid item xs={12} md={6} lg={6}>

  <PromoSalesFlashStatsChart periods={periodsData} euroData={euroData2} volumeData={volumeData3} brandData={brandData} />
  </Grid>*/}
      </Grid>

    </DashboardLayout>
  );
};

export default DashboardDefault;
