import { Fragment, useCallback, useEffect, useState } from "react";
import {
  ContainerStyle,
} from "./AutoCompleteMultipleInput.style";
import { AutoCompleteMultipleInputPropsInterface } from "./AutoCompleteMultipleInput.type";
import { useTranslation } from "react-i18next";
import { SelectChangeEvent, TextField,FormControl,Autocomplete } from "@mui/material";

function AutoCompleteMultipleInput({
    value,
    error,
    multiple,
    onInputChange,
    freeSolo,
    renderTags,
    renderInput,
}: AutoCompleteMultipleInputPropsInterface) {
  // translation hooks
  const { t } = useTranslation();
  // check if select is open or not
  const [open, setOpen] = useState<boolean>(false);



  return (
    <FormControl fullWidth>
      <Autocomplete
       sx={{
        backgroundColor: 'white',
        
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor:  '#B269AE!important',
          },
        
        
        '&:hover ': {
          borderColor: 'white',
        },
        '&.Mui-focused ': {
          borderColor: '#B269AE!important',
          boxShadow: "0px 0px 6px 3px rgba(18, 63, 140, 0.3) !important",
          
        },
      },
      }}
        value={value}
        options={[]}
        multiple={multiple}
        onInputChange={onInputChange}
        freeSolo={freeSolo}
        renderTags={renderTags}
        renderInput={renderInput}

      /></FormControl>
     
  );
}
export default AutoCompleteMultipleInput;
