import { RouteIdEnum } from "config/enums";
import DashboardLayout from "layouts/layouts/DashboardLayout/DashboardLayout";
import PosTable from "./PosTable";

const Pos = () => {
  return (
    <DashboardLayout
      title={"pos"}
      selectedPath={RouteIdEnum.POS}
    >
        <PosTable/>
    </DashboardLayout>
  );
};

export default Pos;
