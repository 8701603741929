import { createApi } from "@reduxjs/toolkit/query/react";
import { ENDPOINTS } from "config/constant/endpoints.config";
import { IExpeditionCategoriesData } from "features/ExpeditionCategory/ExpeditionCategoryList/ExpeditionCategories.types";
import { baseQueryWithReauth } from "redux/api/rtkBaseQuery";
import { Paginator } from "types/interfaces";
import { injectParamsPaginator } from "utils/helpers/api-url";

export const expeditionCategoryApi = createApi({
    reducerPath: "ExpeditionCategoryApi",
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getExpeditionCategoryById: builder.query({
            query: (id: string) => ENDPOINTS.EXPEDITION_CATEG +"/"+ id,
          }),
          getAllExpeditionCategories: builder.query({
            query: (params: { paginator?: Paginator }) => {
              return injectParamsPaginator(ENDPOINTS.EXPEDITION_MODE, params.paginator);
            },
          }),
      getExpeditionCategory: builder.query({
        query: (params: { paginator?: Paginator,id:number }) => {
          return injectParamsPaginator(`${ENDPOINTS.EXPEDITION_CATEG}/${params.id}`, params.paginator);
        },
      }),
      deleteExpeditionCategory: builder.mutation({
        query: (id: number) => ({
          url: `${ENDPOINTS.EXPEDITION_CATEG}/${id}`,
          method: "DELETE",
        }),
      }),
      addExpeditionCategory: builder.mutation({
        query: ({
          category,
          price
         
        }: IExpeditionCategoriesData) => ({
          url: `${ENDPOINTS.EXPEDITION_CATEG}`,
          method: "POST",
          params: { category, price},
        }),
      }),
      updateExpeditionCategory: builder.mutation({
        query: ({
          id,
          category,
         price
         
        }: IExpeditionCategoriesData) => ({
          url: `${ENDPOINTS.EXPEDITION_CATEG}/${id}`,
          method: "POST",
          params: { category, price },
        }),
      }),
    }),
  });
  
  export const { useGetExpeditionCategoryQuery,useGetExpeditionCategoryByIdQuery,useAddExpeditionCategoryMutation,useDeleteExpeditionCategoryMutation,useUpdateExpeditionCategoryMutation,useGetAllExpeditionCategoriesQuery } = expeditionCategoryApi;
  