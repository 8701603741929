import { useTranslation } from "react-i18next";
import { ChangeEvent, useCallback, useState } from "react";
import Dialog from "components/common/Dialog/Dialog";
import Button from "components/common/Button/Button";
import {
  ContainerButtonStyle,
  DeleteButtonsStyle,
  InputStyle,
} from "./ExpeditionCategoryMode.style";
import { useAddPromotionCodeMutation } from "redux/api/promotionCodes/promotionCodes";
import {
  IExpeditionCategoryModeAddingData,
  IExpeditionCategoryModeAddingDataError,
  IExpeditionCategoriesModeDialog,
} from "./ExpeditionCategoryMode.types";
import { useSnackBar } from "hooks/useSnackBar";
import moment from "moment";
import TextInput from "components/common/TextInput/TextInput";
import useCategoryList from "features/Category/CategoryList/useCategoryList";

function AddExpeditonCategoryMode({
  openAddingDialog,
  setOpenAddingDialog,
  getCurrentCategory
}: IExpeditionCategoriesModeDialog) {
  const { t } = useTranslation();

  const { displaySuccessSnackBar, displayErrorApiSnackBar } = useSnackBar();
 
  const [addingCode, { data, error, isLoading, reset }] =
    useAddPromotionCodeMutation();

  if (error) {
    displayErrorApiSnackBar(error);
    reset();
  }
  if (data) {
    displaySuccessSnackBar(t("delivery.successfully_adding"));
    reset();
  }

  const closeAddingDialog = useCallback(() => {
    setOpenAddingDialog(false);
    setAddingData({ category: "", value: "" });
  }, [setOpenAddingDialog]);

  const onSuccessAddingDialog = (event: React.FormEvent) => {
    event.preventDefault()
    /*event.preventDefault();
    addingCode(addingData);
    closeAddingDialog();*/
    getCurrentCategory(addingData)
    closeAddingDialog()
  };

  const [addingData, setAddingData] = useState<IExpeditionCategoryModeAddingData>({
    category: "",
    value: ""
  });
  const [addingError, setAddingError] =
    useState<IExpeditionCategoryModeAddingDataError>({
        category: "",
        value: ""
    });
  const onChange = useCallback(
    (field: string) =>
      (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setAddingData({ ...addingData, [field]: e.target.value });

      },
    [addingData]
  );

  return (
    <Dialog
      open={openAddingDialog}
      handleClose={closeAddingDialog}
      title={t("delivery.add_cat")}
    >
      <form onSubmit={onSuccessAddingDialog}>
      <InputStyle>
          <TextInput
            value={`${addingData.category}`}
            label={t("delivery.category")}

            onChange={onChange("category")}
          />
        </InputStyle>
      <InputStyle>
          <TextInput
            value={addingData.value}
            label={t("delivery.value")}
            onChange={onChange("value")}
          />
        </InputStyle>
     
       
        <DeleteButtonsStyle>
          <ContainerButtonStyle>
            <Button
              disabled={isLoading}
              title={t("common.cancel_button")}
              onClick={closeAddingDialog}
            />
          </ContainerButtonStyle>
          <ContainerButtonStyle>
            <Button
              disabled={isLoading}
              type="submit"
              loader={isLoading}
              title={t("common.confirm_button")}
            />
          </ContainerButtonStyle>
        </DeleteButtonsStyle>
      </form>
    </Dialog>
  );
}
export default AddExpeditonCategoryMode;
