import { BadgePropsInterface } from "./StatusBadge.type";
import { BadgeStyle } from "./StatusBadge.style";

function StatusBadge({ isEnabled,color }: BadgePropsInterface) {
  return (
    <BadgeStyle
      color={color ? color : (isEnabled ? "success" : "error")}
      variant="dot"
    ></BadgeStyle>
  );
}
export default StatusBadge;
