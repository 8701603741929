import { InputsContainerPropsInterface } from "./InputsContainer.type";
import {
  ContainerStyle,
  DividerStyle,
  GlobalContainerStyle,
  TitleContainerStyle,
  TitleStyle,
} from "./InputsContainer.style";

function InputsContainer({
  title, // inputs title
  children, // children component
  fullContainer,
}: InputsContainerPropsInterface) {
  return (
    <GlobalContainerStyle isFullWidh={fullContainer}>
      <TitleContainerStyle>
        <TitleStyle>{title}</TitleStyle>
        <DividerStyle />
      </TitleContainerStyle>
      <ContainerStyle>{children}</ContainerStyle>
    </GlobalContainerStyle>
  );
}
export default InputsContainer;
