import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import Dialog from "components/common/Dialog/Dialog";
import Button from "components/common/Button/Button";
import {
  ContainerButtonStyle,
  DeleteButtonsStyle,
} from "./DeleteCategory.style";
import { useSnackBar } from "hooks/useSnackBar";
import { ICategoryDelete } from "./DeleteCategory.types";
import { useDeleteCategoryMutation } from "redux/api/category/category";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
function DeleteCategory({
  id,
  title,
  openDeleteDialog,
  setOpenDeleteDialog,
}: ICategoryDelete) {
  const { t } = useTranslation();

  const { displaySuccessSnackBar, displayErrorApiSnackBar } = useSnackBar();

  const [deleteCode, { data, error, isLoading, reset }] =
    useDeleteCategoryMutation();

  if (error) {
    displayErrorApiSnackBar(error);
  }
  if (data) {
    displaySuccessSnackBar(t("category.successfully_deleted"));
    reset();
  }

  const closeDeleteAlertDialog = useCallback(() => {
    setOpenDeleteDialog(false);
  }, [setOpenDeleteDialog]);

  const onSuccessDeleteAlert = (event: React.FormEvent) => {
    event.preventDefault();
    deleteCode(id);
    closeDeleteAlertDialog();
  };

  return (
    <Dialog
      open={openDeleteDialog}
      handleClose={closeDeleteAlertDialog}
      title={t("category.delete")}
      size={"xs"}
      
    >
      <div style={{textAlign: "center"}}> <ErrorOutlineIcon sx={{fontSize:"90PX",color:"#f54040"}}  ></ErrorOutlineIcon></div>
      
      <form onSubmit={onSuccessDeleteAlert} style={{textAlign: "center"}}>
        <p>
          {t("common.delete_message")}
          <span style={{ fontWeight: "bold", marginLeft: 5 }}>{title}</span>!<br/>
          <p ><span style={{ fontWeight: "bold", marginLeft: 5,color:"red" }}>Note: </span>{t("common.note")}</p>

        </p>
        <DeleteButtonsStyle>
          <ContainerButtonStyle>
            <Button
              disabled={isLoading}
              title={t("common.cancel_button")}
              onClick={closeDeleteAlertDialog}
            />
          </ContainerButtonStyle>
          <ContainerButtonStyle>
            <Button
              disabled={isLoading}
              type="submit"
              loader={isLoading}
              title={t("common.confirm_button")}
            />
          </ContainerButtonStyle>
        </DeleteButtonsStyle>
      </form>
    </Dialog>
  );
}
export default DeleteCategory;
