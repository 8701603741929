import PropTypes, { array } from 'prop-types';
import { useState, useEffect } from 'react';
import 'dayjs/locale/fr'; 
// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Chip, Grid, Stack, Typography,Button } from '@mui/material';

// third-party
import ReactApexChart from 'react-apexcharts';
import useProductList from 'features/Product/ProductList/useProductList';
import useCategoryList from 'features/Category/CategoryList/useCategoryList';
import useSubCategoryList from 'features/SubCategory/SubCategoryList/useSubCategoryList';
import { ICategoryData } from 'features/Category/CategoryList/Category.types';
import SelectInput from 'components/common/SelectInput/SelectInput';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { InputStyle } from 'components/common/TextInput/TextInput.style';
import { AutoInputsContainerStyle } from 'features/Product/ProductAdd/Product.style';
import { useTranslation } from 'react-i18next';
import { useGetProductStatisticsMutation } from 'redux/api/statistics/products';
import { useGetProductsByCategoryQuery } from 'redux/api/product/product';
import { useGetAllSubCategoriesQuery } from 'redux/api/category/category';
import { useGetOrderStatisticsMutation } from 'redux/api/statistics/orders';
import useSuppliersList from 'features/Supplier/SuppliersList/useSuppliersList';
import { useGetAllPromotionCodeStatisticsMutation, useGetPromotionCodeStatisticsMutation } from 'redux/api/statistics/promotionCodes';
import { ISuppliersAddingData } from 'features/Supplier/SupplierAdd/AddSupplier.types';
import usePromotionCodesList from 'features/PromotionCodes/PromotionCodesList/usePromotionCodesList';
import { IPromotionCodesAddingData } from 'features/PromotionCodes/PromotionCodeAdd/AddPromotionCode.types';
import { useGetAllPromotionCodesWithoutPaginationQuery } from 'redux/api/promotionCodes/promotionCodes';
import { useGetAllSuppliersWithoutPaginationQuery } from 'redux/api/suppliers/suppliers';
import { useGetAllProviderStatisticsMutation, useGetProviderStatisticsMutation } from 'redux/api/statistics/providers';
import { useGetAllExpeditionModeStatisticsMutation, useGetExpeditionModeStatisticsMutation } from 'redux/api/statistics/expeditionModes';
import { useGetAllExpeditionModeQuery } from 'redux/api/delivery/expeditionMode/expeditionMode';
// chart options
const areaChartOptions = {
  chart: {
    height: 450,
    type: 'area',
    toolbar: {
      show: false
    }
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    curve: 'smooth',
    width: 2
  },
  grid: {
    strokeDashArray: 0
  }
};

// ==============================|| INCOME AREA CHART ||============================== //

const ExpeditionModeChart = (props:{ slot:any }) => {
  const theme:any = useTheme();
  const [slot, setSlot] = useState('euro');

  const {
    data: expeditionMode,
    error,
    isLoading,
  } = useGetAllExpeditionModeQuery({
  });
  const { primary, secondary } = theme.palette.text;
  const line = theme.palette.divider;

  const [options, setOptions] = useState<any>(areaChartOptions);
  const [selectedCategory,setSelectedCategory]=useState(0);
  const [selectedSubCategory,setSelectedSubCategory]=useState(0);

  const [selectedCode,setSelectedCode]=useState(0)
  const [startDate,setStartData]=useState<Date>(new Date())
  const [endDate,setEndDate]=useState<Date>(new Date(new Date().setMinutes(new Date().getMinutes() + 1)))
  const [getStats,{data}]=useGetAllExpeditionModeStatisticsMutation()

  const [orderStats,setOrderStats]=useState()
  const [initialValue,setInitialValues]=useState<number[]>([])
  const [array,setArray]=useState();

 const [getStatsAllProducts,{data:dataProds}]=useGetExpeditionModeStatisticsMutation()
 const [labels,setLabels]=useState<string[]>([])
 const [data1,setData1]=useState([])
 const [data2,setData2]=useState([])


  const onChangeSelectCode=(e:any)=>{
    setSelectedCode(e)
       e!==0?  getStats({start_date:Math.floor(new Date(e).getTime() / 1000),
    end_date:Math.floor(endDate.getTime() / 1000)}):
    getStatsAllProducts({start_date:Math.floor(new Date(e).getTime() / 1000),
    end_date:Math.floor(endDate.getTime() / 1000)})
  }

  useEffect(()=>{
    getStatsAllProducts({})
  },[])
  
    useEffect(()=>{
      selectedCode!==0  ?getStats({start_date:Math.floor(new Date(startDate).getTime() / 1000),
       end_date:Math.floor(endDate.getTime() / 1000)}):
       startDate===new Date() && endDate===new Date(new Date().setMinutes(new Date().getMinutes() + 1))&& getStatsAllProducts({start_date:Math.floor(new Date(startDate).getTime() / 1000),
       end_date:Math.floor(endDate.getTime() / 1000)})
     },[startDate,endDate])
     function concatArrays(arrays:any) {
      return arrays.reduce((acc:any, curr:any) => acc.concat(curr), []);
  }
     useEffect(()=>{
       dataProds&&setLabels(Object.keys(dataProds).sort((a: string, b: string): number => {
         // Convert date strings to Date objects for comparison
         const dateA: Date = new Date(a);
         const dateB: Date = new Date(b);
         
         // Compare the dates
         return dateA.getTime() - dateB.getTime();
     }))},[dataProds])
 
     useEffect(()=>{
       console.log("dataProvider",data)
     },[data])
     useEffect(()=>{
       data&& selectedCode!==0&&data[selectedCode]&&setLabels(Object.keys(data[selectedCode]).sort((a: string, b: string): number => {
         // Convert date strings to Date objects for comparison
         const dateA: Date = new Date(a);
         const dateB: Date = new Date(b);
         
         // Compare the dates
         return dateA.getTime() - dateB.getTime();
     }))},[data,selectedCode])
  
    const {t}=useTranslation()
    const initArray=(length:number)=>{
      let arr=[];
      for(var i=0;i<length-1;i++){
      arr.push(0)
      }
      console.log("arr",arr)
      return arr;
    }
    let arr:any=[];

    useEffect(() => {
      data &&setInitialValues(initArray(Object.values(data)?.length))
     
      let arr:any[]
      setOptions((prevState:any) => ({
        ...prevState,
        colors: ["#93441A"],
        xaxis: {
          categories:labels,
          labels: {
            style: {
              colors: [
                secondary,
                secondary,
                secondary,
                secondary,
                secondary,
                secondary,
                secondary,
                secondary,
                secondary,
                secondary,
                secondary,
                secondary
              ]
            }
          },
          axisBorder: {
            show: true,
            color: line
          },
          tickAmount: slot === 'month' ? 11 : 7
        },
        yaxis: {
          labels: {
            style: {
              colors: [secondary]
            }
          }
        },
        grid: {
          borderColor: line
        },
        tooltip: {
          theme: 'light'
        }
      }));
    }, [primary, secondary, line, theme, slot,data,labels,selectedCode,dataProds,startDate,endDate]);
  
  
  const [series, setSeries] = useState<{name:string,data:number[]}[]>([
    {
      name: 'Utilisation',
      data: dataProds&&concatArrays(Object.values(dataProds)).map((data:any)=>data.total_shipping_price)
    },
    {
      name: 'Frais',
      data:dataProds&&concatArrays(Object.values(dataProds)).map((data:any)=>data.shipping_mode_uses)
    }
  ]);
  interface MyData {
    total_sales: number;
    total_ttc: number;
}

interface MyObject {
    [date: string]: MyData;
}
function sortObjectByDate(object: { [key: string]: any }): { [key: string]: any } {
  const keys = Object.values(object).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
  const sortedObject: { [key: string]: any } = {};
  
  keys.forEach(key => {
      sortedObject[key] = object[key];
  });
  console.log("sortedObject",sortedObject)
  return sortedObject;
}

  useEffect(() => {
const serie=slot=="euro"?{
    name: 'Utilisation',
    data: selectedCode===0 && dataProds?concatArrays(Object.values(dataProds)).map((stat:any)=>stat.total_shipping_price):data&&data[selectedCode]?Object.values(sortObjectByDate(data[selectedCode])).map((stat:any)=>stat?.total_shipping_price):initArray(9)
  }: {
    name:'Frais',
    data: selectedCode===0 && dataProds?concatArrays(Object.values(dataProds)).map((stat:any)=>stat.shipping_mode_uses):data&&data[selectedCode]?Object.values(sortObjectByDate(data[selectedCode])).map((stat:any)=>stat?.shipping_mode_uses):initArray(9)

  }
    setSeries([
      
     serie
  
    ]);
    console.log("orderStats",orderStats)
  },[slot,dataProds,selectedCode,data])
dayjs.locale("fr")
  return ( <Box
    sx={{
      boxShadow: 3,
      width: 'auto',
      height: 'auto',
      bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
      color: (theme) =>
        theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
      p: 1,
      m: 1,
      borderRadius: 2,
      fontSize: '0.875rem',
      fontWeight: '700',
    }}
  >
     <Grid item xs={12}>
            <Stack direction="row" alignItems="center" spacing={0}>
            <LocalizationProvider dateAdapter={AdapterDayjs} > 
  <DateTimePicker
    label={t("promotion_codes.start_date_title")}
    value={dayjs(startDate)}
    format="DD MMMM YYYY"  // Format utilisé pour l'affichage dans le sélecteur
    maxDateTime={dayjs(endDate)}
    onChange={(start: any) => {
      setStartData(new Date(start));
      selectedCode !== 0 ? 
        getStats({ start_date: Math.floor(new Date(start).getTime() / 1000), end_date: Math.floor(endDate.getTime() / 1000) }) :
        getStatsAllProducts({ start_date: Math.floor(new Date(start).getTime() / 1000), end_date: Math.floor(endDate.getTime() / 1000) });
    }}
  />

  <DateTimePicker
    label={t("promotion_codes.end_date_title")}
    value={dayjs(endDate).locale('fr')} 
    format="DD MMMM YYYY HH:mm" 
    onChange={(end: any) => {
      setEndDate(new Date(end));
      selectedCode !== 0 ? 
        getStats({ start_date: Math.floor(startDate.getTime() / 1000), end_date: Math.floor(new Date(end).getTime() / 1000) }) :
        getStatsAllProducts({ start_date: Math.floor(startDate.getTime() / 1000), end_date: Math.floor(new Date(end).getTime() / 1000) });
    }}
  />
</LocalizationProvider>
<Button
                size="small"
                onClick={() => setSlot('euro')}
                color={slot === 'euro' ? 'primary' : 'secondary'}
                variant={slot === 'euro' ? 'outlined' : 'text'}
              >
                Utilisation
              </Button>
              <Button
                size="small"
                onClick={() => setSlot('volume')}
                color={slot === 'volume' ? 'primary' : 'secondary'}
                variant={slot === 'volume' ? 'outlined' : 'text'}
              >
                Frais
              </Button>
</Stack>
<br/>
<Stack direction="row" alignItems="center" spacing={0}>


            {expeditionMode  && (
              <div style={{width:"100%"}}>
                <SelectInput
                  
                  none={true}
                  defaultValue={{id:0,name:"All"}}
                  label={"Mode d'expéditions"}
                  error={""}
                  value={selectedCode}
                  onChange={onChangeSelectCode}
                  data={expeditionMode?.data?.map((e:any) => ({
                    id: e.id,
                    name: e.title,
                  }))}
                />
                </div>
              )}
         
             
             
            </Stack>
     <Typography variant="h6" color="textSecondary">
     Historique des Modes d'expéditions
      </Typography>
  </Grid>
    
    <ReactApexChart options={options} series={series} type="area" height={450} />
    
    
    </Box>);
};

ExpeditionModeChart.propTypes = {
  slot: PropTypes.string
};

export default ExpeditionModeChart;
