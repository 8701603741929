import { createApi } from "@reduxjs/toolkit/query/react";
import { ENDPOINTS } from "config/constant/endpoints.config";
import { Paginator } from "types/interfaces";
import { injectParamsPaginator } from "utils/helpers/api-url";
import { baseQueryWithReauth } from "../rtkBaseQuery";

export const HolidaysApi = createApi({
  reducerPath: "HolidaysApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Holidays"],
  endpoints: (builder) => ({
    getHolidays: builder.query({
      query: () => {
        return injectParamsPaginator(ENDPOINTS.HOLIDAYS );
      },
      providesTags: ["Holidays"],
    }),
    createOrUpdateHolidays: builder.mutation<any, FormData>({
      query(data: any) {
        return {
          url: `${ENDPOINTS.HOLIDAYS}`,
          method: "POST",
          credentials: "include",
          body: data,
        };
      },
      invalidatesTags: ["Holidays"],
    }),
  }),
});

export const {
  useGetHolidaysQuery,
  useCreateOrUpdateHolidaysMutation,
} = HolidaysApi;
