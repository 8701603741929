import { SelectChangeEvent } from "@mui/material";
import { GlobalVariables } from "config/constant";
import { useSnackBar } from "hooks/useSnackBar";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useGetAllClientQuery,
  useUpdateClientStatusMutation,
} from "redux/api/client/client";

export default function useClientList() {
  const { t } = useTranslation();
  const { displaySuccessSnackBar, displayErrorApiSnackBar } = useSnackBar();
  const [search, setSearch] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(
    GlobalVariables.DefaultPerPage
  );
  const [orderBy, setOrderBy] = useState<string>("created_at");
  const [orderDir, setOrderDir] = useState<"" | "asc" | "desc">("desc");

  const {
    data: clientData,
    error,
    isLoading,
  } = useGetAllClientQuery({
    paginator: {
      search: search,
      page: page,
      perPage: perPage,
      orderBy: orderBy,
      orderDir: orderDir,
    },
  });

  if (error) {
    displayErrorApiSnackBar(error);
  }

  const onPageChange = (page: number) => {
    setPage(page);
  };

  const onSearchChange = (e: ChangeEvent<HTMLInputElement> | string) => {
    setPage(1);
    if (typeof e === "string") {
      setSearch(e);
    } else {
      setSearch(e.target.value);
    }
  };

  const onPerPageChange = (event: SelectChangeEvent<number>) => {
    setPerPage(parseInt(String(event.target.value), 10));
    setPage(1);
  };

  const onOrderChange = (field: string) => () => {
    const order_dir =
      orderBy === field
        ? orderDir === "asc"
          ? "desc"
          : orderDir === "desc"
          ? ""
          : "asc"
        : "asc";
    setOrderDir(order_dir);
    setOrderBy(order_dir === "" ? "" : field);
  };

  const [updateStatus, { data: dataUpdateStatus, error: errorUpdateStatus }] =
    useUpdateClientStatusMutation({});

  useEffect(() => {
    if (errorUpdateStatus) {
      displayErrorApiSnackBar(errorUpdateStatus);
    }

    if (dataUpdateStatus) {
      displaySuccessSnackBar(t("client.updateStatus"));
    }
  }, [
    dataUpdateStatus,
    displayErrorApiSnackBar,
    displaySuccessSnackBar,
    errorUpdateStatus,
    t,
  ]);

  const updateClientStatus = useCallback(
    (id: number) => () => {
      updateStatus(id);
    },
    [updateStatus]
  );

  return {
    onSearchChange,
    onPageChange,
    onPerPageChange,
    onOrderChange,
    clientData,
    isLoading,
    search,
    orderBy,
    orderDir,
    page,
    perPage,
    updateClientStatus,
  };
}
