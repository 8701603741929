import { useTranslation } from "react-i18next";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import Dialog from "components/common/Dialog/Dialog";
import Button from "components/common/Button/Button";
import {
  ContainerButtonStyle,
  DeleteButtonsStyle,
  InputStyle,
} from "./EditSupplier.style";
import { useUpdateSupplierMutation } from "redux/api/suppliers/suppliers";
import {
  ISuppliersDialog,
  ISuppliersEditingData,
  ISuppliersEditingDataError,
} from "./EditSupplier.types";
import { useSnackBar } from "hooks/useSnackBar";
import TextInput from "components/common/TextInput/TextInput";
import { ISuppliersAddingData } from "../SupplierAdd/AddSupplier.types";

function EditSupplier({
  id,
  codeInfos,
  openEditingDialog,
  setOpenEditingDialog,
}: ISuppliersDialog) {
  const { t } = useTranslation();

  const { displaySuccessSnackBar, displayErrorApiSnackBar } = useSnackBar();

  const [editingCode, { data, error, isLoading, reset }] =
    useUpdateSupplierMutation();

  if (error) {
    displayErrorApiSnackBar(error);
    reset();
  }
  if (data) {
    displaySuccessSnackBar(t("supplier.successfully_editing"));
    reset();
  }
  const isValidate = () => {
    let ok = true;
    let editingDataError = {
        id:0,
        company_name:"",
        address: "",
        postal_code: "",
        city: "",
        region:"",
        department:"",
        country:"",
        email:"" ,
        office_phone:"",
        mobile_phone:"",
        website:"",
        contact_last_name:"",
        contact_first_name:"",
        contact_position:"",
        contact_mobile:"",
        contact_email:"",
        contact_office_phone:""
    };
    const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
    const emailPattern = /^$|^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!editingData.company_name) {
      ok = false;
      editingDataError.company_name = t("supplier.company_name_required");
  } else {
      editingDataError.company_name = "";
  }

  if (!editingData.postal_code) {
      ok = false;
      editingDataError.postal_code = t("supplier.postal_code_required");
  } else {
      editingDataError.postal_code = "";
  }

  // Optional fields validation
  if (editingData.website && !urlPattern.test(editingData.website)) {
      ok = false;
      editingDataError.website = "URL invalide";
  } else {
      editingDataError.website = "";
  }

  if (editingData.email && !emailPattern.test(editingData.email)) {
      ok = false;
      editingDataError.email = "email invalide";
  } else {
      editingDataError.email = "";
  }

  // Set the updated error state
  setEditingError(editingDataError);
  return ok;
};
  const [editingData, setEditingData] = useState<ISuppliersEditingData>({
    company_name: codeInfos.company_name,
    address: codeInfos.address,
    postal_code:codeInfos.postal_code,
    city:codeInfos.city,
    region:codeInfos.region,
    department:codeInfos.department,
    country:codeInfos.country,
    email:codeInfos.email,
    office_phone:codeInfos.office_phone,
    mobile_phone:codeInfos.mobile_phone,
    website:codeInfos.website,
    contact_last_name:codeInfos.contact_last_name,
    contact_first_name:codeInfos.contact_first_name,
    contact_position:codeInfos.contact_position,
    contact_mobile:codeInfos.contact_mobile,
    contact_email:codeInfos.contact_email,
    contact_office_phone:codeInfos.contact_office_phone

  });
  const [EditingError, setEditingError] =
    useState<ISuppliersEditingDataError>({   id: 0,
      company_name:"",
      address: "",
      postal_code: "",
      city: "",
      region: "",
      department:"",
      country:"",
      email:"" ,
      office_phone:"",
      mobile_phone:"",
      website:"",
      contact_last_name:"",
  contact_first_name:"",
  contact_position:"",
  contact_mobile:"",
  contact_email:"",
  contact_office_phone:""
    });

  const onChange = useCallback(
    (field: string) =>
      (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setEditingData({ ...editingData, [field]: e.target.value });
        if(field=="postal_code"||field=="office_phone"||field=="mobile_phone"||field=="contact_mobile"||field=="contact_office_phone"){
          if(isNaN(parseInt(e.target.value))){
            console.log("Nan")
          setEditingData({ ...editingData, [field]: ""})
          }
        else
        setEditingData({ ...editingData, [field]: e.target.value})
        isValidate()
      }
    },
    [editingData,EditingError]
  );

  const closeEditingDialog = useCallback(() => {
    setOpenEditingDialog(false);
    setEditingData({
      company_name:"",
      address: "",
      postal_code: "",
      city: "",
      region: "",
      department:"",
      country:"",
      email:"" ,
      office_phone:"",
      mobile_phone:"",
      website:"",
      contact_last_name:"",
      contact_first_name:"",
      contact_position:"",
      contact_mobile:"",
      contact_email:"",
      contact_office_phone:""
    });
    setEditingError({   id: 0,
      company_name:"",
      address: "",
      postal_code: "",
      city: "",
      region: "",
      department:"",
      country:"",
      email:"" ,
      office_phone:"",
      mobile_phone:"",
      website:"",
      contact_last_name:"",
  contact_first_name:"",
  contact_position:"",
  contact_mobile:"",
  contact_email:"",
  contact_office_phone:""
    });
  }, [setOpenEditingDialog]);

  const onSuccessEditingDialog = (event: React.FormEvent) => {
    event.preventDefault();
    
    if (isValidate()) {
        // Ensure filteredData only includes fields that need to be submitted
        const filteredData: ISuppliersAddingData = {
            id: id,
            company_name: editingData.company_name,
            address: editingData.address || "",
            postal_code: editingData.postal_code || "",
            city: editingData.city || "",
            region: editingData.region || "",
            department: editingData.department || "",
            country: editingData.country || "",
            email: editingData.email || "", 
            office_phone: editingData.office_phone || "",
            mobile_phone: editingData.mobile_phone || "",
            website: editingData.website || "",
            contact_last_name: editingData.contact_last_name || "",
            contact_first_name: editingData.contact_first_name || "",
            contact_position: editingData.contact_position || "",
            contact_mobile: editingData.contact_mobile || "",
            contact_email: editingData.contact_email || "",
            contact_office_phone: editingData.contact_office_phone || ""
        };

        editingCode(filteredData); 
        closeEditingDialog();
    }
};


  useEffect(() => {
    if (openEditingDialog)
      setEditingData({
        company_name: codeInfos.company_name,
        address: codeInfos.address,
        postal_code:codeInfos.postal_code,
        city:codeInfos.city,
        region:codeInfos.region,
        department:codeInfos.department,
        country:codeInfos.country,
        email:codeInfos.email,
        office_phone:codeInfos.office_phone,
        mobile_phone:codeInfos.mobile_phone,
        website:codeInfos.website,
        contact_last_name:codeInfos.contact_last_name,
        contact_first_name:codeInfos.contact_first_name,
        contact_position:codeInfos.contact_position,
        contact_mobile:codeInfos.contact_mobile,
        contact_email:codeInfos.contact_email,
        contact_office_phone:codeInfos.contact_office_phone
      });
  }, [
    openEditingDialog,
    codeInfos.company_name,
    codeInfos.address,
   codeInfos.postal_code,
    codeInfos.city,
    codeInfos.region,
    codeInfos.department,
   codeInfos.country,
    codeInfos.email,
    codeInfos.office_phone,
    codeInfos.mobile_phone,
    codeInfos.website,
    codeInfos.contact_last_name,
    codeInfos.contact_first_name,
    codeInfos.contact_position,
    codeInfos.contact_mobile,
    codeInfos.contact_email,
    codeInfos.contact_office_phone,
    id,
  ]);

  return (
    <Dialog
      open={openEditingDialog}
      handleClose={closeEditingDialog}
      title={t("supplier.edit")}
    >
      <form onSubmit={onSuccessEditingDialog}>
        <InputStyle>
          <TextInput
            value={editingData.company_name}
            label={t("supplier.company_name")}
            onChange={onChange("company_name")}
            error={t(EditingError.company_name)}
          />
        </InputStyle>
        <InputStyle>
          <TextInput
            value={editingData.address}
            label={t("supplier.address")}
            onChange={onChange("address")}
            error={t(EditingError.address)}

          />
        </InputStyle>
        <InputStyle>
          <TextInput
            value={editingData.postal_code}
            label={t("supplier.postal_code")}
            onChange={onChange("postal_code")}
            error={t(EditingError.postal_code)}

          />
        </InputStyle>
        <InputStyle>
          <TextInput
            value={editingData.city}
            label={t("supplier.city")}
            onChange={onChange("city")}
            error={t(EditingError.city)}

          />
        </InputStyle>
        <InputStyle>
          <TextInput
            value={editingData.region}
            label={t("supplier.region")}
            onChange={onChange("region")}
            error={t(EditingError.region)}

          />
        </InputStyle>
        <InputStyle>
          <TextInput
            value={editingData.department}
            label={t("supplier.department")}
            onChange={onChange("department")}
            error={t(EditingError.department)}

          />
        </InputStyle>
        <InputStyle>
          <TextInput
            value={editingData.country}
            label={t("supplier.country")}
            onChange={onChange("country")}
            error={t(EditingError.country)}

          />
        </InputStyle>
        <InputStyle>
          <TextInput
            value={editingData.email}
            label={t("supplier.email")}
            onChange={onChange("email")}
            error={t(EditingError.email)}

          />
        </InputStyle>
        <InputStyle>
          <TextInput
            value={editingData.office_phone}
            label={t("supplier.office_phone")}
            onChange={onChange("office_phone")}
            error={t(EditingError.office_phone)}

          />
        </InputStyle>
        <InputStyle>
          <TextInput
            value={editingData.mobile_phone}
            label={t("supplier.mobile_phone")}
            onChange={onChange("mobile_phone")}
            error={t(EditingError.mobile_phone)}

          />
        </InputStyle>
        <InputStyle>
          <TextInput
            value={editingData.website}
            label={t("supplier.website")}
            onChange={onChange("website")}
            error={t(EditingError.website)}

          />
        </InputStyle>
        <InputStyle>
          <TextInput
            value={editingData.contact_last_name}
            label={t("supplier.contact_last_name")}
            onChange={onChange("contact_last_name")}
            error={t(EditingError.contact_last_name)}

          />
        </InputStyle>
        <InputStyle>
          <TextInput
            value={editingData.contact_first_name}
            label={t("supplier.contact_first_name")}
            onChange={onChange("contact_first_name")}
            error={t(EditingError.contact_first_name)}

          />
        </InputStyle>

        <InputStyle>
          <TextInput
            value={editingData.contact_position}
            label={t("supplier.contact_position")}
            onChange={onChange("contact_position")}
            error={t(EditingError.contact_position)}

          />
        </InputStyle>
        <InputStyle>
          <TextInput
            value={editingData.contact_mobile}
            label={t("supplier.contact_mobile")}
            onChange={onChange("contact_mobile")}
            error={t(EditingError.contact_mobile)}

          />
        </InputStyle>
        <InputStyle>
          <TextInput
            value={editingData.contact_email}
            label={t("supplier.contact_email")}
            onChange={onChange("contact_email")}
            error={t(EditingError.contact_email)}

          />
        </InputStyle>
        <InputStyle>
          <TextInput
            value={editingData.contact_office_phone}
            label={t("supplier.office_phone")}
            onChange={onChange("contact_office_phone")}
            error={t(EditingError.contact_office_phone)}

          />
        </InputStyle>
        <DeleteButtonsStyle>
          <ContainerButtonStyle>
            <Button
              disabled={isLoading}
              title={t("common.cancel_button")}
              onClick={closeEditingDialog}
            />
          </ContainerButtonStyle>
          <ContainerButtonStyle>
            <Button
              disabled={isLoading}
              type="submit"
              loader={isLoading}
              title={t("common.confirm_button")}
            />
          </ContainerButtonStyle>
        </DeleteButtonsStyle>
      </form>
    </Dialog>
  );
}
export default EditSupplier;
