import { IconButton, styled } from '@mui/material';
import Inventory2Icon from '@mui/icons-material/Inventory2';
export const ApplyCEButtonStyle = styled(IconButton)(({ theme }) => ({
    width: 35,
    height: 35,
    margin: '0px 4px',
    borderRadius: 18,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 5,
    paddingRight: 5,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '& p': {
      visibility: 'hidden',
    },
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      transition: theme.transitions.create('all', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      width: 'auto',
      '& p': {
        visibility: 'visible',
        transition: theme.transitions.create('visibility', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
          delay: theme.transitions.duration.enteringScreen / 2,
        }),
        paddingRight: 5,
      },
    },
    backgroundColor: theme.palette.primary.main,
  }));
  


export const ApplyCEIconStyle = styled(Inventory2Icon)(({ theme }) => ({
  color: 'white',
}));


export const ApplyCETextStyle = styled('span')(({ theme }) => ({
    fontSize: 14,
    color: 'white',
    margin: 0,
    padding: 0,
    marginLeft: 10,
    marginRight: 10,
}));
