import { Button, CircularProgress, styled } from "@mui/material";
import { ColorsConfig } from "config/constant/colors.config";

export const ButtonStyle = styled(Button)(({ theme }) => ({
  width: "100%",
  height: "auto",
  padding: 6,
  color: "white !important",
  backgroundColor: theme.palette.primary.main,
  "&:hover": {
    backgroundColor: theme.palette.primary.light,
  },
  "&.Mui-disabled": {
    backgroundColor: ColorsConfig.gray.dark,
  },
}));

export const CircularProgressStyle = styled(CircularProgress)(({ theme }) => ({
  height: "20px !important",
  width: "20px !important",
  color: "white",
}));
