import React, { useState,useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { styled } from '@mui/material/styles';
import {  Button, IconButton,  } from '@mui/material';
import { useCreateOrUpdateHolidaysMutation,useGetHolidaysQuery  } from "redux/api/Holidays/Holidays";
import { useSnackBar } from "hooks/useSnackBar";

interface CalendarTileProperties {
  date: Date;
  view: string;
}

const Container = styled('div')({
  display: 'flex',
  alignItems: 'stretch',
  flexWrap: "wrap",
  width: "100%",
});

const CalendarContainer = styled('div')({
  flex: "1 0 48%",
  marginRight: '10px', 
  overflow: "auto",
  width: "100%",

});
const DeleButton = styled('button')({
  float: "right",
  borderColor: "red",
  backgroundColor: "red",
  borderRadius: "50%",
  height: "25px",
  width: "25px",
  padding: 0,
  margin: "1px",
  textAlign: "center",
  lineHeight: 0,
  color: "white",
  fontSize: "large",
  fontWeight: "bold",
});
const ListContainer = styled('div')({
  flex: "1 0 48%",
  overflowY: 'auto', 

});
const ListItemContainer = styled('div')({
  
    overflowY: 'auto', 
    border:"1px solid #a0a096;",
    height: 284,
    padding: 14,
  });
const Card = styled('div')({
  border: '1px solid #ccc',
  borderRadius: '5px',
  padding: '10px',
  marginBottom: '10px',
  backgroundColor: 'white',
});
const StyledCalendar = styled(Calendar)(({ theme }) => ({
    '.holiday': {
      backgroundColor: 'red',
      color: 'white',
    },
  }));
const Holiday: React.FC = () => {
  const [holidayList, setHolidayList] = useState<string[]>([]);
  const { data:dbDAta, error:errorr, isLoading:load, isError:errorIS } = useGetHolidaysQuery("");
  const [createOrUpdateHolidays, { isLoading, isError }] = useCreateOrUpdateHolidaysMutation();
  
  useEffect(() => {
    if (dbDAta) {
      const holidayStrings = dbDAta.data.map((item: any) => item.date.toString());
      setHolidayList(holidayStrings);
    }
  }, [dbDAta]);
  const handleDateClick = (date: Date) => {
    const adjustedDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);

// Converting to ISO string and splitting to get the date part
const dateString = adjustedDate.toISOString().split('T')[0];
    if (holidayList.includes(dateString)) {
      setHolidayList(holidayList.filter(holiday => holiday !== dateString));
    } else {
      setHolidayList([...holidayList, dateString]);
    }
  };

  const handleDeleteHoliday = (date: string) => {
    setHolidayList(holidayList.filter(holiday => holiday !== date));
  };

  const tileDisabled = ({ date }: CalendarTileProperties): boolean => {
    const adjustedDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);

// Converting to ISO string and splitting to get the date part
const dateString = adjustedDate.toISOString().split('T')[0];
    return holidayList.includes(dateString);
  };

  const tileClassName = ({ date }: CalendarTileProperties): string | null => {
    const adjustedDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);

// Converting to ISO string and splitting to get the date part
const dateString = adjustedDate.toISOString().split('T')[0];
    return holidayList.includes(dateString) ? 'holiday' : null;
  };
  const { displaySuccessSnackBar, displayErrorApiSnackBar } = useSnackBar();

  const handleSubmit = (dataHoliday:string[]) => {
    const formData = new FormData();
   // Convertir en chaîne de caractères
   dataHoliday.forEach((day, index) => {
      formData.append(`day[${index}]`, day);
    });

    createOrUpdateHolidays(formData)
      .unwrap()
      .then((data) => {
        console.log('Holidays  created/updated successfully:', data);
        displaySuccessSnackBar("Jours fériés créés/mis à jour avec succès");
      })
      .catch((error) => {
        console.error('Error creating/updating Holidays :', error);
        displayErrorApiSnackBar(error);
      });
  };

  return (
    <Container>
      <CalendarContainer>
      <h4 style={{    margin: 0,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    fontSize: "2.125rem",
    lineHeight: 1.235,
    letterSpacing: "0.00735em",
    marginBottom: "0.35em",}}>Jours fériés</h4>
        <StyledCalendar
          onClickDay={(date) => handleDateClick(date)}
          tileDisabled={tileDisabled}
          tileClassName={tileClassName}
        />
      </CalendarContainer>
      <ListContainer>
        <h4 style={{    margin: 0,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    fontSize: "2.125rem",
    lineHeight: 1.235,
    letterSpacing: "0.00735em",
    marginBottom: "0.35em",}}>Liste des jours fériés:</h4>
        <ListItemContainer>
          {holidayList.map((holiday, index) => (
            <Card key={index}>
              {holiday}
              <DeleButton onClick={() => handleDeleteHoliday(holiday)}>X</DeleButton>
            </Card>
          ))}
        </ListItemContainer>
        <Button variant="contained" color="primary" onClick={() => handleSubmit(holidayList)} style={{ marginTop: 16, float: "right" }}>
        Enregistrer les jours fériés
      </Button>
      </ListContainer>
      
    </Container>
  );
};

export default Holiday;
