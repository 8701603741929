import { SelectChangeEvent } from "@mui/material";
import { GlobalVariables } from "config/constant";
import { useSnackBar } from "hooks/useSnackBar";
import { ChangeEvent, useState } from "react";
import { useGetAllBrandsWithoutPaginationQuery, useGetAllBrandQuery } from "redux/api/brand/brand";
import { useGetAllCategoryQuery } from "redux/api/category/category";

export default function useBrandList() {
  const { displaySuccessSnackBar, displayErrorApiSnackBar } = useSnackBar();
  const [search, setSearch] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(
    GlobalVariables.DefaultPerPage
  );
  const [orderBy, setOrderBy] = useState<string>("created_at");
  const [orderDir, setOrderDir] = useState<"" | "asc" | "desc">("desc");

  const {
    data: brandData,
    error,
    isLoading,
  } = useGetAllBrandQuery({
    paginator: {
      search: search,
      page: page,
      perPage: perPage,
      orderBy: orderBy,
      orderDir: orderDir,
    },
  });
  const {
    data: allBrandData,
  } = useGetAllBrandsWithoutPaginationQuery({
    paginator: {
      search: search,
      orderBy: orderBy,
      orderDir: orderDir,
    },
  });
  if (error) {
    displayErrorApiSnackBar(error);
  }

  const onPageChange = (page: number) => {
    setPage(page);
  };

  const onSearchChange = (e: ChangeEvent<HTMLInputElement> | string) => {
    setPage(1);
    if (typeof e === "string") {
      setSearch(e);
    } else {
      setSearch(e.target.value);
    }
  };

  const onPerPageChange = (event: SelectChangeEvent<number>) => {
    setPerPage(parseInt(String(event.target.value), 10));
    setPage(1);
  };

  const onOrderChange = (field: string) => () => {
    const order_dir =
      orderBy === field
        ? orderDir === "asc"
          ? "desc"
          : orderDir === "desc"
          ? ""
          : "asc"
        : "asc";
    setOrderDir(order_dir);
    setOrderBy(order_dir === "" ? "" : field);
  };

  return {
    onSearchChange,
    onPageChange,
    onPerPageChange,
    onOrderChange,
    brandData,
    allBrandData,
    isLoading,
    search,
    orderBy,
    orderDir,
    page,
    perPage,
  };
}
