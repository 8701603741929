import { Box, Typography } from "@mui/material"
import { IClientData } from "features/Client/ClientList/Client.types"
import useClientList from "features/Client/ClientList/useClientList"
import Table from "components/common/Table/Table"
import { RouteIdEnum } from "config/enums";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import { useTranslation } from "react-i18next";
import { Grid, IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import SearchInput from "components/common/SearchInput/SearchInput";
import StatusBadge from "components/common/StatusBadge/StatusBadge";
import { ActionContainerStyle } from "../../features/Client/ClientList/Client.style";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { RemoveRedEyeRounded } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
const UserList=()=>{
  const {
    onSearchChange,
    onPageChange,
    onPerPageChange,
    onOrderChange,
    clientData,
    isLoading,
    search,
    orderBy,
    orderDir,
    page,
    perPage,
    updateClientStatus,
  } = useClientList();    
  const {t}=useTranslation()
  const navigate=useNavigate();
  const location=useLocation()
  console.log("location pathname",location.pathname)
    return(
      
        <Box
        sx={{
          boxShadow: 3,
          width: 'auto',
          height: 'auto',
          bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
          color: (theme) =>
            theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
          p: 1,
          m: 1,
          borderRadius: 2,
          fontSize: '0.875rem',
          fontWeight: '700',
        }}
      >
                <Grid item xs={12}>
             <Grid item style={{float: 'right'}}>
              </Grid>
              <Typography variant="h6" style={{textAlign:"center"}} color="textSecondary">
      Liste des utlisateurs</Typography>
              </Grid>
              <Table
        loader={isLoading}
        headerData={[
          {
            key: "first_name",
            title: t("client.first_name"),
            onClick: onOrderChange("first_name"),
            asc: orderBy === "first_name" ? orderDir === "asc" : undefined,
          },
          {
            key: "last_name",
            title: t("client.last_name"),
            onClick: onOrderChange("last_name"),
            asc: orderBy === "last_name" ? orderDir === "asc" : undefined,
          },
          {
            key: "email",
            title: t("client.email"),
            onClick: onOrderChange("email"),
            asc: orderBy === "email" ? orderDir === "asc" : undefined,
          },
          {
            key: "country",
            title: t("client.country"),
          },
          {
            key: "city",
            title: t("client.city"),
          },
        
          { key: "action", title: t("common.actions") },
        ]}
        bodyData={clientData?.data.map((e: {id:number,first_name:string,last_name:string,email:string,country:string,
        city:string}) => {
          return {
            first_name: <p>{e.first_name}</p>,
            last_name: <p>{e.last_name}</p>,
            email: <p>{e.email}</p>,
            country: <p>{e.country}</p>,
            city: <p>{e.city}</p>,
           
            action: (
              <ActionContainerStyle>
                {
                  <Tooltip
                    title={
                     "Afficher Statistiques utilisateur"
                    }
                  >
                    <IconButton
                      color="primary"
                      component="span"
                      onClick={()=>{
                          navigate(location.pathname=="/"?`Tableau de bord/stat/user/${e.id}`:`${location.pathname}/stat/user/${e.id}`)
                      }}
                    > 
                    <RemoveRedEyeRounded/>
                     
                    </IconButton>
                  </Tooltip>
                }
              </ActionContainerStyle>
            ),
          };
        })}
        paginationData={{
          page: page,
          lastPage: clientData?.last_page ?? 1,
          perPage: perPage,
          onPageChange: (event, value) => onPageChange(value),
          onPerPageChange: (e: any) => onPerPageChange(e),
        }}
      />   
              </Box>

    )
    
}
export default UserList