import { Card } from "components/common/Card/Card"
import { RouteIdEnum } from "config/enums"
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout"
import React from "react"
import { useTranslation } from "react-i18next"
import dataJson  from "./response.json"
 const DashboardStatsVisits=()=>{
  const {t}=useTranslation()

      return(
        <DashboardLayout
        title={t("dashboard.dashboard_title")}
        selectedPath={RouteIdEnum.Stats_Visit}
      >
      <div style={{width:"100%"}}>
          <Card title="Temps passé">
            <h5 style={{fontWeight:"bold",color:"black"}}>Temps passé moyen</h5>
            <p style={{color:"gray"}}>{dataJson.data["Temps moyen"]}</p>
            <h5 style={{fontWeight:"bold",color:"black"}}>Temps total passé</h5>
            <p style={{color:"gray"}}>{dataJson.data["Temps total"]}</p>
          </Card>
          <Card title="Type des utilisateurs">
            <div style={{padding:"3px",overflowX:"scroll"}}>
                 <table  style={{borderCollapse: 'collapse',
      width: '100%'}}>
                  <thead>
                    <tr>
                      <th style={{   border: '1px solid black',
      padding: '8px',
      textAlign: 'left'}}>Type d'utilisateur</th>
                      <th style={{   border: '1px solid black',
      padding: '8px',
      textAlign: 'left'}}>Session des utilisateurs</th>
                    </tr>
                  </thead>
                 {dataJson.data.UserTypes.map((item,index)=>( <tr>
                      <td style={{   border: '1px solid black',
      padding: '8px',
      textAlign: 'left'}}>{item.newVsReturning}</td>
                      <td style={{   border: '1px solid black',
      padding: '8px',
      textAlign: 'left'}}>{item.activeUsers}</td>
                    </tr>))}
                 </table>
            </div>
          </Card>
          <Card title="Utilisateurs">
            <div style={{padding:"3px",overflowX:"scroll"}}>
                 <table  style={{borderCollapse: 'collapse',
      width: '100%'}}>
                  <thead>
                    <tr>
                      <th style={{   border: '1px solid black',
      padding: '8px',
      textAlign: 'left'}}>Impression</th>
                      <th style={{   border: '1px solid black',
      padding: '8px',
      textAlign: 'left'}}>Session des utilisateurs</th>
                    </tr>
                  </thead>
                 {dataJson.data.VisitorsAndPageViews.map((item,index)=>( <tr>
                      <td style={{   border: '1px solid black',
      padding: '8px',
      textAlign: 'left'}}>{item.activeUsers}</td>
                      <td style={{   border: '1px solid black',
      padding: '8px',
      textAlign: 'left'}}>{item.screenPageViews}</td>
                    </tr>))}
                 </table>
            </div>
          </Card>
          <br/>
          <Card title="Pages visitées">
            <div style={{padding:"3px",overflowX:"scroll"}}>
            <table  style={{borderCollapse: 'collapse',
      width: '100%'}}>
                  <thead>
                    <tr>
                      <th style={{   border: '1px solid black',
      padding: '8px',
      textAlign: 'left'}}>Lien de la page</th>
                      <th style={{   border: '1px solid black',
      padding: '8px',
      textAlign: 'left'}}>Pages affichées</th>
                    </tr>
                  </thead>
                 {dataJson.data.VisitorsAndPageViews.map((item,index)=>( <tr>
                      <td style={{   border: '1px solid black',
      padding: '8px',
      textAlign: 'left'}}><a style={{cursor:"pointer",textDecoration:"underline"}}>{item.pageTitle}</a></td>
                      <td style={{   border: '1px solid black',
      padding: '8px',
      textAlign: 'left'}}>{item.screenPageViews}</td>
                    </tr>))}
               
                 </table>
            </div>
          </Card>
          <Card title="Total des visites">
            <div style={{padding:"3px",overflowX:"scroll"}}>
            <table style={{borderCollapse: 'collapse',
      width: '100%'}}>
                  <thead>
                    <tr>
                      <th  style={{   border: '1px solid black',
      padding: '8px',
      textAlign: 'left'}}>Date</th>
                      <th style={{   border: '1px solid black',
      padding: '8px',
      textAlign: 'left'}}>Pages affichées</th>
           <th style={{   border: '1px solid black',
      padding: '8px',
      textAlign: 'left'}}>Session des utilisateurs</th>
                    </tr>
                  </thead>
                {dataJson.data.TotalVisitorsAndPageViews.map((item,index)=>(  <tr>
                      <td style={{   border: '1px solid black',
      padding: '8px',
      textAlign: 'left'}}>{item.date}</td>
                      <td style={{   border: '1px solid black',
      padding: '8px',
      textAlign: 'left'}}>{item.screenPageViews}</td>
       <td style={{   border: '1px solid black',
      padding: '8px',
      textAlign: 'left'}}>{item.activeUsers}</td>
                    </tr>))}
                
                 </table>
            </div>
          </Card>
      </ div>
      </DashboardLayout>)
}
export default DashboardStatsVisits