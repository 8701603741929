import { useTranslation } from "react-i18next";
import "../assets/sass/404.scss";
import { styled } from "@mui/material";

const NotFoundPage = () => {
  const { t } = useTranslation();

  const LogoStyle = styled("img")(({ theme }) => ({
    height: 200,
    width: 200,
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
  }));

  const LogoContainerStyle = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
  }));

  return (
    <>
      <LogoContainerStyle>
        <LogoStyle src="logo.png" />
      </LogoContainerStyle>

      <section className="error-container">
        <span className="four">
          <span className="screen-reader-text">4</span>
        </span>
        <span className="zero">
          <span className="screen-reader-text">0</span>
        </span>
        <span className="four">
          <span className="screen-reader-text">4</span>
        </span>
      </section>
      <div className="link-container">
        <a href="/" className="more-link">
          {t("common.back_home")}
        </a>
      </div>
    </>
  );
};

export default NotFoundPage;
