export interface CustomAppBarPropsInterface {
  drawer: boolean;
  handleDrawerStatus: () => void;
  handleDialogChangePassword: () => void;
  title: string;
  backPath?: string;
}

export type CustomAppBarProps = {
  isappbarclassshift: boolean;
};

export const APP_BAR_HEIGHT = 50;
