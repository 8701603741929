import { AlertColor } from "@mui/material";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GlobalVariables } from "config/constant";
export interface SnackBarState {
  open?: boolean;
  type?: AlertColor;
  message?: string;
  timeout?: number | null;
}
export const snackBarInitialState: SnackBarState = {
  open: false,
  type: "info",
  message: "",
  timeout: GlobalVariables.SNACK_BAR_TIME_OUT,
};
export const SnackBarSlice = createSlice({
  name: "snackBar",
  initialState: snackBarInitialState,
  reducers: {
    showSnackBar: (_state, action: PayloadAction<SnackBarState>) => ({
      ...snackBarInitialState,
      ...action.payload,
      open: true,
    }),
    clearSnackBar: (state) => ({ ...state, open: false }),
  },
});
export const SnackBarActions = SnackBarSlice.actions;
export const SnackBarReducer = SnackBarSlice.reducer;
