import { createApi } from "@reduxjs/toolkit/query/react";
import { ENDPOINTS } from "config/constant/endpoints.config";
import { Paginator } from "types/interfaces";
import { injectParamsPaginator } from "utils/helpers/api-url";
import { baseQueryWithReauth } from "../rtkBaseQuery";

export const categoryApi:any = createApi({
  reducerPath: "CategoryApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Category"],
  endpoints: (builder) => ({
    getAllCategory: builder.query({
      query: (params: { paginator?: Paginator,all?:boolean }) => {
        return injectParamsPaginator(ENDPOINTS.CATEGORY, params.paginator,params.all);
      },
      providesTags: ["Category"],
    }),
    getCategory: builder.query({
      query: (id: number) => ({
        url: `${ENDPOINTS.CATEGORY}/${id}`,
        method: "GET",
      }),
    }),
    getAllSubCategories: builder.query({
      query: (params: { paginator?: Paginator ,id:number,all?:boolean}) => {
        return injectParamsPaginator(`${ENDPOINTS.CATEGORY}/subcategories/${params.id}`, params.paginator,params.all);
      },
      providesTags: ["Category"],
    }),
    deleteCategory: builder.mutation({
      query: (id: number) => ({
        url: `${ENDPOINTS.CATEGORY}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Category"],
    }),
    deleteMultipleCategories: builder.mutation({
      query: (params: { categories_ids: number[], select_all: boolean ,search:string}) => ({
        url: `${ENDPOINTS.CATEGORY}/delete-multiple`,
        method: "POST",
        body: {
          categories_ids: params.categories_ids,
          select_all: params.select_all,
          search:params.search
        },
      }),
      invalidatesTags: ["Category"],
    }),
    deleteMultipleSubCategories: builder.mutation({
      query: (params: { categories_ids: number[], select_all: boolean ,search:string}) => ({
        url: `${ENDPOINTS.CATEGORY}/delete-multiple`,
        method: "POST",
        body: {
          categories_ids: params.categories_ids,
          select_all: params.select_all,
          search:params.search
        },
      }),
      invalidatesTags: ["Category"],
    }),
    addCategory: builder.mutation<{}, FormData>({
      query(formData: FormData) {
        return {
          url: `${ENDPOINTS.CATEGORY}`,
          method: "POST",
          credentials: "include",
          body: formData,
        };
      },
      invalidatesTags: ["Category"],
    }),
    updateCategory: builder.mutation({
      query({ id, formData }) {
        return {
          url: `${ENDPOINTS.CATEGORY}/${id}`,
          method: "POST",
          credentials: "include",
          body: formData,
        };
      },
      invalidatesTags: ['Category'],


    }),
    getSubCategoryById:builder.query({
      query: (params: { paginator?: Paginator,id:number }) => {
        return injectParamsPaginator(`${ENDPOINTS.CATEGORY}/${params.id}`, params.paginator);
      },
      }),
    getSubCategories: builder.query({
      query: (params: { paginator?: Paginator,id:number }) => {
        return injectParamsPaginator(`category/${params.id}`, params.paginator);
      },
      providesTags: ["Category"],
    }),

    addSubCategory: builder.mutation({
      query: ({ id, formData }) => {return{
        url: `${ENDPOINTS.CATEGORY}`,
        method: "POST",
        body:formData
      }
    },
    invalidatesTags: ['Category'],
}
    )
  })
})







export const {
  useGetAllCategoryQuery,
  useGetAllSubCategoriesQuery,
  useAddCategoryMutation,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
  useGetSubCategoriesQuery,
  useAddSubCategoryMutation,
  useGetSubCategoryByIdQuery,
  useDeleteMultipleCategoriesMutation,
  useDeleteMultipleSubCategoriesMutation,
  useGetCategoryQuery
} = categoryApi;
