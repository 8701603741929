import { common } from "./fr/common";
import { login } from "./fr/login";
import { forgot_password } from "./fr/forgotPassword";
import { menu } from "./fr/menu";
import { change_password } from "./fr/changePassword";
import { promotion_codes } from "./fr/codePromotion";
import { errors } from "./fr/errors";
import { category } from "./fr/category";
import { product } from "./fr/product";
import { client } from "./fr/client";
import { order } from "./fr/order";
import { supplier } from "./fr/supplier";
import {delivery}    from "./fr/delivery"
import { tax } from "./fr/tax";
import { promo_flash } from "./fr/promotion_flash";
import {dashboard} from "./fr/dashboard"
import {brand} from"./fr/brand"
const fr = {
  common,
  login,
  menu,
  errors,
  forgot_password,
  change_password,
  promotion_codes,
  category,
  product,
  client,
  order,
  supplier,
  delivery,
  tax,
  promo_flash,
  dashboard,
  brand
  
};

export default fr;
