import {
  AddActionTitleStyle,
  AddButtonStyle,
  AddIconStyle,
} from "./AddButton.style";
import { AddButtonPropsInterface } from "./AddButton.type";
import { useTranslation } from "react-i18next";

function AddButton({ onAddClick, title, className }: AddButtonPropsInterface) {
  // translation hooks
  const { t } = useTranslation();
  return (
    <AddButtonStyle onClick={onAddClick} className={className}>
      <AddIconStyle />
      <AddActionTitleStyle>{t(title)}</AddActionTitleStyle>
    </AddButtonStyle>
  );
}
export default AddButton;
