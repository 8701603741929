import Table from "components/common/Table/Table";
import { RouteIdEnum } from "config/enums";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import { useTranslation } from "react-i18next";
import { IOrderHistoryData, IProductOrderData, IUserOrderData } from "./Order.types";
import { Grid, IconButton, MenuList, MenuItem, ListItemText } from "@mui/material";
import moment from "moment";
import SearchInput from "components/common/SearchInput/SearchInput";
import useOrderList from "./useOrderList";
import SelectInput from "components/common/SelectInput/SelectInput";
import { useEffect, useState } from "react";
import ShowClient from "features/Client/ShowClient/ShowClient";
import { useUpdateOrderStatusMutation } from "redux/api/order/order";
import { useSnackBar } from "hooks/useSnackBar";
import { LiIconExpandLessStyle, LiIconExpandMoreStyle } from "components/Dashboard/Menu/Menu.style"
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import React from "react";
import MenuItems from "components/common/Menu/Menu";
import OrdersTable from "UpdatedFeatures/Order Histories/OrdersTable";
function Order() {
  const { t } = useTranslation();

  return (
    <DashboardLayout
      title={t("order.page_title")}
      selectedPath={RouteIdEnum.OrderHistory}
    >
      <OrdersTable />
    </DashboardLayout>
  );
}
export default Order;
