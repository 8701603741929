import { RouteIdEnum } from 'config/enums'
import DashboardLayout from 'layouts/layouts/DashboardLayout/DashboardLayout'
import VendeursTable from './VendeursTable'

const Vendeurs = () => {
  return (
    <DashboardLayout
    title={"Vendeurs"}
    selectedPath={RouteIdEnum.Vendeurs}
  >
    <VendeursTable/>
  </DashboardLayout>
  )
}

export default Vendeurs