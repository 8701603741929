import { CssBaseline } from "@mui/material";
import AppBar from "components/Dashboard/AppBar/AppBar";
import Drawer from "components/Dashboard/Drawer/Drawer";
import Menu from "components/Dashboard/Menu/Menu";
import Button from "components/common/Button/Button";
import Dialog from "components/common/Dialog/Dialog";
import TextInput from "components/common/TextInput/TextInput";
import { useState, useCallback, useEffect, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { MIN_SIZE_SCREEN } from "utils";
import { DashboardPropsInterface } from "./Dashboard.type";
import {
  ChildrenStyle,
  InputStyle,
  MainContentShiftStyle,
  MainContentStyle,
  SendStyle,
  ToolbarStyle,
} from "./Dashboard.style";
import { useUpdatePasswordMutation } from "redux/api/auth/auth";
import { useSnackBar } from "hooks/useSnackBar";

export default function DashboardLayout({
  children, // content
  selectedPath, // selected menu Path
  title, // selected screen title
  backPath, // backPath
}: DashboardPropsInterface) {
  const { displaySuccessSnackBar, displayErrorApiSnackBar } = useSnackBar();

  const [
    updatePassword,
    { data: dataUpdatePassword, error: errorUpdatePassword },
  ] = useUpdatePasswordMutation();

  const [data, setData] = useState<{
    current_password: string;
    new_password: string;
    new_password_confirmation: string;
  }>({
    current_password: "",
    new_password: "",
    new_password_confirmation: "",
  });

  const [error, setError] = useState<{
    current_password: string;
    new_password: string;
    new_password_confirmation: string;
  }>({
    current_password: "",
    new_password: "",
    new_password_confirmation: "",
  });

  const onChange = useCallback(
    (field: string) =>
      (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setData({ ...data, [field]: e.target.value });
        setError({
          current_password: "",
          new_password: "",
          new_password_confirmation: "",
        });
      },
    [data]
  );

  // translation hooks
  const { t } = useTranslation();
  //verify screen size
  const handleResize = () => {
    if (window.innerWidth < MIN_SIZE_SCREEN) {
      return false;
    } else {
      return true;
    }
  };

  // drawer is open state
  const [drawer, setDrawer] = useState<boolean>(handleResize);
  // handle close drawer
  const handleDrawerClose = useCallback(() => {
    setDrawer(false);
  }, [setDrawer]);
  // handle open status
  const handleDrawerStatus = useCallback(() => {
    setDrawer((prev) => !prev);
  }, [setDrawer]);

  // dialog change password is false state
  const [dialogChangePassword, setDialogChangePassword] =
    useState<boolean>(false);
  // handle  dialog change password
  const openDialogChangePassword = useCallback(() => {
    setDialogChangePassword(true);
  }, [setDialogChangePassword]);

  // close  dialog change password
  // handle  dialog change password
  const resetData = useCallback(() => {
    setData({
      current_password: "",
      new_password: "",
      new_password_confirmation: "",
    });
  }, []);

  const closeDialogChangePassword = useCallback(() => {
    setDialogChangePassword(false);
    resetData();
  }, [setDialogChangePassword, resetData]);

  useEffect(() => {
    document.title = `Kdmarche-admin-${title
      .replaceAll(" ", "-")
      .toLowerCase()}`;
  }, [title]);

  const isValidate = () => {
    let error = {
      current_password: "",
      new_password: "",
      new_password_confirmation: "",
    };
    let ok = true;
    if (data.current_password === "") {
      ok = false;
      error.current_password = t("errors.CURRENT_PASSWORD_REQUIRED");
    }
    if (data.new_password === "") {
      ok = false;
      error.new_password = t("errors.NEW_PASSWORD_REQUIRED");
    }
    if (data.new_password_confirmation === "") {
      ok = false;
      error.new_password_confirmation = t(
        "errors.NEW_PASSWORD_CONFIRMED_REQUIRED"
      );
    }
    if (!ok) {
      setError(error);
      return false;
    } else {
      return true;
    }
  };

  const onSubmitChangePassword = (event: React.FormEvent) => {
    event.preventDefault();
    if (isValidate())
      updatePassword({
        current_password: data.current_password,
        new_password: data.new_password,
        new_password_confirmation: data.new_password_confirmation,
      });
  };

  useEffect(() => {
    if (errorUpdatePassword) {
      displayErrorApiSnackBar(errorUpdatePassword);
    }
  }, [errorUpdatePassword]);

  useEffect(() => {
    if (dataUpdatePassword) {
      displaySuccessSnackBar(t("login.successfully_update_password"));
      closeDialogChangePassword();
    }
  }, [dataUpdatePassword]);

  return (
    <div>
      <CssBaseline />
      <AppBar
        drawer={drawer}
        handleDrawerStatus={handleDrawerStatus}
        handleDialogChangePassword={openDialogChangePassword}
        title={t(title)}
        backPath={backPath}
      />
      <Drawer drawer={drawer} handleDrawerClose={handleDrawerClose}>
        <Menu handleDrawerClose={handleDrawerClose} menuPath={selectedPath} />
      </Drawer>
      {drawer ? (
        <MainContentShiftStyle>
          <ToolbarStyle />
          <ChildrenStyle>{children}</ChildrenStyle>
        </MainContentShiftStyle>
      ) : (
        <MainContentStyle>
          <ToolbarStyle />
          <ChildrenStyle>{children}</ChildrenStyle>
        </MainContentStyle>
      )}

      {/* Dialog Change Password */}
      <Dialog
        open={dialogChangePassword}
        handleClose={closeDialogChangePassword}
        title={t("change_password.title")}
      >
        <form onSubmit={onSubmitChangePassword}>
          <InputStyle>
            <TextInput
              value={data.current_password}
              label={t("change_password.previous")}
              type="password"
              onChange={onChange("current_password")}
              error={t(error.current_password)}
            />
          </InputStyle>

          <InputStyle>
            <TextInput
              value={data.new_password}
              label={t("change_password.new")}
              type="password"
              onChange={onChange("new_password")}
              error={t(error.new_password)}
            />
          </InputStyle>

          <InputStyle>
            <TextInput
              value={data.new_password_confirmation}
              label={t("change_password.confirm")}
              type="password"
              onChange={onChange("new_password_confirmation")}
              error={t(error.new_password_confirmation)}
            />
          </InputStyle>

          <SendStyle>
            <Button
              disabled={false}
              type="submit"
              loader={false}
              title={t("change_password.update")}
            />
          </SendStyle>
        </form>
      </Dialog>
    </div>
  );
}
