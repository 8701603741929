import { createApi } from "@reduxjs/toolkit/query/react";
import { ENDPOINTS } from "config/constant/endpoints.config";
import { Paginator } from "types/interfaces";
import { injectParamsPaginator } from "utils/helpers/api-url";
import { baseQueryWithReauth } from "../rtkBaseQuery";

export const statisticsProviderApi = createApi({
  reducerPath: "statisticsProviderApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["StatisticProvider"],
  endpoints: (builder) => ({
    getProviderStatistics: builder.mutation({
      query: ({
        start_date,
        end_date
      }: {start_date?:number,end_date?:number}) => ({
        url: `/stats/providers`,
        method: "POST",
        body:start_date && end_date? {  start_date,
            end_date,
           }:{},
      }),
      invalidatesTags: ["StatisticProvider"],
    }),
    getAllProviderStatistics: builder.mutation({
      query: ({
        start_date,
        end_date
      }: {start_date?:number,end_date?:number}) => ({
        url: `/stats/allproviders`,
        method: "POST",
        body:start_date && end_date? {  start_date,
            end_date,
           }:{},
      }),
      invalidatesTags: ["StatisticProvider"],
    }),
    
  })
})

export const { useGetProviderStatisticsMutation,useGetAllProviderStatisticsMutation} =statisticsProviderApi;
