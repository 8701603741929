import { styled } from "@mui/material";
import { InputsContainerProps } from "./InputsContainer.type";

export const GlobalContainerStyle = styled("div")<InputsContainerProps>(
  ({ theme, isFullWidh }) => ({
    display: "flex",
    alignItems: "start",
    flexDirection: "column",
    marginBottom: -25,
    marginTop: -10,
    width: isFullWidh ? "-webkit-fill-available" : "100%",
    minWidth: isFullWidh ? "-moz-available" : "",
  })
);

export const TitleContainerStyle = styled("div")(({ theme }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  maxWidth: 420,
  height: 52,
  left: "auto",
  right: "auto",
  paddingRight: 20,
  paddingLeft: 20,
  zIndex: 1,
}));

export const TitleStyle = styled("h1")(({ theme }) => ({
  fontFamily: '"Lato, Regular"',
  fontSize: 14,
  color: theme.palette.primary.main,
  padding: 0,
}));

export const DividerStyle = styled("div")(({ theme }) => ({
  width: "calc(100% - 30px)",
  height: 3,
  backgroundColor: "#fbf9f9",
  position: "absolute",
  top: 25,
  left: 15,
  zIndex: -1,
}));

export const ContainerStyle = styled("div")(({ theme }) => ({
  borderColor: theme.palette.primary.main,
  borderWidth: 1,
  borderStyle: "solid",
  position: "relative",
  width: "100%",
  top: -26,
  borderRadius: 10,
  paddingTop: 10,
  paddingBottom: 10,
  paddingRight: 20,
  paddingLeft: 20,
  zIndex: 0,
  overflow: "hidden",
  height: "auto",
}));
