export const GlobalVariables = {
  NoDataLength: 0,
  ManyDataLength: 2,
  EmptyString: "",
  TOKEN: "token",
  EmptyJsonString: "{}",
  STRING: "string",
  DebounceTime: {
    Short: 100,
    Medium: 500,
  },
  DefaultPerPage: 10,
  SNACK_BAR_TIME_OUT: 5000,
  //fie
  FILE_FORMATS: [
    { format: "image/jpeg", extension: ".jpeg" },
    { format: "image/jpg", extension: ".jpg" },
    { format: "image/png", extension: ".png" },
    // { format: "image/webp", extension: ".webp" },
  ],
};
