import { useTranslation } from "react-i18next";
import { ChangeEvent, useCallback, useState, useEffect } from "react";
import Dialog from "components/common/Dialog/Dialog";
import Button from "components/common/Button/Button";
import {
  ContainerButtonStyle,
  DeleteButtonsStyle,
  ImageContainerStyle,
  ImageStyle,
  InputStyle,
} from "./EditBrand.style";
import { useSnackBar } from "hooks/useSnackBar";
import TextInput from "components/common/TextInput/TextInput";
import { useUpdateCategoryMutation } from "redux/api/category/category";
import FileInput from "components/common/FileInput/FileInput";
import { CONFIG, GlobalVariables } from "config/constant";
import {
  IBrandEditingData,
  IBrandEditingDataError,
  IEditingDialog,
} from "./EditBrand.types";
import { useUpdateBrandMutation } from "redux/api/brand/brand";

function EditBrand({
  iniData,
  openEditingDialog,
  setOpenEditingDialog,
}: IEditingDialog) {
  const { t } = useTranslation();

  const { displaySuccessSnackBar, displayErrorApiSnackBar } = useSnackBar();

  const [editingBrand, { data, error, isLoading, reset }] =
    useUpdateBrandMutation();
    const [isSubmitted,setIsSubmitted]=useState(false);

  if (error) {
    displayErrorApiSnackBar(error);
    reset();
  }
  if (data) {
    displaySuccessSnackBar(t("brand.successfully_editing"));
    reset();
  }
  const [editingError, setEditingError] = useState<IBrandEditingDataError>({
    name: "",
    image: "",
  });
  const validate=()=>{
    const addingErrorCateg:{name:string,image:string}={
      name:"",
      image:"",
    }
    if(!editingData.name){
      addingErrorCateg.name="Titre obligatoire"
    }
    else {
      addingErrorCateg.name=""

    }
    if(!editingData.image){
      addingErrorCateg.image="Image obligatoire"

    }
    else {
      addingErrorCateg.image=""

    }
    setEditingError(addingErrorCateg)
    console.log("AddingError",addingErrorCateg)

  }
  const onChangeOneImage = useCallback(
    (field: string) => (e: any) => {
      validate()
      const files = (e.target as HTMLInputElement).files;

      if (files) {
        const file = files[0];

        // Check if the selected file is an image
        if (file.type.startsWith('image/')) {
          const reader = new FileReader();

          reader.onload = (event) => {
            if (event.target) {
              const image = new Image();
              image.src = event.target.result as string;

              image.onload = () => {
                // Access the image dimensions
                const imageWidth = image.width;
                const imageHeight = image.height;

                console.log('Image Width:', imageWidth);
                console.log('Image Height:', imageHeight);
                if(imageWidth==300 && imageHeight == 300){
                  setEditingError({ ...editingError, image: "" });

                  setEditingData({ ...editingData, image: "" });

                  setEditingData({
                    ...editingData,
                  image : e.target.files[0]})


                }else{
                  setEditingError({ ...editingError, image: "L'image doit être de taille 300 × 300 pixels." });

                }


              };
            }
          };

          // Read the selected file as a data URL
          reader.readAsDataURL(file);
        } else {
          // Handle non-image file type
          console.error('Selected file is not an image.');
        }
      }
    },
    [editingError]
  );
  const closeEditingDialog = useCallback(() => {
    setOpenEditingDialog(false);
    setIsSubmitted(false)

    setEditingData({ name: "", image: null });
  }, [setOpenEditingDialog]);

  const onSuccessEditingDialog = (event: React.FormEvent) => {
    event.preventDefault();
    setIsSubmitted(true)
   validate()
    const formData = new FormData();
    formData.append("name", editingData.name);
    formData.append(
      "is_image_updated",
      "1"
    );
    if (editingData.image) formData.append("image", editingData.image);
   if(editingData.name && editingData.image){
    editingBrand({ id: editingData.id, formData: formData });

  }
  };
  useEffect(()=>{
    if(!isLoading && isSubmitted){
    closeEditingDialog();
  }
},[isLoading])

  const [editingData, setEditingData] = useState<IBrandEditingData>({
    id: iniData.id,
    name: iniData.name,
    image: iniData.image,
    is_image_updated: 0,
  });

  const onChange = useCallback(
    (field: string) =>
      (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        validate()
        setEditingData({ ...editingData, [field]: e.target.value });
        if (field === "image") {
          const files = (e.target as HTMLInputElement).files;
          if (files)
            setEditingData({
              ...editingData,
              is_image_updated: 1,
              image: files[0],
            });
        }
      },
    [editingData]
  );

  useEffect(() => {
    if (openEditingDialog)
      setEditingData({
        id: iniData.id,
        name: iniData.name,
        image: iniData.image,
      });
  }, [openEditingDialog, iniData.id, iniData.image, iniData.name]);

  return (
    <Dialog
      open={openEditingDialog}
      handleClose={closeEditingDialog}
      title={t("brand.edit")}
    >
      <form onSubmit={onSuccessEditingDialog}>
        <InputStyle>
          <TextInput
            value={editingData.name}
            label={t("brand.name")}
            onChange={onChange("name")}
            error={t(editingError.name)}
          />
        </InputStyle>
        <InputStyle>
          <FileInput
            value={
              typeof editingData.image != "string" ? editingData.image : null
            }
            label={t("category.image")}
            onChange={onChangeOneImage("image")}
            error={t(editingError.image)}
            accept={GlobalVariables.FILE_FORMATS.map((e) => e.format).join(",")}
          />
        </InputStyle>
        {editingData.image && (
          <ImageContainerStyle>
            <ImageStyle
              src={
                typeof editingData.image === "string"
                  ? CONFIG.BASE_URL + editingData.image
                  : URL.createObjectURL(editingData.image)
              }
            ></ImageStyle>
          </ImageContainerStyle>
        )}

        <DeleteButtonsStyle>
          <ContainerButtonStyle>
            <Button
              disabled={isLoading}
              title={t("common.cancel_button")}
              onClick={closeEditingDialog}
            />
          </ContainerButtonStyle>
          <ContainerButtonStyle>
            <Button
              disabled={isLoading}
              type="submit"
              loader={isLoading}
              title={t("common.confirm_button")}
            />
          </ContainerButtonStyle>
        </DeleteButtonsStyle>
      </form>
    </Dialog>
  );
}
export default EditBrand;
