export const tax={
    name:"Nom",
    percentage:"Pourcentage",
    country:"Pays",
    page_title:"Taxes",
    add:"Ajouter un taxe",
    edit:"Modifier un taxe",
    delete:"Supprimer un taxe",
    successfully_deleted: "Taxe  supprimée avec succès",
    successfully_adding: "Taxe  ajoutée avec succès",
    successfully_editing: "Taxe  editée avec succès",
    name_required:"Nom est obligatoire",
    percentage_required:"Pourcentage est obligatoire",
    percentage_range:"Pourcentage est compris entre 0 et 99.99",
    country_required:"Pays est requis"
}