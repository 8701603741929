import { styled } from "@mui/material";

export const DeleteButtonsStyle = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
}));

export const ContainerButtonStyle = styled("div")(({ theme }) => ({
  margin: 10,
}));

export const InputsContainer = styled("div")(({ theme }) => ({
  display: "flex",
}));
export const ModeInputs=styled("div")`  display: flex;
flex-direction: column;

@media (max-width: 768px) {
  display:flex
  flex-direction: row;
}
`;


export const ItemModeLine=styled("div")(({theme})=>({
  display:"flex",
  justifyContent:"space-between"
}))
export const InputStyle = styled("div")(({ theme }) => ({
  width: "100%",
  marginTop: 20,
  marginBottom: 20,
}));
export const InputStyleItem = styled("div")(({ theme }) => ({
  width: "100%",
  marginTop: 20,
  marginRight:"20px",
  marginBottom: 20,
}));
export const FormStyle=styled("form")(({theme})=>({
  padding:"20px"
}))


  export const InputStyleCostMode=styled("div")(({theme})=>({
    width:"25%"
  }))



export const ErrorContainer = styled("p")(({ theme }) => ({
  color: "red",
  fontSize: 14,
  margin: 0,
  marginTop: 2,
  marginBottom: 0,
  textAlign: "start",
}));
