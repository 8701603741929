import React, { useEffect, useState } from "react";
import { RiCloseLine } from "react-icons/ri";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { changeOrderStatus, getAllPointOfSales } from "UpdatedFeatures/api";
import { Puff } from "react-loader-spinner";
import { toast } from "react-toastify";

// Define static status options


const ChangeStatusModal = ({ open, setOpen, order, type }) => {
    const [selectedStatus, setSelectedStatus] = useState(order?.status || "");
    const queryClient = useQueryClient();

    const statusOptions = type == 0 ? [
        { value: 0, label: "En cours de traitement", color: "yellow" },
        { value: 1, label: "En cours de livraison", color: "yellow" },
        { value: 2, label: "Livré", color: "green" },
        { value: 3, label: "Annulé", color: "red" },
    ] : [
        { value: 6, label: "Nouvelle commande." },
        { value: 7, label: "Payée et en attente de retrait" },
        { value: 8, label: "Retiré" },
    ];

    const { data: pos, isLoading: isLoadingPOS } = useQuery({
        queryKey: ["pointOfSales"],
        queryFn: getAllPointOfSales,
        keepPreviousData: true,
    });

    const mutation = useMutation({
        mutationFn: (status) => changeOrderStatus(order.id, status),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['orderHistories'] });
            setOpen(false);
            toast.success("Statut de la commande mis à jour avec succès !");
        },
        onError: () => {
            toast.error("Erreur lors du changement du statut !");
        },
    });

    useEffect(() => {
        if (order) {
            setSelectedStatus(order.status || "");
        }
    }, [order]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!isLoadingPOS) {
            mutation.mutate(selectedStatus);
        }
    };

    if (!open) return null;

    return (
        <div className="fixed inset-0 z-50 overflow-visible" role="dialog" aria-modal="true">
            {/* Overlay */}
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75" onClick={() => setOpen(false)} />

            {/* Modal Content */}
            <div className="flex items-center justify-center min-h-screen p-4">
                <div
                    className="relative w-full max-w-lg mx-auto bg-white shadow-lg rounded-lg overflow-hidden"
                    onClick={(e) => e.stopPropagation()}
                >
                    {/* Header */}
                    <div className="flex justify-between items-center p-6 bg-[#b269ae] border-b border-gray-500/30 pb-4">
                        <h3 className="text-lg font-medium leading-6 text-white">Informations du utilisateur</h3>
                        <button
                            type="button"
                            className="inline-flex items-center justify-center p-1.5 text-gray-400 hover:text-gray-500 hover:scale-105 duration-200"
                            onClick={() => setOpen(false)}
                        >
                            <RiCloseLine className="h-6 w-6 text-white" aria-hidden="true" />
                        </button>
                    </div>

                    {/* Modal Body */}
                    <div className="p-6">
                        <form onSubmit={handleSubmit}>
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700 mb-2">Status</label>
                                <select
                                    value={selectedStatus}
                                    onChange={(e) => setSelectedStatus(Number(e.target.value))}
                                    className="form-select mt-1 block w-full"
                                >
                                    {statusOptions.map(({ value, label }) => (
                                        <option key={value} value={value}>
                                            {label}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="flex justify-end">
                                <button
                                    type="submit"
                                    className="bg-[#b269ae] text-white px-4 py-2 rounded"
                                    disabled={mutation.isLoading} // Disable button while mutation is loading
                                >
                                    {mutation.isLoading ? (
                                        <Puff visible height="20" width="20" color="#895a7c" ariaLabel="puff-loading" />
                                    ) : (
                                        "Save Changes"
                                    )}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChangeStatusModal;
