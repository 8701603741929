export const errors = {
  CODE_REQUIRED: "Code de promotion est obligatoire",
  CODE_UNIQUE: "Code de promotion doit être unique",
  TITLE_REQUIRED: "Titre est obligatoire",
  IMAGE_REQUIRED: "Image est obligatoire",
  INVALID_DETAILS: "Email ou mot de passe incorrect",
  CURRENT_PASSWORD_REQUIRED: "Ancien mot de passe est obligatoire",
  INCORRECT_OLD_PASSWORD: "Ancien mot de passe est incorrecte",
  NEW_PASSWORD_REQUIRED: "Nouveau mot de passe est obligatoire",
  NEW_PASSWORD_CONFIRMED_REQUIRED:
    "La confirmation du nouveau mot de passe est obligatoire",
  NEW_PASSWORD_CONFIRMED:
    "La confirmation du nouveau mot de passe est incorrecte",
  NUMBER_PERCENTAGE:"Le pourcentage doit être un nombre"  
};
