import PropTypes from 'prop-types';

// material-ui
import { Box, Chip, Grid, Stack, Typography } from '@mui/material';

// project import
import MainCard from '../../MainCard';

// assets
import { RiseOutlined, FallOutlined } from '@ant-design/icons';

// ==============================|| STATISTICS - ECOMMERCE CARD  ||============================== //
interface AnalyticsEcommerceProps {
  title:string,
  count:string,
  percentage?:number,
  isLoss:boolean,
  extra: any
};

const AnalyticEcommerce = (props:AnalyticsEcommerceProps) => (
  <Box
  sx={{
    boxShadow: 3,
    width: 'auto',
    height: '7rem',
    bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
    color: (theme) =>
      theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
    p: 1,
    m: 1,
    borderRadius: 2,
    fontSize: '0.875rem',
    fontWeight: '700',
  }}
>
      <Typography variant="h6" color="textSecondary">
        {props.title}
      </Typography>
      <Grid container alignItems="center">
        <Grid item>
          <Typography variant="h5" color="inherit">
            {props.count}
          </Typography>
        </Grid>
        {props.percentage && (
          <Grid item>
            <Chip
              color={"secondary"}
              icon={
                <>
                  {!props.isLoss && <RiseOutlined style={{ fontSize: '0.75rem', color: 'inherit' }} />}
                  {props.isLoss && <FallOutlined style={{ fontSize: '0.75rem', color: 'inherit' }} />}
                </>
              }
              label={`${props.percentage}%`}
              sx={{ ml: 1.25, pl: 1 }}
              size="small"
            />
          </Grid>
        )}
      </Grid>

 </Box>
);





export default AnalyticEcommerce;
