import {
  MenuInterface,
  MenuPropsInterface,
  SectionPropsInterface,
} from "./Menu.type";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import { MIN_SIZE_SCREEN, menu } from "utils";
import {
  LiIconExpandLessStyle,
  LiIconExpandMoreStyle,
  LiTitleStyle,
  ListItemSelectedStyle,
  ListStyle,
  MenuContainerStyle,
  MenuStyle,
} from "./Menu.style";
import { useNavigate,useLocation} from "react-router-dom";
import { Collapse } from "@mui/material";
function Menu({
  handleDrawerClose, // handle close drawer
  menuPath, //selected menu path
}: MenuPropsInterface) {
  // translation hooks
  const { t } = useTranslation();
  const [open,setOpen]=useState<boolean>(false)
  const navigate = useNavigate();
  const location=useLocation()
  // handle navigation
  const handleClick = useCallback(
    
    (link: string|undefined) => () => {
      console.log("link",link)
      if (window.innerWidth < MIN_SIZE_SCREEN) {
        handleDrawerClose();
      }
      navigate("/" + link);
     
      

    },
    [handleDrawerClose, navigate,open]
  );
  const handleClickChild = useCallback(
    
    (link: string|undefined) => () => {
      console.log("link",link)
      if (window.innerWidth < MIN_SIZE_SCREEN) {
        handleDrawerClose();
      }
      navigate("/" + link);
     
      

    },
    [handleDrawerClose, navigate,open]
  );
  const handleCollapseItem=()=>{
    setOpen(!open)
  }
  useEffect(()=>{
    console.log("open",open)
  },[open])


  const MenuSection = ({ path, title, icon,children }: SectionPropsInterface) => {
    console.log("path",path,"title",title,"icon",icon,"children",children)
    return (
      <>
      <ListItemSelectedStyle
        isselected={menuPath === path}
        onClick={children?.length===0?handleClick(path):()=>{handleCollapseItem()}}
      >
        {icon}
        <LiTitleStyle>{t(title)}</LiTitleStyle>
        {children &&children?.length>0 ?open ? <LiIconExpandLessStyle /> : <LiIconExpandMoreStyle />:""}

      </ListItemSelectedStyle>

          {open && children?.map((child)=>(
            <ListItemSelectedStyle
            style={{marginLeft:"10px"}}
            isselected={menuPath === child.path}
            onClick={handleClickChild(child.path)}>
                <img src={child.icon} alt="add" className="makeStyles-liIcon" />
            <LiTitleStyle><span>{t(child.title)}</span></LiTitleStyle>
          </ListItemSelectedStyle>
          ))}
          </>
     

    )
    }

  
  return (
    <MenuContainerStyle>
      <MenuStyle>
        <ListStyle>
          {menu.map((item: MenuInterface, index: number) => (
              <MenuSection
              key={`menu-parent-${index}`}
              path={item.path}
              title={t(item.title)}
              children={item.children}
              icon={
                <img src={item.icon} alt="add" className="makeStyles-liIcon" />
              }
            />
          ))}
        </ListStyle>
      </MenuStyle>
    </MenuContainerStyle>
  );
}
export default Menu;
